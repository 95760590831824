import { useState } from "react";
import styled from "styled-components";

import SIZE from "components/atoms/size";
import Tab from "components/templates/Products/Tab";
import ProductList from "components/templates/Products/List";

const Product = () => {
  const [activeCoin, setActiveCoin] = useState("KRW");
  return (
    <Container>
      <Top>투자 상품</Top>
      {/* <Tab activeCoin={activeCoin} onChange={setActiveCoin} /> */}
      <ProductList activeCoin={activeCoin} />
    </Container>
  );
};

const Container = styled.div`
  max-width: ${SIZE.largeWidth};
  margin: 0 auto;
  border: 1px solid #e0e0e0;
`;
const Top = styled.div`
  padding: 26px 10px 10px 16px;
  font-weight: bold;
`;

export default Product;
