import { ReactComponent as Red } from "assets/icons/Main/squareRed.svg";
import { ReactComponent as Blue } from "assets/icons/Main/squareBlue.svg";
import { ReactComponent as Purple } from "assets/icons/Main/squarePurple.svg";

export const data = [
  {
    id: 0,
    color: "#FFEDF1",
    img: <Red />,
    text: (
      <p>
        <span>[내 NFT 지갑]</span>을 등록하고 해당 상품의
        <br /> 투자 내역을 NFT화 해보세요.
      </p>
    ),
  },
  {
    id: 1,
    color: "#E1EFFF",
    img: <Blue />,
    text: (
      <p>
        신청 시 투자하신 내용을 <span>문서화한 NFT</span>를 <br />
        계정과 연결된 지갑으로 보내드립니다.
      </p>
    ),
  },
  {
    id: 2,
    color: "#E8E2FF",
    img: <Purple />,
    text: (
      <p>
        생성된 NFT는 아이디얼팜 NFT 거래소 탭에서
        <br />
        <span>다른 사용자와 거래</span>하여 수익을 창출할 수 있어요.
      </p>
    ),
  },
];
export const textData = [
  {
    id: 0,
    text: (
      <p>
        -<span>마이페이지 &gt; NFT 지갑 관리/연동</span>에서 NFT 지갑 주소 등록
        후 신청가능합니다.
      </p>
    ),
  },
  {
    id: 1,
    text: <p>-신청 이후 차용증이 생성되며 해당 차용증은 NFT화 됩니다.</p>,
  },
  {
    id: 2,
    text: <p>-생성된 NFT는 거래할 수 있습니다.</p>,
  },
  {
    id: 3,
    text: (
      <p>
        -한 상품 당 하나의 NFT만 생성됩니다.{" "}
        <span>(차용증 NFT 판매 이후 재생성 불가.)</span>
      </p>
    ),
  },
  {
    id: 4,
    text: (
      <p>
        -만기일에 해당 NFT를 보유한 지갑으로 지정된 수량만큼의 코인이
        전송됩니다.
      </p>
    ),
  },
  {
    id: 5,
    text: <p>-상품 만기 후에도 NFT는 계속 보유할 수 있습니다.</p>,
  },
  {
    id: 6,
    text: (
      <p>
        -이미 지급이 완료된 NFT 채권에 대해서는 추가 지급이 이뤄지지 않습니다.
      </p>
    ),
  },
];
