import { atom } from "recoil";

const localStorageEffect =
  (walletInfo: string) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(walletInfo);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }
    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(walletInfo)
        : localStorage.setItem(walletInfo, JSON.stringify(newValue));
    });
  };

export const walletInfoState = atom({
  key: "walletInfo",
  default: null as unknown as string,
  effects: [localStorageEffect("walletInfo")],
});
