import styled from "styled-components";

import { contactData } from "datas/landing";
import Background from "assets/images/Landing/ContactBackground.jpg";
// import { ReactComponent as Tel } from "../../assets/icons/tel.svg";
import COLORS from "components/atoms/colors";
import { MiniContent } from "components/atoms/landing";

const CenterContact = () => {
  return (
    <Layout>
      <TitleWrap>
        <SubTitle>contact</SubTitle>
        <Title>상품에 대해 궁금한 점이 있으신가요?</Title>
      </TitleWrap>
      <ContactContainer>
        {contactData.map(({ id, img, title, content }) => (
          <Contact key={id}>
            {img}
            <Wrapper>
              <Content>{title}</Content>
              <MiniContent>{content}</MiniContent>
            </Wrapper>
          </Contact>
        ))}
      </ContactContainer>
    </Layout>
  );
};

const Layout = styled.div`
  background-image: linear-gradient(
      to bottom,
      rgba(37, 37, 37, 0.8),
      rgba(37, 37, 37, 0.8)
    ),
    url(${Background});
  background-color: rgba(37, 37, 37, 0.8);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0 56px 0;
  @media only screen and (max-width: 1200px) {
    /* flex-direction: column;
    justify-content: space-evenly;
    height: 220px;
    padding-bottom: 20px; */
  }
  @media only screen and (max-width: 787px) {
    flex-direction: column;
    justify-content: space-evenly;
    padding: 30px 32px 42px;
  }
`;
const TitleWrap = styled.div`
  margin-right: 98px;
  @media only screen and (max-width: 1200px) {
    /* width: 80%;
    margin: 0; */
  }
  @media only screen and (max-width: 787px) {
    width: 100%;
    max-width: 308px;
    /* max-width: 350px; */
    margin: 0 0 15px 0;
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  @media only screen and (max-width: 1200px) {
    text-align: left;
  }
  @media only screen and (max-width: 787px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
const SubTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.pink};
`;
const ContactContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 787px) {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 11px 23px;
  }
`;
const Contact = styled.div`
  display: flex;
  align-items: center;
  margin-right: 23px;
  .Email {
    margin-left: 32px;
  }
  & + & {
    border-left: 1px solid ${COLORS.lightGray};
  }
  @media only screen and (max-width: 787px) {
    svg,
    .Email {
      width: 20px;
      height: 20px;
      margin-left: 0;
    }
  }
`;
const Content = styled.div`
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  @media only screen and (max-width: 1200px) {
    text-align: left;
  }
  @media only screen and (max-width: 787px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
  @media only screen and (max-width: 787px) {
    flex: 1;
    padding-left: 50px;
    border-left: 1px solid ${COLORS.gray};
    align-items: center;
  }
`;

export default CenterContact;
