import styled from "styled-components";
import { Link } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from "assets/icons/Main/Logo.svg";
// import { ReactComponent as TopBell } from 'assets/icons/Main/topBell.svg';
// import { ReactComponent as TopAlarm } from 'assets/icons/Main/topAlarm.svg';
import SIZE from "components/atoms/size";

const Nav = () => {
  // const navigate = useNavigate();
  // const alarm = false;
  return (
    <Layout>
      <Container>
        <Link to="/main">
          <Logo />
        </Link>
        {/* {alarm ? <TopAlarm /> : <TopBell onClick={() => navigate("/alarm")} />} */}
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  position: static;
  background: #fff4f7;
  z-index: 10;
  max-width: ${SIZE.largeWidth};
  margin: 0 auto;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 25px 25px 20px 16px;
  svg {
    cursor: pointer;
  }
`;

export default Nav;
