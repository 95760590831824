import { colorData } from "datas/yetColorData";
import { ProductsType } from "types/products";
import {
  Content,
  ContentTitle,
  ContentWrap,
} from "components/atoms/yetEndProducts";

const Center = ({ data }: { data: ProductsType }) => {
  const { coin_id, unit, apy, recommend_bonus_apy, max_amount } = data;

  return (
    <>
      <ContentWrap>
        <ContentTitle>예상 수익률</ContentTitle>
        <Content
          color={colorData[coin_id - 1].fontColor}
          bgColor={colorData[coin_id - 1].borderColor}
        >
          {`최소 ${apy}% ~ 최대 ${apy + recommend_bonus_apy}% `}
        </Content>
      </ContentWrap>
      <ContentWrap>
        <ContentTitle>총 모집 수량</ContentTitle>
        <Content>
          {max_amount.toLocaleString()}&nbsp;{unit}
        </Content>
      </ContentWrap>
    </>
  );
};

export default Center;
