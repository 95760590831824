import styled from "styled-components";

import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";

export const ContentTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0 auto;
  max-width: ${SIZE.mediumWidth};
`;
export const InfoGraph = styled.progress`
  width: 100%;
  appearance: none;
  margin-top: 5px;
  height: 7px;
  &::-webkit-progress-bar {
    background: #e2e2e2;
    border-radius: 20px;
  }
  &::-webkit-progress-value {
    border-radius: 20px;
    background: ${COLORS.pink};
  }
`;
