import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import styled from "styled-components";

import { getProduct } from "api/product";
import { coutions } from "datas/coutions";
import { LoginState } from "stores/LoginStates";
import { convertToDateNoTime } from "utils/getDDay";
import { ReactComponent as Krw } from "assets/icons/Product/krw-active.svg";
import { ReactComponent as Caution } from "assets/icons/Landing/Caution.svg";
import { ReactComponent as Ripple } from "assets/icons/Product/xrp-active.svg";
import { ReactComponent as Bitcoin } from "assets/icons/Product/btc-active.svg";
import { ReactComponent as Etherieum } from "assets/icons/Product/eth-active.svg";
import { ReactComponent as StakingBG } from "assets/images/Landing/StakingBG.svg";
import { ReactComponent as StakingBGArrow } from "assets/images/Landing/StakingBGArrow.svg";
import {
  SubTitle,
  Title,
  TextWrapper,
  SubContent,
  SubContentBold,
} from "components/atoms/landing";
import COLORS from "components/atoms/colors";
import LandingNav from "components/organisms/LandingNav";
import LandingFooter from "components/organisms/LandingFooter";

const coinData = [
  { id: 1, unit: "KRW", img: <Krw /> },
  { id: 2, unit: "BTC", img: <Bitcoin /> },
  { id: 3, unit: "ETH", img: <Etherieum /> },
  { id: 4, unit: "XRP", img: <Ripple /> },
];

const ProductDetail = () => {
  const isLoggedIn = useRecoilValue(LoginState);
  const { id = "" } = useParams();
  const { data, isLoading } = useQuery("product", () => getProduct(id));
  if (isLoading) return null;
  const {
    name,
    name_kr,
    min_amount,
    max_amount,
    apy,
    recommend_bonus_apy,
    duration_month,
    fund_end_at,
    code,
    unit,
    explanation,
    coin_id,
  } = data;

  return (
    <>
      <LandingNav menuList={[]} />
      <Container>
        <BG>
          <StakingBG />
        </BG>
        <TextWrap>
          <StakingBGArrow />
          <TitleText>
            씨를 뿌리고 수확하듯 자산을 늘리는 <br />
            <span>아이디얼 팜</span> 예치 상품
          </TitleText>
          <SubContent2>
            자산을 예치하고, <br />
            편리하고 지속적인 수익을 창출하세요.
          </SubContent2>
        </TextWrap>
        <ProductInfo>
          <Item>
            <TitleContainer>
              <CoinTitle>
                {coinData.find((i) => i.id === coin_id)?.img}
                <TitleWrap>
                  <ContentTitle>{name}</ContentTitle>
                  <SubContentBold>{code}</SubContentBold>
                </TitleWrap>
              </CoinTitle>
              <RateContainer>
                <RateTitle>예상 연 이율</RateTitle>
                <RateContent>
                  최소 {apy}% ~ 최대 {apy + recommend_bonus_apy}%
                </RateContent>
              </RateContainer>
            </TitleContainer>
            <OptionContainer>
              <OptionItem>
                <OptionContentGray>예치 자산</OptionContentGray>
                <OptionContent>
                  {name_kr} ({unit})
                </OptionContent>
              </OptionItem>
              <OptionItem>
                <OptionContentGray>예치 기간</OptionContentGray>
                <OptionContent>{duration_month}개월</OptionContent>
              </OptionItem>
              <OptionItem>
                <OptionContentGray>신청 최소 수량</OptionContentGray>
                <OptionContent>
                  {min_amount.toLocaleString()} {unit}
                </OptionContent>
              </OptionItem>
              <OptionItem>
                <OptionContentGray>총 모집 수량</OptionContentGray>
                <OptionContent>
                  {max_amount.toLocaleString(0)} {unit}
                </OptionContent>
              </OptionItem>
              <OptionItem>
                <OptionContentGray>모집 기간</OptionContentGray>
                <OptionContent>
                  ~ {convertToDateNoTime(fund_end_at)}
                </OptionContent>
              </OptionItem>
            </OptionContainer>
          </Item>
          <ExplainContainer>
            <ExplainTitle>상품 설명</ExplainTitle>
            <Description>{explanation}</Description>
          </ExplainContainer>
          <CautionContainer>
            <CautionTitle>
              <Caution />
              신청 전 꼭 읽어주세요
            </CautionTitle>
            <CautionContentList>
              {coutions.map((content: string, i: number) => (
                <CautionContent key={i}>{content}</CautionContent>
              ))}
            </CautionContentList>
          </CautionContainer>
          {isLoggedIn ? (
            <Link to={`/products/${id}`}>
              <BtnWrap>
                <BtnApply>신청하기</BtnApply>
              </BtnWrap>
            </Link>
          ) : (
            <Link to="/login">
              <BtnWrap>
                <BtnApply>신청하기</BtnApply>
              </BtnWrap>
            </Link>
          )}
        </ProductInfo>
      </Container>
      <LandingFooter />
    </>
  );
};

const Container = styled.div`
  padding: 175px 0 186px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 1200px) {
    padding: 44px 0 120px 0;
  }
  @media only screen and (max-width: 787px) {
    padding: 44px 0 120px 0;
  }
`;
const BG = styled.div`
  position: absolute;
  z-index: -1;
  background-color: #fff6f8;
  top: 0;
  width: 100%;
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media only screen and (max-width: 1200px) {
    height: 325px;
  }
  @media only screen and (max-width: 787px) {
    height: 434px;
    align-items: center;
  }
`;
const TextWrap = styled(TextWrapper)`
  position: relative;
  .stakingBgArrow {
    position: absolute;
    z-index: -1;
    top: -30px;
    left: -30px;
    @media only screen and (max-width: 1200px) {
      width: 109px;
    }
    @media only screen and (max-width: 787px) {
      width: 87px;
    }
  }
`;
const CoinTitle = styled.div`
  display: flex;
  align-items: center;
  .krw {
    margin-bottom: 4px;
  }
  @media only screen and (max-width: 787px) {
    display: flex;
    justify-content: flex-start;
    width: 263px;
    .bitCoin {
      width: 37px;
      height: 37px;
      margin-top: 4px;
    }
  }
`;
const TitleText = styled(Title)`
  text-align: center;
  br {
    display: none;
  }
  @media only screen and (max-width: 787px) {
    text-align: center;
    br {
      display: block;
    }
  }
`;
const SubContent2 = styled(SubTitle)`
  text-align: center;
  margin-top: 13px;
  br {
    display: none;
  }
  @media only screen and (max-width: 787px) {
    text-align: center;
    br {
      display: block;
    }
  }
`;
const ProductInfo = styled.div`
  max-width: 930px;
  padding: 53px 60px 56px;
  margin: 22px auto 0;
  background: #fff;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  @media only screen and (max-width: 1200px) {
    border: none;
    box-shadow: none;
    background: none;
    padding-top: 0;
  }
  @media only screen and (max-width: 787px) {
    padding: 0;
  }
`;
const Item = styled.div`
  @media only screen and (max-width: 1200px) {
    background: #fff;
    box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 32px 35px;
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 787px) {
    padding: 21px 20px 34px;
    margin: 0 auto 40px auto;
    width: 320px;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 1200px) {
    border-bottom: 1px solid ${COLORS.lightestGray};
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  @media only screen and (max-width: 787px) {
    flex-direction: column;
    border: none;
    margin: 0;
  }
`;
const TitleWrap = styled.div`
  margin-left: 14px;
  flex: 1;
`;
const ContentTitle = styled.p`
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
`;
const RateContainer = styled.div`
  @media only screen and (max-width: 787px) {
    background: #fff6f8;
    border: 1px solid #ffeef2;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 13px 50px 13px 50px;
    margin-top: 16px;
  }
`;
const RateTitle = styled(SubContent)`
  color: #0b0b0b;
  text-align: right;
  font-weight: 500;
`;
const RateContent = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: ${COLORS.pink};
  @media only screen and (max-width: 787px) {
    font-size: 17px;
    line-height: 24px;
    margin-top: 2px;
  }
`;
const OptionContainer = styled.div`
  display: flex;
  margin: 37px auto;
  padding-bottom: 30px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.lightestGray};
  @media only screen and (max-width: 1200px) {
    margin: 0;
    padding: 0;
    border: none;
  }
  @media only screen and (max-width: 787px) {
    flex-direction: column;
  }
`;
const OptionItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
  & + & {
    border-left: 1px solid ${COLORS.lightestGray};
  }
  @media only screen and (max-width: 787px) {
    flex-direction: row;
    justify-content: space-between;
    width: 263px;
    & + & {
      border-left: none;
      margin-top: 10px;
    }
  }
`;
const OptionContent = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 4px;
  white-space: pre;
  @media only screen and (max-width: 787px) {
    margin: 0;
    font-size: 12px;
    line-height: 17px;
  }
`;
const OptionContentGray = styled(OptionContent)`
  color: ${COLORS.lightGray};
`;
const ExplainContainer = styled.div`
  padding: 0 27px;
`;
const ExplainTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  margin-bottom: 8px;
`;
const Description = styled.p`
  font-size: 14px;
  line-height: 25px;
  text-align: justify;
  white-space: pre-wrap;
`;
const CautionContainer = styled.div`
  margin: 40px auto;
  background: #f7f7f7;
  padding: 34px 40px;
  @media only screen and (max-width: 787px) {
    margin-top: 53px;
    padding: 38px 27px;
  }
`;
const CautionTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  margin-bottom: 11px;
  display: flex;
  svg {
    margin-right: 6px;
  }
  @media only screen and (max-width: 787px) {
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    svg {
      width: 14px;
      height: 14px;
      margin-bottom: 2px;
    }
  }
`;
const CautionContentList = styled.ul`
  padding: 0;
  margin: 0;
`;
const CautionContent = styled.li`
  font-size: 14px;
  line-height: 25px;
  color: ${COLORS.gray};
  list-style: inside;
  @media only screen and (max-width: 787px) {
    font-size: 12px;
    line-height: 21px;
    text-indent: -20px;
    padding-left: 20px;
  }
`;
const BtnWrap = styled.div`
  display: flex;
  max-width: 320px;
  margin: 0 auto;
  @media only screen and (max-width: 787px) {
    width: 280px;
    height: 45px;
    margin-top: 40px;
  }
`;
const BtnApply = styled.button`
  background: ${COLORS.pink};
  color: #fff;
  font-weight: 700;
  font-size: 17px;
  line-height: 50px;
  border-radius: 40px;
  height: 50px;
  text-align: center;
  width: 100%;
`;

export default ProductDetail;
