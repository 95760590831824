import { ReactComponent as Kakao } from "assets/icons/MyInfo/kakao.svg";
import { ReactComponent as Coupon } from "assets/icons/MyInfo/coupon.svg";
import { ReactComponent as Logout } from "assets/icons/MyInfo/Logout.svg";
import { ReactComponent as Arrow } from "assets/icons/MyInfo/MenuMore.svg";
import { ReactComponent as Version } from "assets/icons/MyInfo/Version.svg";
import { ReactComponent as Caution } from "assets/icons/MyInfo/caution.svg";
import { ReactComponent as Password } from "assets/icons/MyInfo/Password.svg";
import { ReactComponent as Recommend } from "assets/icons/MyInfo/Recommend.svg";
import { ReactComponent as Withdrawal } from "assets/icons/MyInfo/withdrawal.svg";

export const myinfo = [
  {
    id: 1,
    image: <Coupon />,
    text: "내 쿠폰함",
    subText: "3장 보유",
    last: <Arrow />,
  },
  // {
  //   id: 2,
  //   image: <Caution />,
  //   text: "공지사항",
  //   last: <Arrow />,
  //   link: "/notice",
  // },
  {
    id: 2,
    image: <Recommend />,
    text: "추천인 코드",
    last: <Arrow />,
    link: "/recommend",
  },
  {
    id: 3,
    image: <Password />,
    text: "비밀번호 변경",
    last: <Arrow />,
    link: "/pwchange",
  },
  {
    id: 4,
    image: <Version />,
    text: "버전 정보",
    last: "1.01",
  },
  // {
  //   id: 4,
  //   image: <Kakao />,
  //   text: "카카오톡으로 문의하기",
  //   last: <Arrow />,
  //   onClickLink: "https://pf.kakao.com/_NExjtb",
  //   // link: "/pwchange",
  // },
  { id: 6, image: <Logout />, text: "로그아웃", last: "", logOut: true },
  // {
  //   id: 7,
  //   image: <Withdrawal />,
  //   text: "회원 탈퇴",
  //   last: "",
  //   isPink: true,
  //   deleteUser: true,
  // },
];

export default myinfo;
