import Moment from "react-moment";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { getNoticeDetail } from "api/notice";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import { Container } from "components/atoms/account";

const NoticeDetail = () => {
  const { noticeId } = useParams();
  const { isLoading, data } = useQuery(["notice", noticeId], () =>
    getNoticeDetail(noticeId)
  );
  if (isLoading) return null;
  const { title, contents, created_at } = data[0];

  return (
    <Main>
      <Link to="/my/notice">
        <Back />
      </Link>
      <MenuList>
        <TitleContainer>
          <Title>{title}</Title>
          <Date>
            <Moment local format="YYYY.MM.DD">
              {created_at}
            </Moment>
          </Date>
        </TitleContainer>
        <Content>{contents}</Content>
      </MenuList>
    </Main>
  );
};

const Main = styled(Container)`
  padding: 55px 0 0 0;
  .back {
    left: 15px;
  }
`;
const MenuList = styled.div`
  padding: 0 22px;
  width: 100%;
  margin-top: 50px;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
const Date = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #b3b3b3;
  margin: 3px 0 15px;
`;
const Content = styled.div`
  font-size: 12px;
  line-height: 17px;
`;

export default NoticeDetail;
