import { AxiosError } from "axios";

import { api } from "api/client";

export const getProducts = async () => {
  const response = await api.get(`/products`);
  return response.data;
};

export const getProduct = async (id: string) => {
  try {
    const response = await api.get(`/products/${id}`);
    return response.data.data;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response) {
      const failed = err.response.data.success;
      return failed;
    }
  }
};

export const getYetEndProducts = async () => {
  const response = await api.get(`/products/yet-end`);
  return response.data;
};
