import styled from "styled-components";

import { colorData } from "datas/yetColorData";
import { ProductsType } from "types/products";
import { ReactComponent as Fill } from "assets/icons/YetEnd/AlarmFill.svg";
import { ReactComponent as Empty } from "assets/icons/YetEnd/AlarmEmpty.svg";
import {
  SubTitle,
  Title,
  TitleContainer,
  TitleWrap,
  TopContainer,
  BtnYet,
} from "components/atoms/yetEndProducts";

const Top = ({ data }: { data: ProductsType }) => {
  const { name, code, is_notification, unit, coin_id } = data;

  return (
    <TopContainer>
      <TitleContainer>
        <TitleWrap>
          <Title width={"220px"}>
            {name}({unit})
          </Title>
          <BtnYet fontColor={colorData[coin_id - 1].fontColor}>예정</BtnYet>
        </TitleWrap>
        <SubTitle>{code}</SubTitle>
      </TitleContainer>
      <AlarmBtn bgColor={colorData[coin_id - 1].fontColor}>
        {is_notification ? <Fill /> : <Empty />}
      </AlarmBtn>
    </TopContainer>
  );
};

const AlarmBtn = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ bgColor }: { bgColor?: string }) => bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Top;
