import styled from "styled-components";

import { explainData } from "datas/landing";
import { SubTitle, Title } from "components/atoms/landing";

const Explain = () => {
  return (
    <Layout>
      <Container>
        <TextTitle>
          <span>아이디얼 팜</span>을<br /> 선택해야 하는 이유
        </TextTitle>
        <ItemList>
          {explainData.map(({ id, title, text, img }) => (
            <Item key={id}>
              {img}
              <ContentWrap>
                <Content>{title}</Content>
                <SubTitle>{text}</SubTitle>
              </ContentWrap>
            </Item>
          ))}
        </ItemList>
      </Container>
    </Layout>
  );
};

const Layout = styled.section`
  padding-top: 32px;
`;
const Container = styled.div`
  padding: 88px 0 149px 0;
  @media only screen and (max-width: 787px) {
    padding: 48px 0 74px 0;
  }
`;
const ItemList = styled.div`
  display: grid;
  max-width: 840px;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  margin: 51px auto 0;
  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
    margin-top: 40px;
  }
  @media only screen and (max-width: 787px) {
    display: flex;
    flex-direction: column;
    padding: 0 28px;
    margin-top: 80px;
    align-items: center;
  }
`;
const Item = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  padding: 37px 40px 38px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  @media only screen and (max-width: 1200px) {
    padding: 37px 20px 38px 23px;
  }
  @media only screen and (max-width: 787px) {
    width: 320px;
    margin-bottom: 65px;
    flex-direction: column;
    position: relative;
    padding: 31px auto 27px;
    svg {
      position: absolute;
    }
    .explain1 {
      width: 55px;
      top: -38px;
    }
    .explain2 {
      width: 51px;
      top: -50px;
    }
    .explain3 {
      width: 42px;
      top: -40px;
    }
    .explain4 {
      width: 57px;
      top: -35px;
    }
  }
`;
const TextTitle = styled(Title)`
  font-size: 27px;
  display: flex;
  justify-content: center;
  br {
    display: none;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 21px;
  }
  @media only screen and (max-width: 787px) {
    display: block;
    margin: 0 auto;
    text-align: center;
    br {
      display: block;
    }
  }
`;
const ContentWrap = styled.div`
  text-align: left;
  margin-left: 30px;
  @media only screen and (max-width: 1200px) {
    margin-left: 17px;
  }
  @media only screen and (max-width: 787px) {
    margin-left: 0;
    text-align: center;
  }
`;
const Content = styled.p`
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 10px;
  @media only screen and (max-width: 787px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

export default Explain;
