import styled from "styled-components";

import { ProductsType } from "types/products";
import Top from "components/templates/EndProducts/Top";
import Center from "components/templates/EndProducts/Center";
import Bottom from "components/templates/EndProducts/Bottom";

const EndItem = ({ data }: { data: ProductsType }) => {
  return (
    <Container>
      <Top data={data} />
      <Center data={data} />
      <Bottom data={data} />
    </Container>
  );
};

const Container = styled.div`
  padding: 18px 20px 22px;
  border-radius: 16px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  margin-bottom: 12px;
`;

export default EndItem;
