import styled from "styled-components";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "assets/icons/Main/Logo.svg";
import { ReactComponent as Guide } from "assets/icons/Main/Guide.svg";
import SIZE from "components/atoms/size";

const CenterAdvice = () => {
  return (
    <Layout>
      <Link to="/guide">
        <Container>
          <TextWrap>
            <Title>아이디얼 팜이 처음이신가요?</Title>
            <SubTitle>
              <Logo /> 이용 가이드
            </SubTitle>
          </TextWrap>
          <Guide />
        </Container>
      </Link>
    </Layout>
  );
};

const Layout = styled.div`
  margin: 17px auto 34px;
  padding: 0 15px;
  max-width: ${SIZE.mediumWidth};
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 18px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 0 auto;
`;
const TextWrap = styled.div``;
const Title = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #323232;
`;
const SubTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 6px;
  color: #323232;
`;

export default CenterAdvice;
