import styled from "styled-components";

import SIZE from "components/atoms/size";
import Title from "components/templates/Product/Complete/Title";
import Deposit from "components/templates/Product/Complete/Deposit";
import Proof from "components/templates/Product/Complete/Proof";

const ProductConfirm = ({
  data,
  owner_account,
  unit,
}: {
  data: {
    product_id: string;
    amount: number;
    wallet_address: string;
    recommend_code: string;
    bank: string;
    account_number: string;
    account_holder: string;
  };
  owner_account: string;
  unit: string;
}) => {
  return (
    <Container>
      <Title />
      <Deposit owner_account={owner_account} data={data} />
      <Proof data={data} unit={unit} />
    </Container>
  );
};

const Container = styled.div`
  max-width: ${SIZE.largeWidth};
  width: 95%;
  margin: 0 auto;
  padding: 50px 10px;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
`;

export default ProductConfirm;
