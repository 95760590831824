import Web3 from "web3";
import { useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import styled from "styled-components";

import { walletInfoState } from "stores/walletInfo";
import metamask from "assets/icons/MyInfo/metamask.png";

const Metamask = () => {
  const [loading, setLoading] = useState(false);
  const [walletInfo, setWalletInfo] = useRecoilState(walletInfoState);
  const resetWalletInfo = useResetRecoilState(walletInfoState);
  const onPressConnect = async () => {
    setLoading(true);
    try {
      if (window?.ethereum?.isMetaMask) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const account = Web3.utils.toChecksumAddress(accounts[0]);
        setWalletInfo(account);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const onPressLogout = () => resetWalletInfo();

  return (
    <>
      {walletInfo && !loading ? (
        <ContentBtn onClick={onPressLogout}>Disconnect</ContentBtn>
      ) : loading ? (
        <ContentBtn disabled>
          <div>Loading...</div>
        </ContentBtn>
      ) : (
        <ContentBtn onClick={onPressConnect}>
          <ContentImg src={metamask} />
          MetaMask
        </ContentBtn>
      )}
    </>
  );
};

const ContentBtn = styled.button`
  border: 1px solid #ececec;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 330px;
  height: 60px;
  background-color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 14px;
`;
const ContentImg = styled.img`
  width: 29px;
  height: 29px;
  margin-right: 14px;
`;

export default Metamask;
