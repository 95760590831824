import styled from "styled-components";
import { useRecoilValue } from "recoil";

import investState from "stores/invest";
import COLORS from "components/atoms/colors";
import Modal from "components/organisms/Modal";
import ModalProofKRW from "components/templates/Invest/ModalProofKRW";

const Info = () => {
  const { data } = useRecoilValue(investState);
  const {
    verify_deposit,
    wallet_address,
    bank,
    account_number,
    account_holder,
    is_need_bank,
    verify_certificate,
  } = data;

  return (
    <>
      {is_need_bank ? (
        <Container>
          <Title>내 계좌 정보</Title>
          <InfoBox>
            <InfoTitle>내 입금 계좌 정보</InfoTitle>
            <InfoContentKRW>
              {account_holder &&
                `${bank} ${account_number} (${account_holder})`}
            </InfoContentKRW>
          </InfoBox>
          <InfoBox>
            <InfoTitle>이체 증명서</InfoTitle>
            <CertificateContainer>
              {verify_certificate ? (
                <CertificatePicture src={verify_certificate} />
              ) : (
                <>
                  <CertificateTitle>
                    아직 이체 증명서가 첨부되지 않았습니다
                  </CertificateTitle>
                  <Modal
                    activator={(onOpen) => (
                      <AddCertificateBtn onClick={onOpen}>
                        이체 증명서 첨부하기 +
                      </AddCertificateBtn>
                    )}
                    content={(onClose) => <ModalProofKRW onClose={onClose} />}
                  />
                </>
              )}
            </CertificateContainer>
          </InfoBox>
        </Container>
      ) : (
        <Container>
          <Title>내 주소 정보</Title>
          <InfoBox>
            <InfoTitle>내 업비트 지갑 주소</InfoTitle>
            <InfoContent>{wallet_address}</InfoContent>
          </InfoBox>
          <InfoBox>
            <InfoTitle>입금 거래 ID</InfoTitle>
            <InfoContentId>{verify_deposit}</InfoContentId>
          </InfoBox>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  background-color: #fff;
  padding: 29px 23px 70px;
  margin-top: 10px;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 19px;
`;
const InfoBox = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;
const InfoTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray};
`;
const InfoContent = styled.div`
  min-height: 65px;
  background: #f7f7f7;
  border: 1px solid ${COLORS.lightestGray};
  border-radius: 4px;
  padding: 13px 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.gray};
  margin: 5px 0 15px 0;
`;
const InfoContentKRW = styled(InfoContent)`
  min-height: 45px;
`;
const InfoContentId = styled(InfoContent)`
  background: #fff;
  color: #000;
`;
const CertificateContainer = styled.div`
  width: 330px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;
const CertificateTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #b3b3b3;
  margin-bottom: 9px;
`;
const AddCertificateBtn = styled.button`
  background: #000000;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  padding: 13px 23px;
`;
const CertificatePicture = styled.img`
  border-radius: 4px;
  max-height: 150px;
  width: 100%;
  margin-top: 5px;
`;

export default Info;
