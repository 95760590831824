import styled from "styled-components";

export const ContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  & + & {
    margin-top: 7px;
  }
`;
export const ContentTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #808080;
`;
export const Content = styled.div<{
  color?: string;
  bgColor?: string;
}>`
  font-weight: ${({ color }) => (color ? "700" : "500")};
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: ${({ color }) => (color ? color : "#000")};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "")};
  height: 20px;
  display: flex;
  align-items: flex-end;
`;
export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 9px;
`;
export const SubTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: #b3b3b3;
`;
export const BtnYet = styled.div<{
  fontColor: string;
}>`
  font-size: 10px;
  color: #fff;
  background: ${({ fontColor }) => fontColor};
  border-radius: 20px;
  width: 30px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TitleContainer = styled.div``;
export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const Title = styled.p`
  font-weight: 700;
  font-size: 17px;
  max-width: ${({ width }: { width?: string }) => width};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;
