import styled from "styled-components";

import { ContentData } from "datas/wallets";
import { ReactComponent as ModalTitle } from "assets/icons/Main/ModalTitle.svg";

const ModalHelp = ({ onClose }: { onClose: () => void }) => {
  return (
    <Container>
      <BG onClick={onClose} />
      <Modal>
        <ModalTitle />
        {ContentData.map(({ id, title, img }) => (
          <ContentContainer key={id}>
            <ContentTitle>
              <ContentNumber>{id}</ContentNumber>
              {title}
            </ContentTitle>
            <ContentImgContainer>
              <ContentImg src={img} />
            </ContentImgContainer>
          </ContentContainer>
        ))}
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  bottom: 0;
  display: flex;
  justify-content: center;
`;
const BG = styled.div`
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
`;
const Modal = styled.div`
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 54px 60px 44px;
  z-index: 1;
  margin: 0 auto;
  position: absolute;
  top: 44px;
`;
const ContentContainer = styled.div`
  margin-top: 55px;
`;
const ContentTitle = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 6px;
`;
const ContentNumber = styled.div`
  width: 19px;
  height: 19px;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  margin-right: 7px;
`;
const ContentImgContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: inherit;
  margin: 10px auto;
`;
const ContentImg = styled.img``;

export default ModalHelp;
