import styled from "styled-components";

import { colorData } from "datas/yetColorData";
import { ProductsType } from "types/products";
import Top from "components/templates/YetEndProducts/YetItem/Top";
import Bottom from "components/templates/YetEndProducts/YetItem/Bottom";

const EndItem = ({ data }: { data: ProductsType }) => {
  return (
    <Container
      borderColor={colorData[data.coin_id - 1].borderColor}
      bgColor={colorData[data.coin_id - 1].bgColor}
    >
      <Top data={data} />
      <Bottom data={data} />
    </Container>
  );
};

const Container = styled.div<{
  bgColor: string;
  borderColor: string;
}>`
  padding: 18px 20px 22px;
  background: ${({ bgColor }) => bgColor};
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  border-radius: 16px;
  margin-bottom: 10px;
  height: 170px;
`;

export default EndItem;
