import Web3 from "web3";
import abi from "utils/abi";

const web3 = new Web3(
  new Web3.providers.HttpProvider(
    "https://goerli.infura.io/v3/dbc3288b001d4a81805dd24304e7a835"
  )
);

export const checkOwnNft = async ({
  contractAddress,
  id,
  walletInfo,
}: {
  contractAddress: string;
  id: number;
  walletInfo: string;
}) => {
  const abis = abi as any[];
  const contract = new web3.eth.Contract(abis, contractAddress);
  const res = await contract.methods.ownerOf(id).call();
  return res === walletInfo;
};
