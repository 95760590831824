import styled from "styled-components";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { signup } from "api/user";
import { AccountType } from "types/account";
import { SetStateBooleanType } from "types";
import { ReactComponent as AcceptAllActive } from "assets/icons/Account/AcceptAllActive.svg";
import { ReactComponent as AcceptItemActive } from "assets/icons/Account/AcceptItemActive.svg";
import { ReactComponent as More } from "assets/icons/Account/AcceptArrow.svg";
import COLORS from "components/atoms/colors";
import { FindBtn } from "components/atoms/account";

const defaultData = [
  { id: 0, text: "[필수] 개인정보수집 동의", checked: false },
  { id: 1, text: "[필수] 서비스 이용약관 동의", checked: false },
];

const SignUpTerms = ({
  setIsTerms,
  state,
}: {
  setIsTerms: SetStateBooleanType;
  state: AccountType;
}) => {
  const [data, setData] = useState(defaultData);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const isCheckAll = data.every((data) => data.checked);
    setIsCheckAll(isCheckAll);
  }, [data]);
  const handleAllCheck = () => {
    const newData = data.map((a) => ({
      ...a,
      checked: !isCheckAll,
    }));
    setData(newData);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const { checked } = e.target;
    const newData = data.map((a) => (a.id === id ? { ...a, checked } : a));
    setData(newData);
  };
  const handleSubmit = async () => {
    const result = await signup(state);
    if (result === false) {
      navigate("/signup");
    } else {
      navigate("/login");
      alert("회원가입에 성공하셨습니다.");
    }
  };

  return (
    <Container>
      <BG onClick={() => setIsTerms(false)} />
      <Modal>
        <Title>서비스 약관 동의</Title>
        <AcceptContainer>
          <AcceptAll check={isCheckAll}>
            <AcceptAllActive onClick={handleAllCheck} />
            전체 동의하기
          </AcceptAll>
          <CenterLine />
          <AcceptList>
            {data.map(({ id, text, checked }) => (
              <AcceptItem key={id} check={checked}>
                <AcceptBox
                  type="checkbox"
                  checked={checked}
                  id={text}
                  onChange={(e) => handleChange(e, id)}
                />
                <label htmlFor={text}>
                  <AcceptItemActive />
                </label>
                <AcceptText>{text}</AcceptText>
                <More />
              </AcceptItem>
            ))}
          </AcceptList>
          <Link to="/">
            <FindBtn disabled={!isCheckAll} onClick={handleSubmit}>
              가입하기
            </FindBtn>
          </Link>
        </AcceptContainer>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
`;
const BG = styled.div`
  position: absolute;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
`;
const Modal = styled.div`
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 54px 60px 44px;
  z-index: 1;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 25px;
`;
const CenterLine = styled.div`
  border: 1px solid #e0e0e0;
  margin: 16px 0 20px 0;
`;
const AcceptContainer = styled.div``;
const AcceptAll = styled.button<{ check: boolean }>`
  display: flex;
  align-items: center;
  padding: 0;
  background-color: #fff;
  .acceptAll {
    top: -1px;
    margin-right: 10px;
    fill: ${({ check }) => (check ? COLORS.pink : "#fff")};
    stroke: ${({ check }) => (check ? "#fff" : COLORS.lightestGray)};
  }
`;
const AcceptList = styled.div`
  border-radius: 4px;
  margin-bottom: 16px;
`;
const AcceptItem = styled.div<{ check: boolean }>`
  font-size: 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    position: static;
  }
  .checked {
    stroke: ${({ check }) => (check ? COLORS.pink : COLORS.lightestGray)};
    margin-right: 10px;
  }
`;
const AcceptBox = styled.input`
  display: none;
`;
const AcceptText = styled.p`
  flex: 1;
  font-size: 12px;
`;

export default SignUpTerms;
