import Content1 from "assets/icons/Guide/content1.png";
import Content2 from "assets/icons/Guide/Content2.png";
import Content3 from "assets/icons/Guide/Content3.png";
import Content4 from "assets/icons/Guide/Content4.png";

export const guide = [
  // {
  //   id: 1,
  //   title: "업비트 지갑 주소 등록하기",
  //   subTitle: `<span>내정보 &gt; 업비트 지갑주소 관리</span>
  //     에서 내 업비트 지갑 주소를 등록해주세요. 상품 만기 시 해당 계좌로 입금됩니다.`,
  //   img: Content1,
  // },
  {
    id: 1,
    title: "상품 신청하기",
    subTitle: `원하시는 상품을 선택하고 예치 금액을 입력한 후 신청합니다.`,
    img: Content2,
  },
  {
    id: 2,
    title: "선택한 예치 금액 입금하기",
    subTitle: `하단의 Ideal Farm 업비트 지갑 주소로 <span>선택하신 예치 금액을 업비트 [바로출금]</span>을 통해 입금해주세요.
  `,
    img: Content3,
  },
  {
    id: 3,
    title: "입금 증명하기",
    subTitle: ` Ideal Farm 앱 홈 > <span>내 가상자산 상품</span>에서 신청하신 상품으로
    들어가 <span>[입금 증명하기]</span>에 입금하신 거래 ID를 입력해주세요.`,
    img: Content4,
  },
  {
    id: 4,
    title: "관리자 입금 확인",
    subTitle: `관리자가 입금을 확인 한 후 알림을 보내드립니다. `,
    img: "",
  },
];
