import styled from "styled-components";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useState, useRef, TouchEvent } from "react";

import { getYetEndProducts } from "api/product";
import { ProductsType } from "types/products";
import Item from "components/templates/YetEndProducts/EndItem/Item";

const EndProducts = () => {
  const { data, isLoading } = useQuery("products", getYetEndProducts);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const touchStartPoint = useRef(0);
  const handleTouchStart = (e: TouchEvent) => {
    const { screenX } = e.changedTouches[0];
    touchStartPoint.current = screenX;
  };
  const handleTouchEnd = (e: TouchEvent) => {
    const { screenX } = e.changedTouches[0];
    // 감도 설정
    if (Math.abs(touchStartPoint.current - screenX) < 50) return;
    const toRight = touchStartPoint.current > screenX;
    if (toRight && activeIndex < data.length - 1) {
      setActiveIndex((prev: number) => prev + 1);
    } else if (!toRight && activeIndex > 0) {
      setActiveIndex((prev: number) => prev - 1);
    }
  };
  if (isLoading) return null;
  const EndData = data.filter((item: ProductsType) => item.status === "마감");

  return (
    <Container>
      <TitleContainer>
        <Title>마감 상품</Title>
        <MoreBtn onClick={() => navigate("/products/end")}>더보기 &gt;</MoreBtn>
      </TitleContainer>
      <ItemList>
        <ProductItem
          activeIndex={activeIndex}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          {EndData.map((data: ProductsType, i: number) => (
            <ItemWrapper
              onClick={() => setActiveIndex(i)}
              active={activeIndex === i}
              key={data.id}
            >
              <Item data={data} />
            </ItemWrapper>
          ))}
        </ProductItem>
      </ItemList>
      <SwipeIndexWrap>
        {EndData.map((item: ProductsType, i: number) => (
          <SwipeIndex isActive={activeIndex === i} key={i} />
        ))}
      </SwipeIndexWrap>
    </Container>
  );
};

const Container = styled.div`
  background: #f5f5f5;
  padding: 32px 22px 100px;
  overflow: hidden;
  gap: 10px;
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
`;
const MoreBtn = styled.button`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #808080;
  background: #f5f5f5;
`;
const ItemList = styled.div`
  overflow-x: hidden;
  margin: 0 auto;
`;
const ProductItem = styled.div<{ activeIndex: number }>`
  margin: 30px auto 30px;
  display: flex;
  align-items: center;
  height: 250px;
  width: 300px;
  transform: ${({ activeIndex }) => `translateX(${-320 * activeIndex}px)`};
  transition: 1s;
`;
const ItemWrapper = styled.div<{ active: boolean }>`
  cursor: ${({ active }) => !active && "pointer"};
`;
const SwipeIndexWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-bottom: 100px;
`;
const SwipeIndex = styled.div<{ isActive: boolean }>`
  height: 7px;
  background-color: ${({ isActive }) => (isActive ? "#FF3061" : "#E0E0E0")};
  border-radius: ${({ isActive }) => (isActive ? "10px" : "50%")};
  width: ${({ isActive }) => (isActive ? "19px" : "7px")};
  transition: all 0.2s ease-in-out;
`;

export default EndProducts;
