import { useState } from "react";
import Moment from "react-moment";
import { useQuery } from "react-query";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { getNotices } from "api/notice";
import { NoticeType } from "types/notice";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import { Container } from "components/atoms/account";

const Notice = () => {
  const [openNoticeId, setOpenNoticeId] = useState(0);
  const { isLoading, data } = useQuery("notice", getNotices);
  if (isLoading) return null;

  return (
    <Main>
      <Link to="/my">
        <Back />
      </Link>
      <Top>공지사항</Top>
      <MenuList>
        {data.map(({ id, created_at, title, contents }: NoticeType) => (
          <MenuTab key={id}>
            <Link to={`${id}`}>
              <TitleContainer
                onClick={() => setOpenNoticeId(id === openNoticeId ? 0 : id)}
              >
                <Date>
                  <Moment local format="YYYY.MM.DD">
                    {created_at}
                  </Moment>
                </Date>
                <Title isOpen={id === openNoticeId}>{title}</Title>
              </TitleContainer>
            </Link>
            <Content isOpen={id === openNoticeId}>{contents}</Content>
          </MenuTab>
        ))}
      </MenuList>
    </Main>
  );
};

const Main = styled(Container)`
  padding: 55px 0 0 0;
  .back {
    left: 15px;
  }
`;
const Top = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
`;
const MenuList = styled.div`
  padding: 0 15px;
  border-bottom: 1px solid #ebebeb;
  width: 100%;
`;
const MenuTab = styled.div`
  border-bottom: 1px solid #ebebeb;
  padding: 13px 0;
  cursor: pointer;
  &:last-child {
    border: none;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.p<{ isOpen: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: ${({ isOpen }) => (isOpen ? "normal" : "nowrap")};
  max-width: 488px;
`;
const Date = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #b3b3b3;
`;
const Content = styled.div<{ isOpen: boolean }>`
  min-height: 170px;
  background-color: #f7f7f7;
  padding: 24px 19px;
  font-size: 12px;
  color: #333;
  margin-top: 13px;
  display: ${({ isOpen }) => !isOpen && "none"};
`;

export default Notice;
