import Moment from "react-moment";

import { colorData } from "datas/yetColorData";
import { ProductsType } from "types/products";
import {
  Content,
  ContentTitle,
  ContentWrap,
} from "components/atoms/yetEndProducts";

const Bottom = ({ data }: { data: ProductsType }) => {
  const { fund_end_at, apy, max_amount, coin_id, recommend_bonus_apy, unit } =
    data;

  return (
    <>
      <ContentWrap>
        <ContentTitle>예상 수익률</ContentTitle>
        <Content
          color={colorData[coin_id - 1].fontColor}
          bgColor={colorData[coin_id - 1].borderColor}
        >
          {`최소 ${apy}% ~ 최대 ${apy + recommend_bonus_apy}% `}
        </Content>
      </ContentWrap>
      <ContentWrap>
        <ContentTitle>총 모집 수량</ContentTitle>
        <Content>
          {max_amount.toLocaleString()}&nbsp;{unit}
        </Content>
      </ContentWrap>
      <ContentWrap>
        <ContentTitle>신청 기간</ContentTitle>
        <Content>
          ~&nbsp;
          <Moment local format="YYYY.MM.DD">
            {fund_end_at}
          </Moment>
        </Content>
      </ContentWrap>
    </>
  );
};

export default Bottom;
