import { useState } from "react";
import useInputs from "hooks/useInputs";

import Info from "components/templates/Account/SignUpInfo";
import Privacy from "components/templates/Account/SignUpPrivacy";

const SignUp = () => {
  const [page, setPage] = useState(0);
  const [state, onChange] = useInputs({
    email: "",
    password: "",
    name: "",
    phone_number: "",
    birthday: "",
  });

  return (
    <>
      {
        [
          <Info setPage={setPage} state={state} onChange={onChange} />,
          <Privacy setPage={setPage} state={state} onChange={onChange} />,
        ][page]
      }
    </>
  );
};

export default SignUp;
