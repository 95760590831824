import { ReactComponent as Email } from "assets/icons/Landing/Email.svg";
import { ReactComponent as Krw } from "assets/icons/Product/krw-active.svg";
import { ReactComponent as Explain1 } from "assets/icons/Landing/Explain1.svg";
import { ReactComponent as Explain2 } from "assets/icons/Landing/Explain2.svg";
import { ReactComponent as Explain3 } from "assets/icons/Landing/Explain3.svg";
import { ReactComponent as Explain4 } from "assets/icons/Landing/Explain4.svg";
import { ReactComponent as Ripple } from "assets/icons/Product/xrp-active.svg";
import { ReactComponent as Bitcoin } from "assets/icons/Product/btc-active.svg";
import { ReactComponent as Etherieum } from "assets/icons/Product/eth-active.svg";
import { ReactComponent as OffBitcoin } from "assets/icons/Product/btc-inactive.svg";
import { ReactComponent as OffEtherieum } from "assets/icons/Product/eth-inactive.svg";
import { ReactComponent as OffRipple } from "assets/icons/Product/xrp-inactive.svg";
import { ReactComponent as OffKrw } from "assets/icons/Product/krw-inactive.svg";

export const coinData = [
  { id: 1, unit: "KRW", img: <Krw /> },
  { id: 2, unit: "BTC", img: <Bitcoin /> },
  { id: 3, unit: "ETH", img: <Etherieum /> },
  { id: 4, unit: "XRP", img: <Ripple /> },
];
export const periodData = [
  { name: "세 달", month: 3 },
  { name: "반 년", month: 6 },
  { name: "일 년", month: 12 },
];
export const profitData = [
  { id: 1, unit: "KRW", img: <Krw />, offImg: <OffKrw /> },
  { id: 2, unit: "BTC", img: <Bitcoin />, offImg: <OffBitcoin /> },
  { id: 3, unit: "ETH", img: <Etherieum />, offImg: <OffEtherieum /> },
  { id: 4, unit: "XRP", img: <Ripple />, offImg: <OffRipple /> },
];
export const contactData = [
  // {
  //   id: 1,
  //   img: <Tel />,
  //   title: "준비 중 입니다",
  //   content: "평일 10:00 - 19:00 / 주말 및 공휴일 휴무",
  // },
  {
    id: 2,
    img: <Email />,
    title: "cs@idealfarm.co.kr",
    content: "3일 이내 응답 / 주말 및 공휴일 휴무",
  },
];

export const explainData = [
  {
    id: 1,
    title: "편리한 예치 서비스",
    text: `간편하게 예치를 신청하고,\n간단하게 수익금을 누릴 수 있습니다.`,
    img: <Explain1 />,
  },
  {
    id: 2,
    title: "빠르고 지속적인 수익 창출",
    text: "빠른 만기를 통한 수익으로\n복리의 마법을 느껴보세요.",
    img: <Explain2 />,
  },
  {
    id: 3,
    title: "안전한 예치 관리 시스템",
    text: "다양한 시스템과 플랫폼을 활용해\n고객분들의 자산을 안전히 보호합니다.",
    img: <Explain3 />,
  },
  {
    id: 4,
    title: "안정적인 트랙 레코드",
    text: "1년 가량의 시범 운영을 거쳐\n안정적인 예치자금 운영이 가능합니다.",
    img: <Explain4 />,
  },
];
