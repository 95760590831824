import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import investState from "stores/invest";
import { walletInfoState } from "stores/walletInfo";
import { checkOwnNft } from "utils/checkOwnNft";
import { getExpiryDate, getExpiryPoint } from "utils/getDDay";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";
import Tree from "components/templates/Invest/TopBox/Tree";
import Advice from "components/templates/Invest/TopBox/Advice";
import Backdrop from "components/templates/Invest/TopBox/Backdrop";
import BtnNFT from "components/templates/Invest/TopBox/BtnNFT";
import InfoNFT from "components/templates/Invest/TopBox/InfoNFT";

const TopBox = () => {
  const { data, contractAddress } = useRecoilValue(investState);
  const walletInfo = useRecoilValue(walletInfoState);
  const {
    typed_wallet,
    code,
    amount,
    deposit_end_at,
    deposit_start_at,
    duration_month,
    name,
    name_en,
    is_need_bank,
    rate,
    all_amount,
    verify_deposit,
    verify_certificate,
    mintingDate,
    id,
  } = data;

  const isConfirm = typed_wallet === "인증완료" || typed_wallet === "입금완료";
  const expiryDatePoint = getExpiryPoint(deposit_start_at, deposit_end_at);
  const expiryDate = getExpiryDate(deposit_end_at);
  // krw
  const isKRW = is_need_bank === 1;
  // 만기 수익률 포맷
  const rateFormat = isKRW ? Math.floor(rate) : Math.floor(rate * 100) / 100;
  // 만기 시 환급 금액
  const allAmountFormat = isKRW
    ? Math.floor(all_amount)
    : Math.floor(all_amount * 100) / 100;
  const [isOwner, setIsOwner] = useState(false);

  const checkOwner = async () => {
    const result = await checkOwnNft({ contractAddress, id, walletInfo });
    if (result) setIsOwner(true);
  };

  useEffect(() => {
    checkOwner();
  }, [walletInfo && mintingDate]);

  return (
    <InfoBox>
      {!isConfirm && (
        <Backdrop typed_wallet={typed_wallet} is_need_bank={is_need_bank} />
      )}
      <TitleContianer>
        <TitleWrap>
          <Title>
            {name} {expiryDatePoint === 5 && <DdayBox>만기</DdayBox>}
          </Title>
          <SubTitle>{code}</SubTitle>
        </TitleWrap>
        <Tree
          typed_wallet={typed_wallet}
          deposit_start_at={deposit_start_at}
          deposit_end_at={deposit_end_at}
          mintingDate={mintingDate}
        />
      </TitleContianer>
      <Info confirm={isConfirm}>
        <ContentWrap>
          <InfoTitle>남은 만기일</InfoTitle>
          <InfoContentPink>{expiryDate}일</InfoContentPink>
        </ContentWrap>
        <ContentWrap>
          <InfoTitle>예치 기간</InfoTitle>
          <InfoContent>{duration_month}개월</InfoContent>
        </ContentWrap>
        <ContentWrap>
          <InfoTitle>예치 금액</InfoTitle>
          <InfoContent>
            {amount.toLocaleString()}&nbsp;
            {name_en}
          </InfoContent>
        </ContentWrap>
        <ContentWrap>
          <InfoTitle>만기 시 환급 금액</InfoTitle>
          <InfoContent>
            {allAmountFormat.toLocaleString()}&nbsp;
            {name_en}
          </InfoContent>
        </ContentWrap>
        <ContentWrap>
          <InfoTitle>만기 시 수익률</InfoTitle>
          <InfoContent>{rateFormat.toLocaleString()}&nbsp;%</InfoContent>
        </ContentWrap>
      </Info>
      <Advice
        typed_wallet={typed_wallet}
        verify_deposit={verify_deposit}
        is_need_bank={is_need_bank}
        verify_certificate={verify_certificate}
        deposit_start_at={deposit_start_at}
        deposit_end_at={deposit_end_at}
      />
      {mintingDate ? (
        <InfoNFT
          date={mintingDate}
          address={contractAddress}
          isOwner={isOwner}
          walletInfo={walletInfo}
        />
      ) : // <BtnNFT />
      null}
    </InfoBox>
  );
};

const InfoBox = styled.div`
  max-width: ${SIZE.mediumWidth};
  background-color: #fff;
  padding: 27px 22px 18px;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin: 0 auto;
  position: relative;
`;
const TitleContianer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
  svg {
    width: 31px;
    height: 31px;
  }
`;
const TitleWrap = styled.div`
  margin-left: 7px;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
`;
const DdayBox = styled.div`
  background: ${COLORS.pink};
  border-radius: 2px;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  padding-bottom: 2px;
  margin-left: 5px;
`;
const SubTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.lightGray};
`;
const Info = styled.div<{ confirm: boolean }>`
  background: #f5f5f5;
  border-radius: 4px;
  padding: 13px;
  margin-top: 15px;
  z-index: 2;
  position: ${({ confirm }) => (confirm ? "relative" : "static")};
`;
const ContentWrap = styled.div`
  display: flex;
`;
const InfoTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.gray};
`;
const InfoContent = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  flex: 1;
  text-align: right;
`;
const InfoContentPink = styled(InfoContent)`
  color: ${COLORS.pink};
`;

export default TopBox;
