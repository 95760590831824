import styled from "styled-components";
import { useEffect, useRef, useCallback } from "react";

import COLORS from "components/atoms/colors";

type SliderProps = {
  percent: number;
  onChange: (percent: number) => void;
};

const Slider = ({ percent, onChange }: SliderProps) => {
  const containerEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const onMouseUp = () => {
      document.body.removeEventListener("mousemove", onMouseMove);
    };
    document.body.addEventListener("mouseup", onMouseUp);
    return () => {
      document.body.removeEventListener("mouseup", onMouseUp);
      document.body.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  const handlePercent = (pageX: number) => {
    if (containerEl.current === null) return;

    const { left, width } = containerEl.current.getBoundingClientRect();
    let percent = ((pageX - left) / width) * 100;
    if (percent < 0.01) percent = 0.01;
    if (percent > 100) percent = 100;
    onChange(percent);
  };

  // 마우스 움직일 때
  const onMouseMove = useCallback((e) => handlePercent(e.pageX), []);
  const handleMouseDown = () =>
    document.body.addEventListener("mousemove", onMouseMove);

  //터치 움직일 때
  const onTouchMove = useCallback((e) => {
    const { screenX } = e.changedTouches[0];
    handlePercent(screenX);
  }, []);
  const handleTouchStart = () =>
    document.body.addEventListener("touchmove", onTouchMove);
  const handleTouchEnd = () =>
    document.body.removeEventListener("touchmove", onTouchMove);

  return (
    <Container ref={containerEl} onClick={onMouseMove} percent={percent}>
      <Bar />
      <Button
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      />
    </Container>
  );
};

const Bar = styled.div`
  height: 100%;
  background: ${COLORS.pink};
  position: absolute;
  width: 50%;
  border-radius: 6px;
`;
const Button = styled.button`
  position: absolute;
  background: ${COLORS.pink};
  height: 20px;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border-radius: 50%;
`;
const Container = styled.div<{ percent: number }>`
  width: 280px;
  cursor: pointer;
  background-color: #f0f0f0;
  height: 9px;
  position: relative;
  border-radius: 6px;
  ${Bar} {
    width: ${({ percent }) => percent}%;
  }
  ${Button} {
    left: calc(${({ percent }) => percent}% - 10px);
  }
`;

export default Slider;
