import { useQuery } from "react-query";
import styled from "styled-components";
import { RefObject, useState } from "react";

import { coins } from "datas/coin";
import { getCoins } from "api/coin";
import { PropsType } from "types/landing";
import { profitData } from "datas/landing";
import { CoinsType } from "types/coin";
import { ReactComponent as ProfitBGLeft } from "assets/images/Landing/ProfitBGLeft.svg";
import { ReactComponent as ProfitBGRight } from "assets/images/Landing/ProfitBGRight.svg";
import { ReactComponent as ProfitBGCenter } from "assets/images/Landing/ProfitBGCenter.svg";
import { ReactComponent as ProfitBGCenter2 } from "assets/images/Landing/ProfitBGCenter2.svg";
import COLORS from "components/atoms/colors";
import Calculator from "components/templates/Landing/Profit/Calculator";

const Yield = ({ propsRef }: PropsType) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeCoin = coins[activeIndex];
  const { data, isLoading } = useQuery<CoinsType[]>("coins", getCoins);
  if (isLoading) return null;
  const coin = data;

  return (
    <>
      {coin === undefined ? null : (
        <Layout ref={propsRef}>
          <BackgroundWrap>
            <ProfitBGLeft />
            <ProfitBGCenter />
            <ProfitBGCenter2 />
            <ProfitBGRight />
          </BackgroundWrap>
          <Container>
            <Left>
              <TextContainer>
                <MainTitle>수익률 계산기</MainTitle>
                <SubTitle>
                  예치 기간에 따른 <br />
                  나의 수익률은?
                </SubTitle>
                <Text>
                  상품에 따른 수익률을&nbsp;
                  <br />
                  간편하게 계산해보세요.
                </Text>
              </TextContainer>
              <CoinList>
                {/* {coin.map((data: CoinsType, i: number) => (
                  <Coin key={i}>
                    <CoinCircle
                      onClick={() => {
                        // setActiveIndex(i);
                        if (i !== 0) {
                          alert("준비 중 입니다.");
                        }
                      }}
                    >
                      {activeIndex === i ? profitData[i].img : profitData[i].offImg}
                    </CoinCircle>
                    <CoinTitle active={activeIndex === i}>
                      {data.name_kr}
                    </CoinTitle>
                  </Coin>
                ))} */}
              </CoinList>
            </Left>
            <Right>
              <Calculator coin={activeCoin} />
            </Right>
          </Container>
        </Layout>
      )}
    </>
  );
};

const Layout = styled.section`
  position: relative;
`;
const BackgroundWrap = styled.div`
  background: rgb(241, 241, 241);
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  svg {
    position: absolute;
  }
  .bgL {
    left: 0;
    bottom: 0;
    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }
  .bgR {
    right: 0;
    top: 0;
    @media only screen and (max-width: 787px) {
      display: none;
    }
  }
  .bgC {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -20%);
    @media only screen and (max-width: 1200px) {
      left: 0;
      bottom: 0;
      transform: translate(-10%, 30%);
    }
    @media only screen and (max-width: 787px) {
      left: unset;
      right: 0;
      bottom: 0;
      transform: none;
    }
  }
  .bgC2 {
    display: none;
    @media only screen and (max-width: 787px) {
      display: block;
      left: 0;
      top: 0;
    }
  }
`;
const Container = styled.div`
  padding: 109px 0 99px 0;
  display: flex;
  justify-content: space-evenly;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 787px) {
    padding: 59px 0 66px 0;
  }
`;
const Left = styled.div`
  @media only screen and (max-width: 1200px) {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 73px;
  }
  @media only screen and (max-width: 787px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 38px;
  }
`;
const TextContainer = styled.div`
  @media only screen and (max-width: 787px) {
    text-align: center;
  }
`;
const MainTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${COLORS.pink};
  line-height: 23px;
  margin-bottom: 5px;
  @media only screen and (max-width: 1200px) {
    font-size: 14px;
    line-height: 21px;
  }
`;
const SubTitle = styled.p`
  font-size: 27px;
  font-weight: 700;
  line-height: 39px;
  margin-bottom: 14px;
  @media only screen and (max-width: 1200px) {
    font-size: 24px;
    line-height: 35px;
    br {
      display: none;
    }
  }
  @media only screen and (max-width: 787px) {
    font-size: 21px;
    line-height: 30px;
  }
`;
const Text = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.gray};
  margin-bottom: 62px;
  @media only screen and (max-width: 1200px) {
    margin: 0;
    br {
      display: none;
    }
    @media only screen and (max-width: 787px) {
      display: none;
    }
  }
`;
const CoinList = styled.div`
  display: flex;
  @media only screen and (max-width: 1200px) {
    margin-top: 20px;
  }
`;
const CoinCircle = styled.div`
  width: 53px;
  height: 53px;
  cursor: pointer;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  svg {
    margin: 0;
    margin-top: 5px;
  }
  @media only screen and (max-width: 787px) {
    width: 45px;
    height: 45px;
  }
`;
const CoinCircleMini = styled(CoinCircle)`
  width: 26px;
  height: 26px;
  margin: 0 7px 0 0;
  @media only screen and (max-width: 787px) {
    width: 20px;
    height: 20px;
  }
`;
const Coin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 33px;
  @media only screen and (max-width: 787px) {
    margin: 0;
    & + & {
      margin-left: 33px;
    }
  }
`;
const CoinTitle = styled.p<{ active?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  color: ${({ active }) => !active && "#bebebe"};
  @media only screen and (max-width: 787px) {
    font-size: 12px;
    line-height: 17px;
  }
`;
const Right = styled.div`
  max-width: 550px;
`;
const CoinWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const CoinTitleBig = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  padding-top: 3px;
  @media only screen and (max-width: 787px) {
    font-size: 17px;
    line-height: 25px;
  }
`;

export default Yield;
