import styled from "styled-components";
import { Link } from "react-router-dom";

import { coinData } from "datas/landing";
import { ProductsType } from "types/products";
import getDDay, { convertFromStringToDate, dateGap } from "utils/getDDay";
import COLORS from "components/atoms/colors";
import { InfoGraph } from "components/atoms/main";

const getProductStatus = (status: string) => {
  if (status === "모집중") return "신청하기";
  if (status === "예정") return "준비중인 상품입니다.";
  if (status === "마감") return "마감";
};
const Item = ({ data }: { data: ProductsType }) => {
  const {
    id,
    coin_id,
    code,
    duration_month,
    max_amount,
    apy,
    recommend_bonus_apy,
    fund_end_at,
    name_en,
    collected_amount,
    status,
    name,
  } = data;
  const coin = coinData.find((i) => i.id === coin_id);
  const { day, hours, minutes } = getDDay(fund_end_at);
  const dateOver = dateGap("", fund_end_at) < 0;
  const isOpen = status === "모집중";
  const productStatus = getProductStatus(status);

  return (
    <ItemContainer>
      <ItemTop>
        <NameWrap>
          <ItemName>{name}</ItemName>
          <ItemCode>{code}</ItemCode>
        </NameWrap>
        <ItemCode>{coin?.img}</ItemCode>
      </ItemTop>
      <ItemBottom>
        <AdviceContainer>
          <AdviceWrap>
            <AdviceTitle>예상 연간 수익률</AdviceTitle>
            <AdviceContent pink={true}>
              최소 {apy}% ~ 최대 {apy + recommend_bonus_apy}%
            </AdviceContent>
          </AdviceWrap>
          <AdviceWrap>
            <AdviceTitle>총 모집 수량</AdviceTitle>
            <AdviceContent pink={false}>
              {max_amount.toLocaleString()}&nbsp; {name_en}
            </AdviceContent>
          </AdviceWrap>
          <AdviceWrap>
            <AdviceTitle>예치 기간</AdviceTitle>
            <AdviceContent pink={false}>{duration_month} 개월</AdviceContent>
          </AdviceWrap>
        </AdviceContainer>
        <InfoContainer>
          <AdviceWrap>
            <AdviceTitle>신청기간</AdviceTitle>
            <InfoContent>~ {convertFromStringToDate(fund_end_at)}</InfoContent>
          </AdviceWrap>
          <AdviceWrap>
            <AdviceTitle>남은 수량</AdviceTitle>
            <InfoContent>
              {max_amount - collected_amount < 0
                ? "모집 완료"
                : `${(
                    max_amount - collected_amount
                  ).toLocaleString()} ${name_en}`}
            </InfoContent>
          </AdviceWrap>
          <AdviceWrap>
            <AdviceTitle>현재 모집률</AdviceTitle>
            <InfoContent>
              {((+collected_amount / +max_amount) * 100).toLocaleString()}%
            </InfoContent>
          </AdviceWrap>
          <InfoGraph
            value={(+collected_amount / +max_amount) * 100}
            max="100"
          />
        </InfoContainer>
        <ApplyButton disabled={!isOpen}>
          <Link to={`/products/${id}`}>{productStatus}</Link>
        </ApplyButton>
        {!dateOver && (
          <EndDate>
            {isOpen
              ? `마감까지 ${day}일 ${hours}시간 ${minutes}분`
              : `신청이 불가능한 상품입니다.`}
          </EndDate>
        )}
      </ItemBottom>
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  padding: 18px 20px;
  border: 1px solid ${COLORS.lightestGray};
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #fff;
`;
const ItemTop = styled.div`
  padding-bottom: 10px;
  margin-bottom: 8.5px;
  border-bottom: 1px solid ${COLORS.lightestGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    width: 40px;
    height: 40px;
    fill: #373737;
  }
`;
const NameWrap = styled.div``;
const ItemName = styled.p`
  font-size: 17px;
  font-weight: bold;
  line-height: 24px;
`;
const ItemCode = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  color: ${COLORS.lightGray};
`;
const ItemBottom = styled.div`
  display: flex;
  flex-direction: column;
  a {
    width: 250px;
  }
`;
const AdviceContainer = styled.div``;
const AdviceWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
`;
const AdviceTitle = styled.p`
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.gray};
`;
const AdviceContent = styled.p<{ pink: boolean }>`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${({ pink }) => (pink ? "700" : "500")};
  color: ${({ pink }) => pink && COLORS.pink};
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: 10px 15px 15px 15px;
  margin-top: 13px;
  background: #f5f5f5;
`;
const InfoContent = styled.p`
  font-size: 12px;
  line-height: 17px;
`;
const ApplyButton = styled.div<{ disabled: boolean }>`
  background: ${COLORS.pink};
  border-radius: 40px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin: 13px auto 5px auto;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  a {
    border-radius: 40px;
    height: 45px;
    width: inherit;
    background-color: ${({ disabled }) =>
      disabled ? COLORS.gray : COLORS.pink};
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: ${({ disabled }) => disabled && "none"};
  }
`;
const EndDate = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #999999;
  text-align: center;
`;

export default Item;
