import styled from "styled-components";

import SIZE from "components/atoms/size";
import YetProducts from "components/templates/YetEndProducts/YetProducts";
import EndProducts from "components/templates/YetEndProducts/EndProducts";

const YetEndProducts = () => {
  return (
    <Container>
      <Top>진행예정 상품</Top>
      <YetProducts />
      <EndProducts />
    </Container>
  );
};

const Container = styled.div`
  max-width: ${SIZE.largeWidth};
  margin: 0 auto;
  border: 1px solid #e0e0e0;
`;
const Top = styled.div`
  padding: 26px 10px 10px 16px;
  font-weight: bold;
`;

export default YetEndProducts;
