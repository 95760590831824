import { useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { postInvest } from "api/invest";
import { getProduct } from "api/product";
import useInputs from "hooks/useInputs";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";
import Top from "components/templates/Product/Top";
import Info from "components/templates/Product/Info";
import Wallet from "components/templates/Product/Wallet";
import Caution from "components/templates/Product/Caution";
import Contract from "components/templates/Product/Contract";
import Complete from "components/templates/Product/Complete";
import Empty from "components/pages/Empty";

const ProductDetail = () => {
  const { id = "" } = useParams();
  const [isComplete, setIsComplete] = useState(false);
  const [owner, setOwner] = useState("");
  const [state, onChange] = useInputs({
    product_id: id,
    amount: 0,
    wallet_address: "",
    recommend_code: "",
    bank: "",
    account_number: "",
    account_holder: "",
  });
  const { data, isLoading } = useQuery(["product", id], () => getProduct(id));
  if (isLoading) return null;
  if (!isLoading && !data) return <Empty />;
  const { amount, wallet_address } = state;
  const { min_amount, unit, is_need_bank, address } = data;

  const handleSubmitCheck = () => {
    if (amount < min_amount) {
      alert("신청 수량이 최소금액보다 적습니다.");
      return false;
    }
    if (is_need_bank) {
      const { bank, account_holder, account_number } = state;
      if (!bank || !account_holder || !account_number) {
        alert("계좌 정보가 올바르지 않습니다.");
        return false;
      }
    } else {
      if (!wallet_address) {
        alert("지갑 주소가 올바르지 않습니다.");
        return false;
      }
    }
    return true;
  };
  const handleSubmit = async () => {
    if (!handleSubmitCheck()) return;
    const { success, message } = await postInvest(state);
    if (success) {
      alert("투자신청이 완료되었습니다.");
      setIsComplete(true);
      setOwner(address);
    } else if (message) {
      alert(message);
    } else {
      alert("투자신청에 실패하였습니다. 입력 정보를 확인해주세요.");
    }
  };

  return (
    <>
      {isComplete ? (
        <Complete owner_account={owner} unit={unit} data={state} />
      ) : (
        <>
          <Top />
          <Main>
            <InfoContainer>
              <Info data={data} />
              <Contract data={data} onChange={onChange} state={state} />
              <Wallet onChange={onChange} data={data} />
            </InfoContainer>
            <Caution />
            <ApplyContainer>
              <ApplyContent>
                모든 내용을 확인하였으며 모든 약관에 동의합니다.
              </ApplyContent>
              <ApplyButton onClick={handleSubmit}>신청하기</ApplyButton>
            </ApplyContainer>
          </Main>
        </>
      )}
    </>
  );
};

const Main = styled.main`
  max-width: ${SIZE.largeWidth};
  min-height: 100vh;
  background: #f5f5f5;
  margin: 0 auto;
  padding-bottom: 239px;
  border: 1px solid #e0e0e0;
  border-top: none;
`;
const InfoContainer = styled.div`
  padding: 0 15px 0 15px;
`;
const ApplyContainer = styled.div`
  padding: 0 15px;
`;
const ApplyContent = styled.p`
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.gray};
  text-align: center;
`;
const ApplyButton = styled.button`
  background: ${COLORS.pink};
  width: 100%;
  max-width: 346px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin: 10px auto 0 auto;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default ProductDetail;
