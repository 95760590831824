import styled from "styled-components";

import { AdviceType } from "types/invest";
import { getExpiryText, getIsExpiryNear } from "utils/getDDay";
import { ReactComponent as ProductSafe } from "assets/icons/Main/ProductSafe.svg";
import COLORS from "components/atoms/colors";
import Modal from "components/organisms/Modal";
import ModalHistory from "components/templates/Invest/TopBox/ModalHistory";
import ModalConfirm from "components/templates/Invest/TopBox/ModalConfirm";
import ModalConfirmKRW from "components/templates/Invest/TopBox/ModalConfirmKRW";

const Advice = ({
  typed_wallet,
  verify_deposit,
  is_need_bank,
  verify_certificate,
  deposit_start_at,
  deposit_end_at,
}: AdviceType) => {
  const expiryDateText = getExpiryText(
    typed_wallet,
    deposit_start_at,
    deposit_end_at
  );
  const isNear = getIsExpiryNear(deposit_start_at, deposit_end_at);
  const isDeposit = typed_wallet === "입금완료";
  const isChecking = typed_wallet === "확인중";
  const isNeedBank = is_need_bank === 1;

  return (
    <Container>
      <AdviceTextWrap>
        <ProductSafe />
        <AdviceText red={isNear}>
          {isChecking ? (
            <>
              <AdviceTextWrap>관리자가 입금 확인 중입니다 </AdviceTextWrap>
              <Modal
                activator={(onOpen) => (
                  <AdviceBtn onClick={onOpen}>증명 확인</AdviceBtn>
                )}
                content={(onClose) =>
                  isNeedBank ? (
                    <ModalConfirmKRW
                      onClose={onClose}
                      verify_certificate={verify_certificate}
                    />
                  ) : (
                    <ModalConfirm
                      onClose={onClose}
                      depositId={verify_deposit}
                    />
                  )
                }
              />
            </>
          ) : (
            expiryDateText
          )}
        </AdviceText>
      </AdviceTextWrap>
      {isDeposit && (
        <Modal
          activator={(onOpen) => (
            <CheckDeposit onClick={onOpen}>입금 내역 확인하기</CheckDeposit>
          )}
          content={(onClose) => <ModalHistory onClose={onClose} />}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 13px 0 15px;
  border-radius: 0 0 8px 8px;
  position: relative;
`;
const AdviceText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #343434;
  color: ${({ red }: { red?: boolean }) =>
    red ? COLORS.pink : COLORS.darkerGray};
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const AdviceTextWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
const AdviceBtn = styled.button`
  height: 28px;
  width: 70px;
  background-color: #343434;
  border-radius: 2px;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
`;
const CheckDeposit = styled.div`
  width: 100%;
  height: 50px;
  background: #000000;
  border-radius: 4px;
  margin: 15px auto 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default Advice;
