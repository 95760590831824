import styled from "styled-components";

import { ReactComponent as Logo } from "assets/icons/Landing/FooterLogo.svg";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";

const Footer = () => {
  return (
    <Layout>
      <Container>
        <MenuTab>
          <InfoBox>
            <InfoText>
              (주) 아이디얼팜 ㅣ 대표이사 조은철 ㅣ<br />
              {/* 사업자등록번호 123-12-12345 ㅣ  */}
              이메일 : cs@idealfarm.co.kr
            </InfoText>
            <InfoText>
              주소 : 서울특별시 중구 을지로 50 을지한국빌딩 17층 1704호
              <br />
              {/* l 대표 전화 : 준비중... (평일 10:00 - 19:00 / 주말 및 공휴일 휴무) */}
            </InfoText>
          </InfoBox>
          <LogoWrap>
            <Logo />
          </LogoWrap>
        </MenuTab>
        <Copyright>Copyright Ideal Farm. All rights reserved.</Copyright>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  background-color: #252525;
  padding: 28px 0 83px;
  @media only screen and (max-width: 1200px) {
    padding: 28px 67px 83px;
  }
  @media only screen and (max-width: 787px) {
    padding: 28px 23px 76px 23px;
    bottom: 0;
  }
`;
const Container = styled.div`
  max-width: ${SIZE.landingWidth};
  margin: 0 auto;
`;
const MenuTab = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  @media only screen and (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;
const LogoWrap = styled.div`
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
const InfoBox = styled.div`
  padding-top: 5px;
  flex: 1;
`;
const InfoText = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.lighterGray};
  br {
    display: none;
  }
  @media only screen and (max-width: 787px) {
    font-size: 12px;
    br {
      display: block;
    }
  }
`;
const Copyright = styled.p`
  font-size: 12px;
  color: ${COLORS.lightGray};
`;

export default Footer;
