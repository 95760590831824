import { useState } from "react";

import { AccountType } from "types/account";
import { OnChangeType } from "types/index";
import { SetStateNumberType } from "types/index";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import {
  FindBtn,
  FindInput,
  FindContainer,
  FindForm,
  TitleContainer,
  Title,
  SubTitle,
  FindInputWrap,
  Check,
  Container,
} from "components/atoms/account";
import Terms from "components/templates/Account/SignUpTerms";

const SignUpPrivacy = ({
  setPage,
  state,
  onChange,
}: {
  setPage: SetStateNumberType;
  state: AccountType;
  onChange: OnChangeType;
}) => {
  // const [request, setRequest] = useState(false);
  // const [certification, setCertification] = useState("");
  const [isTerms, setIsTerms] = useState(false);
  const { name, birthday, phone_number } = state;
  const isNameOk = 1 < name?.length && name.length < 6;
  const isBirthOk = birthday?.length !== 0;
  const numberRegex = /^[0-9]*$/;
  const isNumberOk = numberRegex.test(phone_number);

  return (
    <Container>
      <Back onClick={() => setPage(0)} />
      <TitleContainer>
        <Title>회원가입</Title>
        <SubTitle>
          개인정보를
          <br />
          입력해주세요.
        </SubTitle>
      </TitleContainer>
      <FindContainer>
        <FindForm>
          <FindInputWrap>
            <FindInput
              placeholder="이름"
              value={name}
              onChange={(e) => {
                onChange(e);
              }}
              name="name"
            />
            {!isNameOk && <Check>2글자 이상 5글자 이하로 입력해주세요.</Check>}
          </FindInputWrap>
          <FindInputWrap>
            <FindInput
              value={birthday}
              onChange={(e) => {
                onChange(e);
              }}
              name="birthday"
              type="date"
              min="1900-01-01"
              max="2020-12-31"
            />
            {!isBirthOk && <Check right={"20px"}>날짜를 입력해주세요.</Check>}
          </FindInputWrap>
          <FindInputWrap>
            <FindInput
              placeholder="휴대폰 번호 (-없이 입력)"
              value={phone_number}
              onChange={(e) => {
                onChange(e);
              }}
              name="phone_number"
            />
            {!isNumberOk && <Check>숫자만 입력해주세요.</Check>}
            {/* <CertificateNumber
                disabled={!isNumber || !isBirth || !isName}
                onClick={() => setRequest(true)}
                isHide={!isNumber}
              >
                인증번호 요청
              </CertificateNumber>
            </FindInputWrap>
            {request && (
              <FindInput
                placeholder="인증번호 입력"
                value={certification}
                onChange={(e) => setCertification(e.target.value)}
                name="certification"
              />
            )} */}
          </FindInputWrap>
          <FindBtn
            // disabled={!certification}
            disabled={!isNameOk || !isBirthOk || !isNumberOk}
            onClick={() => {
              setIsTerms(true);
            }}
          >
            다음
          </FindBtn>
        </FindForm>
      </FindContainer>
      {isTerms && <Terms setIsTerms={setIsTerms} state={state} />}
    </Container>
  );
};

// const CertificateNumber = styled.button<{ isHide: boolean }>`
//   position: absolute;
//   font-size: 12px;
//   bottom: 23px;
//   right: 0;
//   padding: 9px 7px;
//   border-radius: 2px;
//   border: none;
//   color: #fff;
//   background-color: ${COLORS.pink};
//   &:disabled {
//     border: 1px solid #e0e0e0;
//     color: ${COLORS.gray};
//     background-color: #f2f2f2;
//   }
//   display: ${({ isHide }) => (isHide ? "none" : "static")};
// `;

export default SignUpPrivacy;
