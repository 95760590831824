import styled from "styled-components";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import { getMyInfo } from "api/user";
import { copyClipboard } from "utils";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import { Container } from "components/atoms/account";

const Recommend = () => {
  const { data, isLoading } = useQuery("info", getMyInfo);
  if (isLoading) return null;
  const { recommend_code } = data;
  const handleCopy = () => copyClipboard(recommend_code);

  return (
    <Container>
      <Link to="/my">
        <Back />
      </Link>
      <Title>내 추천인 코드</Title>
      <CodeContainer>
        <CodeInput type="text" value={recommend_code} readOnly />
        <CopyBtn onClick={handleCopy}>복사</CopyBtn>
      </CodeContainer>
    </Container>
  );
};

const Title = styled.p`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  margin: 24px 0 24px;
`;
const CodeContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  width: 100%;
`;
const CodeInput = styled.input`
  padding: 11px 55px 11px 10px;
  width: 100%;
  background: #f7f7f7;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 12px;
`;
const CopyBtn = styled.button`
  position: absolute;
  background: #ff3061;
  border-radius: 4px;
  padding: 6px 11px;
  color: #fff;
  font-size: 12px;
  right: 6px;
`;

export default Recommend;
