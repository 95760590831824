const landingWidth = "1200px";
const largeWidth = "640px";
const mediumWidth = "540px";
const accountWidth = "520px";
const smallWidth = "420px";
const size = {
  smallWidth,
  mediumWidth,
  largeWidth,
  landingWidth,
  accountWidth,
};
export default size;
