import { useState } from "react";

import Info from "components/templates/Account/FindPwInfo";
import Result from "components/templates/Account/FindPwResult";
import Certification from "components/templates/Account/FindPwCertification";

const FindPw = () => {
  const [pwPage, setPwPage] = useState<number>(0);

  return (
    <>
      {
        [
          <Info setPwPage={setPwPage} />,
          <Certification setPwPage={setPwPage} />,
          <Result setPwPage={setPwPage} />,
        ][pwPage]
      }
    </>
  );
};

export default FindPw;
