import styled from "styled-components";
import { Link } from "react-router-dom";

import Left from "assets/images/Product/ProductLeft.png";
import Right from "assets/images/Product/ProductRight.png";
import { ReactComponent as Arrow } from "assets/images/Product/Arrow.svg";
import { ReactComponent as Second } from "assets/icons/Product/Second.svg";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";

const Proof = ({
  data,
  unit,
}: {
  data: {
    amount: number;
    bank: string;
    account_number: string;
    account_holder: string;
    wallet_address: string;
  };
  unit: string;
}) => {
  const { amount, bank, account_number, account_holder, wallet_address } = data;

  return (
    <Container>
      <TitleContainer>
        <MainTitle>
          <Second />
          입금 증명하기
        </MainTitle>
        {bank === "" ? (
          <SubTitle>
            Ideal Farm 앱 홈 &gt; <span>내 가상자산 상품</span>에서 신청하신
            상품으로
            <br />
            들어가 <span>[입금 증명하기]</span>에 입금하신 거래 ID를
            입력해주세요.
          </SubTitle>
        ) : (
          <SubTitle>
            Ideal Farm 앱 홈 &gt; <span>내 가상자산 상품</span>에서 신청하신
            상품으로
            <br />
            들어가 <span>[입금 증명하기]</span>에 입금하신 이체 증명서를
            첨부해주세요.
          </SubTitle>
        )}
      </TitleContainer>
      <ImageContainer>
        <Img src={Left} />
        <Arrow />
        <Img src={Right} />
      </ImageContainer>
      {bank === "" ? (
        <Wallet>
          <WalletTitle>내 업비트 지갑 주소</WalletTitle>
          <WalletContent>{wallet_address}</WalletContent>
          <CenterLine />
          <WalletTitle>예치 수량</WalletTitle>
          <WalletContent>
            {amount}&nbsp;
            {unit}
          </WalletContent>
        </Wallet>
      ) : (
        <Wallet>
          <WalletTitle>내 입금 계좌 정보</WalletTitle>
          <WalletContent>
            {bank} {account_number} ({account_holder})
          </WalletContent>
          <CenterLine />
          <WalletTitle>예치 금액</WalletTitle>
          <WalletContent>
            {amount}&nbsp;
            {unit}
          </WalletContent>
        </Wallet>
      )}

      <Link to="/main">
        <NextBtn>투자 현황으로</NextBtn>
      </Link>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: ${SIZE.mediumWidth};
`;
const TitleContainer = styled.div``;
const MainTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  svg {
    margin-right: 7px;
  }
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: ${COLORS.gray};
  span {
    color: #000;
    font-weight: 500;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 23px 0;
`;
const Img = styled.img``;
const Wallet = styled.div`
  padding: 14px 10px 16px 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 25px;
`;
const WalletTitle = styled.p`
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.gray};
  font-weight: 500;
`;
const WalletContent = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
`;
const CenterLine = styled.div`
  min-width: 280px;
  width: 90%;
  border: 1px solid ${COLORS.lightestGray};
  margin: 8px auto;
`;
const NextBtn = styled.button`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  height: 45px;
  background-color: #000;
  color: #ffffff;
  border-radius: 4px;
  width: 100%;
`;

export default Proof;
