import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { alarm } from "datas/alarm";
import { getMyInfo } from "api/user";
import { walletInfoState } from "stores/walletInfo";
import { ReactComponent as Logo } from "assets/icons/MyInfo/logo.svg";
import { ReactComponent as Upbit } from "assets/icons/MyInfo/upbit.svg";
import { ReactComponent as Metamask } from "assets/icons/MyInfo/metamask.svg";
import COLORS from "components/atoms/colors";
import ModalNFT from "components/templates/My/ModalNFT";

const Profile = () => {
  const walletInfo = useRecoilValue(walletInfoState);
  const { data, isLoading } = useQuery("info", getMyInfo);
  const [isShowModal, setIsShowModal] = useState(false);
  if (isLoading) return <></>;
  const { name, email, phone_number } = data;
  const notice = alarm[alarm.length - 1].title;

  return (
    <>
      <Container>
        <Name>
          <Logo />
          {name} 님
        </Name>
        <ContactContainer>
          <Contact>{email}</Contact>
          <CenterLine />
          <Contact>{phone_number}</Contact>
          <Link to="info">
            <ChangeBtn>변경</ChangeBtn>
          </Link>
        </ContactContainer>
        <Link to="notice">
          <NoticeBox>
            <NoticeTitle>
              <span>[공지] </span>
              {notice}
            </NoticeTitle>
          </NoticeBox>
        </Link>
        <ChangeWallet>
          <Link to="wallets">
            <Upbit />
            <WalletTitle>업비트 지갑 주소 관리</WalletTitle>
          </Link>
          <WalletLine />
          <MyNFT onClick={() => setIsShowModal(true)}>
            <Metamask />
            <WalletTitle>NFT 지갑 관리/연동</WalletTitle>
          </MyNFT>
        </ChangeWallet>
        <MyWallet>
          <MyWalletTitle>NFT 지갑 주소</MyWalletTitle>
          <MyWalletInfo>
            {walletInfo ? walletInfo : "연동 된 주소가 없습니다."}
          </MyWalletInfo>
        </MyWallet>
      </Container>
      {isShowModal && (
        <ModalNFT onClose={() => setIsShowModal(false)} isProfile={true} />
      )}
    </>
  );
};

const Container = styled.div`
  background-color: #fff;
  padding: 28px 0 0;
  margin-bottom: 12px;
`;
const Name = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  display: flex;
  align-items: center;
  padding: 0 22px;
  svg {
    margin-right: 10px;
  }
`;
const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;
  padding: 0 22px;
`;
const Contact = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.gray};
`;
const CenterLine = styled.div`
  height: 9px;
  border: 1px solid ${COLORS.lightestGray};
  margin: 3px 10px 0;
`;
const ChangeBtn = styled.button`
  background: #fff;
  border: 1px solid ${COLORS.lightestGray};
  border-radius: 2px;
  color: ${COLORS.gray};
  font-size: 10px;
  line-height: 14.5px;
  padding: 2px 5px;
  margin-left: 13px;
`;
const NoticeBox = styled.div`
  background: #f0faff;
  border-radius: 4px;
  padding: 13px 14px;
  margin: 20px 22px 0;
`;
const NoticeTitle = styled.p`
  font-weight: 400;
  font-size: 10px;
  max-width: 300px;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    color: #2285ff;
  }
`;
const ChangeWallet = styled.div`
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
  }
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const MyNFT = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;
const WalletTitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
`;
const WalletLine = styled.div`
  width: 1px;
  height: 41px;
  background-color: ${COLORS.lightestGray};
  margin: 0 30px;
`;
const MyWallet = styled.div`
  display: flex;
  padding: 11px 22px;
  border-top: 1px solid ${COLORS.lightestGray};
`;
const MyWalletTitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  flex: 1;
`;
const MyWalletInfo = styled.p`
  font-weight: 400;
  line-height: 150%;
  font-size: 10px;
  color: ${COLORS.lightGray};
`;

export default Profile;
