import styled from "styled-components";
import { PropsType } from "types/landing";

import { ReactComponent as Apple } from "assets/icons/AppleIcon.svg";
import LandingBackground from "assets/images/LandingBackground2.png";
import { ReactComponent as Google } from "assets/icons/GoogleIcon.svg";
import { SubTitle, Title } from "components/atoms/landing";

const Intro = ({ propsRef }: PropsType) => {
  return (
    <Layout ref={propsRef}>
      <LandingImg url={LandingBackground} />
      <Container>
        <Content>
          <TextWrapper>
            <TitleThin>농장에 씨를 뿌리고 수확을 거두듯</TitleThin>
            <MainTitle>
              <span>자산의 씨앗</span>을 뿌려두세요
            </MainTitle>
          </TextWrapper>
          <SubContent>아이디얼팜 앱 다운로드</SubContent>
          <IconContainer>
            <Google
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.investfarmapp",
                  "_blank"
                )
              }
            />
            <Apple
              onClick={() =>
                window.open(
                  "https://apps.apple.com/kr/app/ideal-farm/id1630732202"
                )
              }
            />
          </IconContainer>
        </Content>
      </Container>
    </Layout>
  );
};

const Layout = styled.section`
  position: relative;
  height: 539px;
  padding-bottom: 135px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  @media only screen and (max-width: 767px) {
    padding: 160px 32px;
    display: flex;
    text-align: center;
    position: relative;
    margin-bottom: 10px;
  }
`;
const LandingImg = styled.div<{ url: string }>`
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${({ url }) => `url(${url})`};
  width: 100%;
  max-width: 100%;
  top: -80px;
  height: calc(100% + 80px);
  left: 0;
`;
const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  @media only screen and (max-width: 1200px) {
    max-width: 768px;
    padding: 0 30px;
  }
  @media only screen and (max-width: 767px) {
    padding: 0;
    align-items: flex-end;
  }
`;
const TextWrapper = styled.div`
  margin-bottom: 40px;
`;
const MainTitle = styled(Title)`
  color: #fff;
`;
const TitleThin = styled(Title)`
  font-weight: 400;
  color: #fff;
`;
const Content = styled.div`
  position: relative;
`;
const SubContent = styled(SubTitle)`
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
`;
const IconContainer = styled.div`
  svg {
    cursor: pointer;
    margin-right: 15px;
    cursor: pointer;
  }
`;
// const IntroInfo = styled.button`
//   background-color: #f6f6f6;
//   border-radius: 4px;
//   width: 415px;
//   height: 45px;
//   padding: 0 11px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   margin-top: 39px;
//   svg {
//     position: static;
//     z-index: 0;
//     margin-right: 10px;
//   }
// `;

export default Intro;
