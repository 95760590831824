import tree0 from "assets/images/Invest/tree0.png";
import tree1 from "assets/images/Invest/tree1.png";
import tree2 from "assets/images/Invest/tree2.png";
import tree3 from "assets/images/Invest/tree3.png";
import tree4 from "assets/images/Invest/tree4.png";
import tree5 from "assets/images/Invest/tree5.png";

export const expiryData = [
  {
    text: "아이디얼 팜에서, 씨앗을 심을 준비 중이에요. ",
    src: tree0,
  },
  {
    text: "시작이 반이에요! 아이디얼 팜에서의 새싹단계에요.",
    src: tree1,
  },
  {
    text: "무럭무럭 자라나고 있어요. 곧 꽃들도 피기 시작할거에요.",
    src: tree2,
  },
  {
    text: "꽃이 피었네요 열매를 맺기 까지 거의 다 왔어요.",
    src: tree3,
  },
  { text: "수확을 거둘때가 가까워지고 있어요.", src: tree4 },
  {
    text: "관리자가 빠른 시일 내에 입금 할 예정 입니다.",
    src: tree5,
  },
];
