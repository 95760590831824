import styled from "styled-components";
import { useQuery } from "react-query";

import { getYetEndProducts } from "api/product";
import { ProductsType } from "types/products";
import Item from "components/templates/YetEndProducts/YetItem/Item";

const YetProducts = () => {
  const { data, isLoading } = useQuery("products", getYetEndProducts);
  if (isLoading) return null;
  const yetData = data.filter((item: ProductsType) => item.status === "예정");
  return (
    <Container>
      {yetData.map((data: ProductsType, i: number) => (
        <Item data={data} key={i} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding: 26px 15px 50px;
  height: 100%;
`;

export default YetProducts;
