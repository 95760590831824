import styled from "styled-components";

import COLORS from "components/atoms/colors";

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  margin: 0;
  line-height: 46px;
  span {
    color: ${COLORS.pink};
  }
  @media only screen and (max-width: 1200px) {
    font-size: 24px;
    line-height: 35px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 29px;
  }
`;
export const TextWrapper = styled.div`
  margin-bottom: 12px;
  @media only screen and (max-width: 1200px) {
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 767px) {
    margin-bottom: 11px;
  }
`;
export const SubTitle = styled.p`
  font-size: 14px;
  color: ${COLORS.gray};
  line-height: 22px;
  white-space: pre-wrap;
`;
export const SubTitleBold = styled.p`
  font-size: 14px;
  color: ${COLORS.gray};
  line-height: 20px;
  font-weight: 700;
`;
export const SubContent = styled.p`
  font-size: 14px;
  color: ${COLORS.lightGray};
  line-height: 20px;
  margin-bottom: 3px;
  @media only screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 17px;
    margin: 0;
  }
`;
export const SubContentBold = styled.p`
  font-size: 14px;
  color: ${COLORS.lightGray};
  line-height: 20px;
  margin-bottom: 3px;
  font-weight: 700;
  @media only screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 17px;
  }
`;
export const MiniContent = styled.p`
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.lighterGray};
  @media only screen and (max-width: 767px) {
    font-size: 10px;
    line-height: 15px;
  }
`;
