import { useReducer } from "react";

const reducer = (state: any, action: any) => {
  const { name, value } = action;
  return {
    ...state,
    [name]: value,
  };
};

const useInputs = (initialForm: any) => {
  const [state, dispatch] = useReducer(reducer, initialForm);
  const onChange = (e: any) => dispatch(e.target);

  return [state, onChange];
};

export default useInputs;
