import styled from "styled-components";

import { PropsType } from "types/landing";
import { ReactComponent as Background } from "assets/images/Landing/ProductBackground.svg";
import { SubTitle, Title, TextWrapper } from "components/atoms/landing";
import List from "components/templates/Landing/Product/List";

const Product = ({ propsRef }: PropsType) => {
  return (
    <Layout ref={propsRef}>
      <Container>
        <Background />
        <TextContainer>
          <TextWrapper>
            <TextTitle>
              아이디얼 팜 <span>&nbsp;예치 상품</span>
            </TextTitle>
          </TextWrapper>
          <SubTitleWrap>
            <SubTitle>
              현재 진행중인 예치 상품을 소개드립니다.
              <br />
              좌우로 이동해 이전/예정 상품을 확인할 수 있습니다.
            </SubTitle>
          </SubTitleWrap>
        </TextContainer>
        <List />
      </Container>
    </Layout>
  );
};

const Layout = styled.section``;
const Container = styled.div`
  margin: 0 auto;
  padding: 84px 0 0;
  background: #fff;
  border: 1px solid #ffeef2;
  position: relative;
  .ProductBackground {
    position: absolute;
    width: 100%;
    height: 600px;
    left: 0;
    bottom: 73px;
    @media only screen and (max-width: 1200px) {
      height: 400px;
      bottom: 120px;
    }
  }
  @media only screen and (max-width: 1200px) {
    border-radius: 0;
    margin: 0 auto;
    height: 715px;
    padding: 84px 70px;
  }
  @media only screen and (max-width: 787px) {
    padding: 0;
    padding-top: 74px;
    height: 600px;
  }
`;
const TextContainer = styled.div`
  text-align: center;
`;
const TextTitle = styled(Title)`
  font-size: 27px;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 1200px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 787px) {
    font-size: 21px;
  }
`;
const SubTitleWrap = styled.div`
  margin-top: 10px;
`;

export default Product;
