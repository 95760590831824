import styled from "styled-components";

import { ReactComponent as Confirm } from "assets/icons/Product/ProductConfirm.svg";
import SIZE from "components/atoms/size";

const Title = () => {
  return (
    <Container>
      <TitleContainer>
        <Confirm />
        <MainTitle>상품 신청 완료</MainTitle>
        <SubTitle>신청완료 후 절차 안내</SubTitle>
      </TitleContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
  max-width: ${SIZE.mediumWidth};
`;
const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 46px;
  svg {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;
const MainTitle = styled.p`
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 5px;
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export default Title;
