export const copyClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);
  alert("클립보드에 복사되었습니다.");
};

export const imageToBase64 = (file: any) => {
  return new Promise<string>((resolve, reject) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      if (base64) {
        const result = base64.toString();
        resolve(result);
      }
    };
    reader.readAsDataURL(file); // 파일을 읽어 버퍼에 저장.
    // setImgFile(event.target.files[0]);
  });
};
