import styled from "styled-components";

import { ReactComponent as ItemConfirm } from "assets/icons/Main/ItemConfirm.svg";

const Backdrop = ({ confirm }: { confirm: string }) => {
  return (
    <>
      {confirm !== "인증완료" && confirm !== "입금완료" && (
        <Container>
          <ContentContainer>
            <ItemConfirm />
            <ContentWrapper>
              {confirm === "확인중" ? (
                <>
                  <Title>입금 확인 중인 상품입니다</Title>
                  <Content>최대 3일 소요</Content>
                </>
              ) : (
                <Title>입금 증명이 필요한 상품입니다</Title>
              )}
            </ContentWrapper>
          </ContentContainer>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  display: flex;
  justify-content: center;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ContentWrapper = styled.div`
  text-align: center;
  margin-top: 6px;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
`;
const Content = styled(Title)`
  font-size: 10px;
  line-height: 18px;
`;

export default Backdrop;
