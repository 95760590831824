import styled from "styled-components";

import { AdviceType } from "types/invest";
import { getExpiryTree } from "utils/getDDay";

const Tree = ({
  typed_wallet,
  deposit_start_at,
  deposit_end_at,
  mintingDate,
}: AdviceType) => {
  const isDeposit = !!mintingDate || typed_wallet === "입금완료";
  const expiryDateTree = getExpiryTree(deposit_start_at, deposit_end_at);

  return <Image src={expiryDateTree} isDeposit={isDeposit} />;
};

const Image = styled.img`
  position: absolute;
  bottom: ${({ isDeposit }: { isDeposit?: boolean }) =>
    isDeposit ? "310px" : "240px"};
  right: 22px;
  z-index: 0;
`;

export default Tree;
