import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import { Link, useNavigate } from "react-router-dom";

import { api } from "api/client";
import { login } from "api/user";
import useInputs from "hooks/useInputs";
import { LoginState } from "stores/LoginStates";
import { ReactComponent as Logo } from "assets/icons/Account/Logo.svg";
// import { ReactComponent as Kakao } from "assets/icons/Account/kakao.svg";
// import { ReactComponent as Apple } from "assets/icons/Account/apple.svg";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";

const Login = () => {
  const [state, onChange] = useInputs({ email: "", password: "" });
  const { email, password } = state;
  const navigate = useNavigate();
  const setIsLoggedIn = useSetRecoilState(LoginState);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = await login(state);
    if (result) {
      const token = result.data.token;
      localStorage.setItem("userToken", result.data.token);
      api.defaults.headers.common["Authorization"] = "Bearer " + token;
      setIsLoggedIn(true);
      navigate("/main");
    }
  };

  return (
    <Container>
      <Logo />
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit}>
          <LoginInput
            placeholder="아이디 (이메일)"
            name="email"
            value={email}
            onChange={onChange}
          />
          <LoginInput
            placeholder="비밀번호"
            type="password"
            name="password"
            value={password}
            onChange={onChange}
          />
          <LoginBtn type="submit">로그인</LoginBtn>
        </LoginForm>
        <RegisterContainer>
          <RegisterMenu>
            {/* <Link to="/find-pw"> */}
            <RegisterBtn onClick={() => alert("준비중입니다.")}>
              비밀번호 찾기
            </RegisterBtn>
            {/* </Link> */}
          </RegisterMenu>
          <CenterLine />
          <RegisterMenu>
            {/* <Link to="/find-id"> */}
            <RegisterBtn onClick={() => alert("준비중입니다.")}>
              아이디 찾기
            </RegisterBtn>
            {/* </Link> */}
          </RegisterMenu>
          <CenterLine />
          <RegisterMenu>
            <Link to="/signup">
              <RegisterBtn>회원가입</RegisterBtn>
            </Link>
          </RegisterMenu>
        </RegisterContainer>
      </LoginContainer>
      <SocialContainer>
        {/* <SocialTitle>소셜 로그인</SocialTitle>
          <KakaoLogin>
            <Kakao />
            카카오로 로그인
          </KakaoLogin>
          <AppleLogin>
            <Apple />
            Apple로 로그인
          </AppleLogin> */}
      </SocialContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-evenly;
  max-width: ${SIZE.accountWidth};
  min-height: 90vh;
  margin: 5vh auto;
  padding: 0 80px;
  border: 1px solid #e0e0e0;
  .logo {
    margin: 30px;
  }
`;
const LoginContainer = styled.div`
  width: 100%;
`;
const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
`;
const LoginInput = styled.input`
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  padding: 11px 0;
  margin-bottom: 18px;
  background-color: #fff;
`;
const LoginBtn = styled.button`
  background-color: ${COLORS.pink};
  border-radius: 4px;
  color: #fff;
  padding: 12px 0;
  width: 100%;
  margin: 12px auto;
  font-weight: 700;
`;
const RegisterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
const RegisterMenu = styled.div``;
const CenterLine = styled.div`
  height: 11px;
  border: 1px solid #e0e0e0;
  margin-top: 4px;
`;
const RegisterBtn = styled.button`
  font-size: 12px;
  color: #808080;
  background-color: #fff;
`;
const SocialContainer = styled.div`
  width: 100%;
`;
// const SocialTitle = styled.p`
//   font-size: 14px;
//   font-weight: 500;
// `;
// const KakaoLogin = styled.div`
//   background-color: #fae100;
//   color: #381e1f;
//   margin-top: 9px;
//   border-radius: 4px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 45px;
//   font-size: 14px;
//   svg {
//     margin-right: 4px;
//   }
// `;
// const AppleLogin = styled(KakaoLogin)`
//   background-color: #000;
//   color: #fff;
// `;

export default Login;
