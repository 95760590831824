import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { guide } from "datas/guide";
import { ReactComponent as Logo } from "assets/icons/Main/Logo.svg";
import { ReactComponent as GuideLogo } from "assets/icons/Main/Guide.svg";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";

const GuideList = () => {
  return (
    <List>
      {guide.map(({ id, title, subTitle, img }) => (
        <Item key={id}>
          <ContentTitle>
            <ContentNumber>{id}</ContentNumber>
            {title}
          </ContentTitle>
          <ContentSubTitle dangerouslySetInnerHTML={{ __html: subTitle }} />
          <ContentImgMain>
            <ContentImg src={img} />
          </ContentImgMain>
        </Item>
      ))}
    </List>
  );
};

const Guide = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <Main>
        <BtnBack onClick={() => navigate(-1)}>
          <Back />
        </BtnBack>
        <Header>
          <TextWrap>
            <Title>아이디얼 팜이 처음이신가요?</Title>
            <SubTitle>
              <Logo /> 이용 가이드
            </SubTitle>
          </TextWrap>
          <GuideLogo />
        </Header>
        <GuideList />
      </Main>
    </Layout>
  );
};

const Layout = styled.div`
  max-width: ${SIZE.largeWidth};
  margin: 0 auto;
  border: 1px solid #e0e0e0;
`;
const Main = styled.main`
  padding: 20px 18px 80px;
  background-color: #fff;
  border-radius: 8px;
  max-width: ${SIZE.mediumWidth};
  margin: 0 auto;
  max-width: 460px;
`;
const BtnBack = styled.button`
  border: none;
  background: none;
  margin-bottom: 30px;
  cursor: pointer;
`;
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TextWrap = styled.div``;
const Title = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;
const SubTitle = styled.p`
  font-size: 21px;
  font-weight: 700;
  display: flex;
  align-items: center;
  svg {
    width: 138px;
    height: 40px;
    margin-right: 7px;
  }
`;
const List = styled.div``;
const Item = styled.div`
  margin-top: 55px;
`;
const ContentTitle = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 6px;
`;
const ContentNumber = styled.div`
  width: 19px;
  height: 19px;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  margin-right: 7px;
`;
const ContentSubTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: ${COLORS.gray};
  margin-bottom: 23px;
  span {
    font-weight: 500;
    color: #000;
  }
`;
const ContentImgMain = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: inherit;
  margin: 0 auto;
`;
const ContentImg = styled.img``;

export default Guide;
