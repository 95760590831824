import { ReactNode, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

const Modal = ({
  activator,
  content,
}: {
  activator: (e: () => void) => ReactNode;
  content: (e: () => void) => ReactNode;
}) => {
  const [show, setShow] = useState(false);
  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);

  return (
    <>
      {activator(onOpen)}
      {createPortal(
        show && (
          <>
            <Backdrop onClick={onClose} />
            <Container>{content(onClose)}</Container>
          </>
        ),
        document.body
      )}
    </>
  );
};

const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
`;
const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 95vw;
  max-width: 450px;
`;

export default Modal;
