import { Link } from "react-router-dom";

import useInputs from "hooks/useInputs";
import { SetStateNumberType } from "types";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import {
  FindBtn,
  FindInput,
  FindContainer,
  FindForm,
  TitleContainer,
  Title,
  SubTitle,
  Container,
} from "components/atoms/account";

const FindPwInfo = ({ setPwPage }: { setPwPage: SetStateNumberType }) => {
  const [state, onChange] = useInputs({ email: "" });
  const { email } = state;

  return (
    <Container>
      <Link to="/login">
        <Back />
      </Link>
      <TitleContainer>
        <Title>비밀번호 찾기</Title>
        <SubTitle>
          가입시 사용한
          <br />
          이메일을 입력해주세요
        </SubTitle>
      </TitleContainer>
      <FindContainer>
        <FindForm>
          <FindInput
            placeholder="이메일"
            value={email}
            onChange={onChange}
            name="email"
          />
          <FindBtn disabled={!email} onClick={() => setPwPage(1)}>
            확인
          </FindBtn>
        </FindForm>
      </FindContainer>
    </Container>
  );
};

export default FindPwInfo;
