import styled from "styled-components";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";

import { getReceipt } from "api/invest";
import investState from "stores/invest";
import { convertToDateTime } from "utils/getDDay";
import deposit from "assets/images/Invest/deposit.jpg";
import { ReactComponent as DepositIcon } from "assets/icons/Product/DepositIcon.svg";
import COLORS from "components/atoms/colors";

const ModalHistory = ({ onClose }: { onClose: () => void }) => {
  const { data } = useRecoilValue(investState);
  const {
    typed_wallet,
    id,
    unit,
    name_en,
    account_number,
    account_holder,
    bank,
  } = data;
  const { data: receipt, isLoading } = useQuery(
    ["receipt", id],
    () => getReceipt(id),
    {
      enabled: typed_wallet === "입금완료",
    }
  );
  if (isLoading && !receipt) return null;
  const { deposit_day, deposit_amount, wallet_address } = receipt;
  const getDepositDay =
    typed_wallet === "입금완료" ? convertToDateTime(deposit_day) : null;

  return (
    <Container>
      <ProofContainer>
        <TitleContainer>
          <TitleWrap>
            <TitleDeposit>입금 내역</TitleDeposit>
            <SubTitle>이용해주셔서 감사합니다.</SubTitle>
          </TitleWrap>
          <DepositIcon />
        </TitleContainer>
        <DepositContainer>
          <DepositWrap>
            <DepositTitle>입금일</DepositTitle>
            <DepositContent>{`${getDepositDay?.date} (${getDepositDay?.time})`}</DepositContent>
          </DepositWrap>
          <DepositWrap>
            <DepositTitle>입금 수량</DepositTitle>
            <DepositContent>
              {`${deposit_amount.toLocaleString()} ${unit}`}
            </DepositContent>
          </DepositWrap>
        </DepositContainer>
        {name_en === "KRW" ? (
          <DepositContainer>
            <DepositInfo>입금 계좌 주소</DepositInfo>
            <DepositBox
              type="text"
              value={bank && `${bank}${account_number}(${account_holder})`}
              readOnly
            />
            <DepositInfo>이체증명서</DepositInfo>
            <CertificateContainer>
              {deposit && <CertificatePicture src={deposit} />}
            </CertificateContainer>
          </DepositContainer>
        ) : (
          <DepositContainer>
            <DepositInfo>입금 지갑 주소</DepositInfo>
            <DepositBox type="text" value={wallet_address} readOnly />
            <DepositInfo>입금 거래 ID</DepositInfo>
            <DepositBoxWhite type="text" value={data.verify_deposit} readOnly />
          </DepositContainer>
        )}
        <DepositBtn onClick={onClose}>확인</DepositBtn>
      </ProofContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;
const ProofContainer = styled.div`
  border-radius: 16px;
  padding: 34px 23px 23px 23px;
  background-color: #fff;
  position: relative;
  svg {
    position: absolute;
    right: 17px;
    top: 20px;
    cursor: pointer;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  padding-bottom: 18px;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  svg {
    position: static;
  }
`;
const TitleWrap = styled.div``;
const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 4px;
`;
const TitleDeposit = styled(Title)`
  font-size: 21px;
  line-height: 30px;
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray};
  span {
    color: ${COLORS.pink};
  }
`;
const DepositContainer = styled.div``;
const DepositWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;
const DepositTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray};
`;
const DepositContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
const DepositInfo = styled.p`
  margin: 20px auto 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray};
`;
const DepositBox = styled.input`
  background: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: ${COLORS.gray};
  margin: 5px auto 0;
  padding: 13px 15px;
  width: 100%;
`;
const CertificateContainer = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px auto 28px;
`;
const CertificatePicture = styled.img`
  border-radius: 4px;
  max-height: 150px;
  width: 100%;
`;
const DepositBoxWhite = styled(DepositBox)`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  color: #000;
`;
const DepositBtn = styled.button`
  background: ${COLORS.pink};
  border-radius: 4px;
  height: 45px;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 20px;
`;

export default ModalHistory;
