import styled from "styled-components";
import { Link } from "react-router-dom";

import { dateGap } from "utils/getDDay";
import { alarm } from "datas/alarm";
import { NoticeType } from "types/notice";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import { Container } from "components/atoms/account";

const Alarm = () => {
  return (
    <Main>
      <Link to="/my">
        <Back />
      </Link>
      <Top>알림</Top>
      <MenuList>
        {alarm.map(({ id, date, title, time }: NoticeType) => (
          <MenuTab key={id}>
            <TitleContainer>
              <Date>{`${date}  ${time}`}</Date>
              <TitleWrap>
                <Title>{title}</Title>
                <NewBtn gap={dateGap(date, "")}>New</NewBtn>
              </TitleWrap>
            </TitleContainer>
          </MenuTab>
        ))}
      </MenuList>
    </Main>
  );
};

const Main = styled(Container)`
  padding: 55px 0 0 0;
  border: 1px solid #e0e0e0;
  .back {
    left: 15px;
  }
`;
const Top = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 37px;
`;
const MenuList = styled.div`
  padding: 0 15px;
  border-bottom: 1px solid #ebebeb;
`;
const MenuTab = styled.div`
  border-bottom: 1px solid #ebebeb;
  padding: 13px 0;
  cursor: pointer;
  &:last-child {
    border: none;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const TitleWrap = styled.div`
  display: flex;
`;
const Title = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 2px 5px 0 5px;
`;
const Date = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #b3b3b3;
  margin: 0 5px;
`;
const NewBtn = styled.p<{ gap: number }>`
  min-width: 31px;
  height: 16px;
  background: #ff3061;
  border-radius: 2px;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  margin-top: 4px;
  text-align: center;
  display: ${({ gap }) => (gap < 7 ? "static" : "none")};
`;

export default Alarm;
