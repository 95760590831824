import styled from "styled-components";
import { useRecoilValue } from "recoil";

import { copyClipboard } from "utils";
import investState from "stores/invest";
import useInputs from "hooks/useInputs";
import { postDeposit } from "api/invest";
import { IF_WALLET_COIN } from "datas/wallets";
import { ReactComponent as CloseBtn } from "assets/images/Product/CloseBtn.svg";
import COLORS from "components/atoms/colors";

const DepositProof = ({ onClose }: { onClose: () => void }) => {
  const { data, refetch } = useRecoilValue(investState);
  const { id } = data;
  const [state, onChange] = useInputs({ verify_deposit: "" });
  const { verify_deposit } = state;
  const handleSubmit = async () => {
    if (!verify_deposit) return alert("거래 아이디를 입력 해 주세요.");
    const { success } = await postDeposit({ id, ...state });
    if (!success)
      return alert("저장 실패하였습니다. 거래 아이디를 다시 확인해주세요.");
    alert("저장되었습니다.");
    onClose();
    refetch();
  };
  const handleCopy = () => copyClipboard(IF_WALLET_COIN);

  return (
    <Container>
      <CloseBtn onClick={onClose} />
      <Title>입금 증명이 필요합니다</Title>
      <SubTitle>
        입금하신 <span>거래ID</span>를 입력해주세요.
      </SubTitle>
      <AdviceBox>
        <AdviceTitle>
          업비트 &gt; 입출금 &gt; 비트코인 선택 &gt; 입출금 내역
        </AdviceTitle>
        <AdviceContent>에서 거래 ID를 확인하실 수 있습니다.</AdviceContent>
      </AdviceBox>
      <ProofTitle>거래 ID 입력</ProofTitle>
      <ProofBox
        rows={4}
        name="verify_deposit"
        value={verify_deposit}
        onChange={onChange}
      />
      <ProofSubTitle>아직 입금 전이라면 아래 주소로 입금해주세요</ProofSubTitle>
      <ProofContent>Ideal Farm의 업비트 지갑주소</ProofContent>
      <WalletContainer>
        <ProofWallet type="text" value={IF_WALLET_COIN} readOnly />
        <WalletCopyBtn onClick={handleCopy}>복사</WalletCopyBtn>
      </WalletContainer>
      <SaveBtn onClick={handleSubmit}>저장하기</SaveBtn>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
  border-radius: 16px;
  padding: 34px 23px 23px 23px;
  background-color: #fff;
  position: relative;
  svg {
    position: absolute;
    right: 17px;
    top: 20px;
    cursor: pointer;
  }
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 4px;
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray};
  span {
    color: ${COLORS.pink};
  }
`;
const AdviceBox = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  margin: 13px 0 18px 0;
  padding: 13px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const AdviceTitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
`;
const AdviceContent = styled(AdviceTitle)`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.gray};
`;
const ProofTitle = styled(AdviceTitle)`
  color: ${COLORS.gray};
  margin-bottom: 6px;
`;
const ProofBox = styled.textarea`
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  resize: none;
  padding: 12px 14px;
  margin-bottom: 42px;
`;
const ProofSubTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 13px;
`;
const ProofContent = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 5px;
  color: #808080;
`;
const WalletContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  margin-bottom: 26px;
`;
const ProofWallet = styled.input`
  padding: 11px 55px 11px 10px;
  position: absolute;
  width: 100%;
  background: #f7f7f7;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
`;
const WalletCopyBtn = styled.button`
  position: absolute;
  background: #ff3061;
  border-radius: 4px;
  padding: 6px 11px;
  color: #fff;
  font-size: 12px;
  right: 6px;
`;
const SaveBtn = styled.button`
  background: ${COLORS.pink};
  border-radius: 4px;
  height: 45px;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 18px;
`;

export default DepositProof;
