import styled from "styled-components";
import Moment from "react-moment";

import COLORS from "components/atoms/colors";

const InfoNFT = ({
  date,
  address,
  isOwner,
  walletInfo,
}: {
  date: string;
  address: string;
  isOwner: boolean;
  walletInfo: string;
}) => {
  const newAddress =
    address.substring(0, 5) + "..." + address.substring(address.length - 5);

  const nftStatus = () => {
    if (!walletInfo) return <span>NFT에 접속해주세요.</span>;
    if (isOwner) return <span>보유 중</span>;
    return "판매 완료";
  };

  return (
    <Layout>
      <Container>
        <Title>NFT 신청 정보</Title>
        <Item>
          <ItemContent>컨트랙트 주소</ItemContent>
          <ItemContent isBlue={true}>{newAddress || "-"}</ItemContent>
        </Item>
        <Item>
          <ItemContent>획득 일자</ItemContent>
          <ItemContent>
            <Moment local format="YYYY.MM.DD (HH:mm)">
              {date}
            </Moment>
          </ItemContent>
        </Item>
        <Item>
          <ItemContent>상태</ItemContent>
          <ItemContent>{nftStatus()}</ItemContent>
        </Item>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  border-radius: 12px;
  border: 2px solid transparent;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #0485ff -32.14%, #7d5cf8 53.24%, #ec033f 150%);
  background-origin: border-box;
  background-clip: content-box, border-box;
`;
const Container = styled.div`
  padding: 18px;
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 14px;
`;
const Item = styled.div`
  display: flex;
  justify-content: space-between;
  & + & {
    margin-top: 5px;
  }
`;
const ItemContent = styled.div<{ isBlue?: boolean }>`
  font-size: 12px;
  color: ${({ isBlue }: { isBlue?: boolean }) =>
    isBlue ? "#0085ff" : "#808080"};
  max-width: ${({ isBlue }: { isBlue?: boolean }) =>
    isBlue ? "100px" : "100%"};
  text-decoration-line: ${({ isBlue }: { isBlue?: boolean }) =>
    isBlue ? "underline" : "none"};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  span {
    font-weight: 500;
    color: ${COLORS.pink};
  }
`;

export default InfoNFT;
