import styled from "styled-components";
import { useEffect, useState } from "react";

import { postRecommendCode } from "api/invest";
import { ProductsType } from "types/products";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";
import { ContentTitle } from "components/atoms/main";
import Calculator from "components/templates/Product/Contract/Calculator";

const Index = ({
  data,
  onChange,
  state,
}: {
  data: ProductsType;
  onChange: any;
  state: { amount: number; recommend_code: string };
}) => {
  const [amountText, setAmountText] = useState("");
  const { unit, max_amount, min_amount, amount_restrict } = data;
  const { amount, recommend_code } = state;
  const [recommendText, setRecommendText] = useState("");
  const [isRecommend, setIsRecommend] = useState(false);
  useEffect(() => {
    let text = "";
    const isMinAmount = amount < min_amount;
    const isAmountRestricted = !Number.isInteger(amount / amount_restrict);
    if (isMinAmount) {
      text = "최소 수량 이상 신청해 주세요.";
    } else if (isAmountRestricted) {
      text = `${amount_restrict.toLocaleString()}${unit} 단위로 입력해 주세요.`;
    }
    setAmountText(text);
  }, [amount]);

  const handleSubmit = async () => {
    const { success } = await postRecommendCode(recommendText);
    if (success) {
      setIsRecommend(true);
      alert("추천인 인증에 성공하였습니다.");
      onChange({ name: "recommend_code", value: recommendText });
    } else {
      alert("추천인 인증에 실패하였습니다. 추천인 정보를 확인해주세요.");
    }
  };

  return (
    <Layout>
      <Container>
        <ContentTitle>상품 수량 및 예치 기간</ContentTitle>
        <ItemContainer>
          <NameWrap>
            <ItemWrap>
              <ItemName>예치 금액</ItemName>
              <ItemText isCheck>{amountText}</ItemText>
            </ItemWrap>
            <InputContainer isBlock={false}>
              <ItemInput
                name="amount"
                value={amount === 0 ? "" : amount}
                onChange={onChange}
                placeholder={`최소 수량 ${min_amount.toLocaleString()} ${unit}`}
                type="number"
                min={min_amount}
                max={max_amount}
                step={amount_restrict}
              />
              <InputTag>{unit}</InputTag>
            </InputContainer>
          </NameWrap>
          <NameWrap>
            <ItemWrap>
              <ItemName>추천인</ItemName>
              <ItemText isCheck={isRecommend}>
                {isRecommend ? "* 유효한 코드입니다" : "* 선택사항"}
              </ItemText>
            </ItemWrap>
            <InputContainer isBlock={isRecommend}>
              <ItemInput
                placeholder="추천인 코드를 입력해주세요."
                name="recommend_code"
                onChange={(e) => {
                  setRecommendText(e.target.value);
                  onChange(e);
                }}
                disabled={isRecommend}
              />
              {isRecommend ? (
                <InputButton
                  onClick={handleSubmit}
                  isActive={!isRecommend}
                  disabled={isRecommend}
                >
                  완료
                </InputButton>
              ) : (
                <InputButton
                  onClick={handleSubmit}
                  isActive={!isRecommend}
                  disabled={recommendText.length === 0}
                >
                  확인
                </InputButton>
              )}
            </InputContainer>
          </NameWrap>
          <ItemName>예상 환급 금액</ItemName>
          <Calculator
            data={data}
            targetAmount={amount}
            isRecommend={isRecommend}
          />
        </ItemContainer>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  padding: 16px 0 0 0;
`;
const Container = styled.div`
  max-width: ${SIZE.largeWidth};
  margin: 0 auto;
  background: #f5f5f5;
`;
const ItemContainer = styled.div`
  margin: 8px auto 0 auto;
  padding: 18px 20px 18px;
  border: 1px solid ${COLORS.lightestGray};
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #fff;
  max-width: ${SIZE.mediumWidth};
`;
const NameWrap = styled.div``;
const ItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ItemName = styled.p`
  font-size: 12px;
  font-weight: 400px;
  line-height: 17px;
  color: ${COLORS.gray};
`;
const ItemText = styled.div<{ isCheck: boolean }>`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: right;
  color: ${({ isCheck }) => (isCheck ? COLORS.pink : COLORS.gray)};
`;
const InputContainer = styled.div<{ isBlock: boolean }>`
  padding: 13px 12px;
  position: relative;
  width: 100%;
  height: 45px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 8px auto 20px auto;
  display: flex;
  align-items: center;
  background-color: ${({ isBlock }) => (isBlock ? "#efefef" : "fff")};
`;
const ItemInput = styled.input`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 90%;
`;
const InputTag = styled.p`
  position: absolute;
  right: 13px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;
const InputButton = styled.button<{ isActive: boolean }>`
  position: absolute;
  right: 7px;
  background: ${({ isActive }) => (isActive ? "#333" : "#f2f2f2)")};
  border: ${({ isActive }) =>
    isActive ? "1px solid #333" : " 1px solid #e0e0e0"};
  border-radius: 4px;
  padding: 6px 11px 7px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${({ isActive }) => (isActive ? "#fff" : COLORS.gray)};
`;

export default Index;
