import Routers from "Router/index";
import { QueryClient, QueryClientProvider } from "react-query";

import useTokenCheck from "hooks/useTokenCheck";

function App() {
  const queryClient = new QueryClient();
  const isTokenChecked = useTokenCheck();
  if (!isTokenChecked) return null;

  return (
    <QueryClientProvider client={queryClient}>
      <Routers />
    </QueryClientProvider>
  );
}

export default App;
