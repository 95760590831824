import { useRef } from "react";

import LandingNav from "components/organisms/LandingNav";
import LandingFooter from "components/organisms/LandingFooter";
import Intro from "components/templates/Landing/Intro";
import Profit from "components/templates/Landing/Profit";
import Product from "components/templates/Landing/Product";
import Explain from "components/templates/Landing/Explain";
import CenterContact from "components/templates/Landing/CenterContact";
import ModalLanding from "components/templates/Landing/ModalLanding";

const Landing = () => {
  const introRef = useRef<HTMLDivElement>(null);
  const productRef = useRef<HTMLDivElement>(null);
  const yieldRef = useRef<HTMLDivElement>(null);
  const menuList = [
    { ref: introRef, name: "서비스 소개" },
    { ref: productRef, name: "예치 상품" },
    { ref: yieldRef, name: "수익률 계산기" },
  ];

  return (
    <>
      {/* <ModalLanding /> */}
      <LandingNav menuList={menuList} />
      <Intro propsRef={menuList[0].ref} />
      <Product propsRef={menuList[1].ref} />
      <CenterContact />
      <Profit propsRef={menuList[2].ref} />
      <Explain />
      <LandingFooter />
    </>
  );
};

export default Landing;
