import styled from "styled-components";
import { PieChart } from "react-minimal-pie-chart";

import { GraphType } from "types/invest";

const Chart = ({ data }: { data: GraphType[] }) => {
  return (
    <SVG viewBox="0 0 200 200">
      <PieChart
        data={data.map(({ name_en, coin_amount, hex_color }: GraphType) => ({
          title: name_en,
          value: coin_amount,
          color: hex_color,
        }))}
        lineWidth={60}
        startAngle={270}
      />
    </SVG>
  );
};

const SVG = styled.svg`
  position: static !important;
`;

export default Chart;
