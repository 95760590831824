import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { myinfo } from "datas/info";
import { deleteUser } from "api/user";
import { MyInfoType } from "types/account";
import { ReactComponent as Kakao } from "assets/images/My/kakao.svg";
import COLORS from "components/atoms/colors";

const Menu = () => {
  const navigate = useNavigate();
  const handleLogOut = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      localStorage.removeItem("userToken");
      window.location.href = "/";
    }
  };
  const handleDeleteUser = async () => {
    if (window.confirm("정말로 탈퇴하시겠습니까?")) {
      const result = await deleteUser();
      if (result) {
        alert("탈퇴가 완료되었습니다.");
        localStorage.removeItem("userToken");
        window.location.reload();
      }
    }
  };

  return (
    <>
      <Container>
        <MenuList>
          <KakaoWrap>
            <Kakao
              onClick={() => window.open("https://pf.kakao.com/_NExjtb")}
            />
          </KakaoWrap>
          {myinfo.map(
            ({
              id,
              image,
              text,
              last,
              isPink,
              link,
              logOut,
              onClickLink,
              alertString,
              subText,
            }: MyInfoType) => (
              <MenuTab
                key={id}
                onClick={() => {
                  alertString && alert(alertString);
                  logOut && handleLogOut();
                  onClickLink && window.open(onClickLink);
                  link && navigate("/my" + link);
                }}
              >
                <MenuImg>{image}</MenuImg>
                <MenuText isPink={isPink}>{text}</MenuText>
                <MenuImg>
                  <span>{subText}</span>
                  {last}
                </MenuImg>
              </MenuTab>
            )
          )}
        </MenuList>
      </Container>
      <Copyright>Copyrightⓒ2022 By Idealfarm. All right reserved.</Copyright>
      <Withdrawal>회원탈퇴</Withdrawal>
    </>
  );
};

const Container = styled.div`
  background-color: #fff;
  margin-bottom: 10px;
`;
const MenuList = styled.div`
  padding: 0 15px 15px;
  border-bottom: 1px solid #e0e0e0;
`;
const MenuTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 15px 10px;
  cursor: pointer;
`;
const KakaoWrap = styled(MenuTab)`
  svg {
    width: 100%;
  }
`;
const MenuImg = styled.div`
  font-size: 12px;
  span {
    color: #ff3061;
    margin-right: 9px;
  }
`;
const MenuText = styled.p<{ isPink: boolean | undefined }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  flex: 1;
  margin-left: 15px;
  color: ${({ isPink }) => (isPink ? COLORS.pink : "#000")};
`;
const Copyright = styled.div`
  margin: 19px auto 0;
  width: 250px;
  font-size: 10px;
  color: #b3b3b3;
  text-align: center;
`;
const Withdrawal = styled(Copyright)`
  margin-top: 10px;
  text-decoration-line: underline;
  cursor: pointer;
`;

export default Menu;
