import styled from "styled-components";

import { AllInvestType } from "types/invest";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";
import Item from "components/templates/Main/Invests/Item";

const Invests = ({ data }: { data: AllInvestType[] }) => {
  return (
    <Container>
      <TitleWrap>
        <Title>내 투자 상품</Title>
        <SortOption>
          <option value="예치 금액 순">예치 금액 순</option>
          <option value="예치 금액 역순">예치 금액 역순</option>
        </SortOption>
      </TitleWrap>
      {data.length !== 0 ? (
        data.map((p: AllInvestType, i: number) => <Item key={i} data={p} />)
      ) : (
        <NoItemBox>투자 중인 상품이 없습니다.</NoItemBox>
      )}
    </Container>
  );
};

const Container = styled.div`
  max-width: ${SIZE.mediumWidth};
  margin: 0 auto;
  padding: 0 15px 191px;
`;
const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
const Title = styled.p`
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
`;
const SortOption = styled.select`
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.gray};
`;
const NoItemBox = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #b3b3b3;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Invests;
