import styled from "styled-components";
import { Link } from "react-router-dom";

import { coinData } from "datas/landing";
import { ProductsType } from "types/products";
import { convertToDateNoTime } from "utils/getDDay";
import COLORS from "components/atoms/colors";
import { SubContent, SubContentBold } from "components/atoms/landing";

const ProductItem = ({
  data,
  active,
}: {
  data: ProductsType;
  active: boolean;
}) => {
  const {
    name,
    status,
    name_en,
    max_amount,
    apy,
    recommend_bonus_apy,
    duration_month,
    fund_end_at,
    id,
    coin_id,
    code,
  } = data;

  return (
    <ProductInfo active={active}>
      <TitleContainer>
        <TitleWrap>
          <Title status={status}>{name}</Title>
          <SubContentBold>{code}</SubContentBold>
        </TitleWrap>
        {coinData.find((i) => i.id === coin_id)?.img}
      </TitleContainer>
      <CenterLine />
      <RateContainer>
        <SubContent>예상 연 이율</SubContent>
        <RateContent>
          최소 {apy}% ~ 최대 {apy + recommend_bonus_apy}%
        </RateContent>
      </RateContainer>
      <OptionContainer>
        <OptionItemWrap>
          <OptionItem first={true}>
            <OptionContentGray2>총 모집 수량</OptionContentGray2>
            <OptionContent2>
              {max_amount.toLocaleString()}&nbsp;
              {name_en}
            </OptionContent2>
          </OptionItem>
        </OptionItemWrap>
        <OptionLine hideOnMobile={true} />
        <OptionItemWrap>
          <OptionItem first={false}>
            <OptionContentGray>예치 기간</OptionContentGray>
            <OptionContent>{duration_month}개월</OptionContent>
          </OptionItem>
          <OptionLine hideOnMobile={false} />
          <OptionItem first={false}>
            <OptionContentGray>신청 기간</OptionContentGray>
            <OptionContent>~ {convertToDateNoTime(fund_end_at)}</OptionContent>
          </OptionItem>
        </OptionItemWrap>
      </OptionContainer>
      <BtnWrap>
        {status === "모집중" ? (
          <Link to={`/staking/${id}`}>
            <BtnApply>자세히 보기</BtnApply>
          </Link>
        ) : status === "예정" ? (
          <BtnSoldOut>준비중인 상품입니다.</BtnSoldOut>
        ) : (
          <BtnSoldOut>마감된 상품입니다.</BtnSoldOut>
        )}
      </BtnWrap>
    </ProductInfo>
  );
};

const ProductInfo = styled.div<{ active: boolean }>`
  flex-shrink: 0;
  width: 430px;
  background-color: #fff;
  border-radius: 20px;
  padding: 26px 25px 25px;
  box-shadow: 1px 2px 40px 5px rgba(168, 0, 40, 0.1);
  z-index: 1;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  margin: 0 10px;
  :hover {
    opacity: 1;
  }
  @media only screen and (max-width: 1200px) {
    width: 370px;
    padding: 26px 25px 29px;
  }
  @media only screen and (max-width: 767px) {
    width: 260px;
    padding: 18.5px 20px 15.5px;
    .krw {
      width: 40px;
      height: 40px;
    }
  }
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TitleWrap = styled.div``;
const Title = styled.p<{ status: string }>`
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
  display: inline-block;
  @media only screen and (max-width: 767px) {
    font-size: 17px;
    line-height: 25px;
  }
  ::after {
    display: ${({ status }) => (status === "모집중" ? "none" : "flex")};
    position: absolute;
    right: -42px;
    top: 7px;
    content: "${({ status }) => status}";
    background: #000;
    color: #fff;
    border-radius: 20px;
    width: 35px;
    height: 18px;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    align-items: center;
    justify-content: center;
  }
`;
const RateContainer = styled.div``;
const RateContent = styled.p`
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  color: ${COLORS.pink};
  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 23px;
  }
`;
const CenterLine = styled.div`
  border-top: 1px solid ${COLORS.lightestGray};
  margin: 17px 0 23px 0;
  @media only screen and (max-width: 767px) {
    margin: 14px auto 14px;
  }
`;
const OptionContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2px;
  background: #f5f5f5;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: white;
  /* display: flex;
  margin-top: 30px;
  background: #f5f5f5;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 1200px) {
    margin-top: 30px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 10px;
    flex-direction: column;
    background: #fff;
  } */
`;
const OptionLine = styled.span<{ hideOnMobile: boolean }>`
  height: 30px;
  width: 0;
  border: 1px solid ${COLORS.lightestGray};
  display: ${({ hideOnMobile }) => hideOnMobile && "none"};
`;
const OptionItemWrap = styled.div`
  background: #f5f5f5;
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
  &:first-child {
    padding: 0;
    background: white;
  }
  /* background: #f5f5f5;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 767px) {
    padding: 8px;
    &:first-child {
      width: 100%;
      padding: 0;
      background: #fff;
      margin-bottom: 10px;
    }
  } */
`;
// const OptionItem = styled.div`
//   width: 120px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   padding: 12px 16px 16px;
//   @media only screen and (max-width: 1200px) {
//     width: 106px;
//     padding: 12px 0 16px;
//   }
//   @media only screen and (max-width: 767px) {
//     width: 105px;
//     padding: 0;
//   }
// `;
const OptionItem = styled.div<{ first: boolean }>`
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: ${({ first }) => (first ? "flex-start" : "center")};
  justify-content: center;
  padding: 8px 16px 8px 0;
  margin-bottom: ${({ first }) => (first ? "5px" : "0")};
  flex: 1;
  @media only screen and (max-width: 767px) {
    width: 105px;
    padding: ${({ first }) => (first ? "10px 0" : "0")};
  }
`;
const OptionContent = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
`;
const OptionContent2 = styled(OptionContent)`
  @media only screen and (max-width: 767px) {
    width: 100%;
    text-align: left;
  }
`;
const OptionContentGray = styled(OptionContent)`
  color: ${COLORS.lightGray};
  @media only screen and (max-width: 767px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
  }
`;
const OptionContentGray2 = styled(OptionContentGray)`
  @media only screen and (max-width: 767px) {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
`;
const BtnWrap = styled.div`
  display: flex;
  margin-top: 29px;
  a {
    flex: 1;
  }
  a[disabled] {
    pointer-events: none;
  }
  @media only screen and (max-width: 1200px) {
    margin-top: 26px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 12px;
  }
`;
const BtnApply = styled.button`
  background: ${COLORS.pink};
  color: #fff;
  font-weight: 700;
  font-size: 17px;
  line-height: 50px;
  border-radius: 40px;
  width: 100%;
  height: 50px;
  text-align: center;
  @media only screen and (max-width: 767px) {
    height: 40px;
    line-height: 20px;
    font-size: 14px;
  }
`;
const BtnSoldOut = styled(BtnApply)`
  background: ${COLORS.lightGray};
  cursor: default;
`;

export default ProductItem;
