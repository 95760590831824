import { getMyInfo } from "api/user";
import { useQuery } from "react-query";
import styled from "styled-components";

import { GraphType } from "types/invest";
import { ReactComponent as Bg } from "assets/images/Main/MainBG.svg";
import { ReactComponent as BgStar } from "assets/images/Main/MobileBGStar.svg";
import { ReactComponent as BgArrow } from "assets/images/Main/MobileBGArrow.svg";
import { ReactComponent as InvestNoItem } from "assets/images/Main/InvestNoItem.svg";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";
import Chart from "components/templates/Main/Chart";

const InvestStatus = ({ data }: { data: GraphType[] }) => {
  const { data: user, isLoading } = useQuery("info", getMyInfo);
  const total = data.reduce((a: number, b: GraphType) => a + b.coin_amount, 0);
  if (isLoading) return null;
  const { name } = user;

  return (
    <Layout>
      <Container>
        <Bg />
        <BgArrow />
        <BgStar />
        <Title>
          <span>{name}</span> 님의 투자현황
        </Title>
        <GraphContainer>
          <SubTitle>
            총 <span>{data.length}건</span>의 투자 상품이 있습니다.
          </SubTitle>
          {data.length > 0 ? (
            <StatusContainer>
              <StatusText>
                <StatusTitle>내 보유 자산</StatusTitle>
                <StatusAmount>{total.toLocaleString()}</StatusAmount>
                <StatusList>
                  {data.map(
                    ({ coin_amount, name_en, name_kr, hex_color }, i) => (
                      <StatusItem key={i}>
                        <ItemTitle>
                          <Rectangle color={hex_color} />
                          {name_kr}({name_en})
                        </ItemTitle>
                        <span>{Math.round((coin_amount / total) * 100)}%</span>
                      </StatusItem>
                    )
                  )}
                </StatusList>
              </StatusText>
              <StatusGraph>
                <Chart data={data} />
              </StatusGraph>
            </StatusContainer>
          ) : (
            <StatusContainer>
              <StatusTextNoItem>
                <InvestNoItem />
                <StatusTitleNoItem>투자 중인 상품이 없어요</StatusTitleNoItem>
              </StatusTextNoItem>
            </StatusContainer>
          )}
        </GraphContainer>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  padding: 26px 0 29px;
  background-color: ${COLORS.lightPink};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
`;
const Container = styled.div`
  margin: 0 auto;
  z-index: 1;
  width: 100%;
  max-width: ${SIZE.smallWidth};
  padding: 0 20px;
  svg {
    position: absolute;
    width: 100%;
    z-index: -1;
    bottom: 10px;
    left: 0;
  }
  .mobileBgArrow {
    left: 120px;
    top: 10px;
  }
  .mobileBgStar {
    left: 70px;
    top: 35px;
  }
`;
const Title = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  span {
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
  }
`;
const GraphContainer = styled.div`
  margin-top: 9px;
  background-color: #fff;
  padding: 15px 19px 21px 16px;
  border-radius: 8px;
`;
const SubTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  span {
    color: ${COLORS.pink};
  }
  padding-bottom: 14px;
  margin-bottom: 11px;
  border-bottom: 1px solid ${COLORS.lightestGray};
`;
const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StatusText = styled.div`
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const StatusTextNoItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 106px;
  position: relative;
  z-index: 1;
  .InvestNoItem {
    z-index: 0;
    left: -70px;
    top: 20px;
  }
`;
const StatusTitle = styled.p`
  color: ${COLORS.gray};
  font-size: 12px;
  line-height: 17px;
`;
const StatusTitleNoItem = styled(StatusTitle)`
  color: #b3b3b3;
  z-index: 1;
`;
const StatusAmount = styled.p`
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
`;
const StatusList = styled.ul`
  margin: 14px 0 0 0;
  padding: 0;
`;
const StatusItem = styled.li`
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: ${COLORS.gray};
  display: flex;
  justify-content: space-between;
  max-width: 142px;
`;
const ItemTitle = styled.div`
  display: flex;
  align-items: center;
`;
const StatusGraph = styled.div`
  width: 106px;
  height: 106px;
  border-radius: 50%;
  overflow: hidden;
`;
const Rectangle = styled.div<{ color: string }>`
  width: 6px;
  height: 6px;
  background-color: ${({ color }) => color};
  margin: 2px 6px 0 0;
`;

export default InvestStatus;
