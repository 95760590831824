import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import { Web3ReactProvider } from "@web3-react/core";

import "index.css";
import App from "App";
import reportWebVitals from "reportWebVitals";

import {
  ExternalProvider,
  JsonRpcFetchFunc,
  Web3Provider,
} from "@ethersproject/providers";
const getLibrary = (provider: ExternalProvider | JsonRpcFetchFunc) =>
  new Web3Provider(provider);

ReactDOM.render(
  <RecoilRoot>
    <React.StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
      </Web3ReactProvider>
    </React.StrictMode>
  </RecoilRoot>,
  document.getElementById("root")
);

reportWebVitals();
