import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as Ok } from "assets/icons/Main/ok.svg";
import { ReactComponent as Line } from "assets/icons/Main/topLine.svg";
import COLORS from "components/atoms/colors";

const ModalApply = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    onClose();
    navigate(-1);
  };
  return (
    <Container>
      <Line />
      <Ok />
      <Title>NFT 신청 완료</Title>
      <SubTitle>
        발급 완료 시 알림을 보내드립니다.
        <br />
        <span>(최대 3일 소요)</span>
      </SubTitle>
      <Btn onClick={handleClose}>확인</Btn>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  border-radius: 16px 16px 0 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  svg {
    margin: 15px auto;
    width: 45px;
  }
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 7px;
`;
const SubTitle = styled.p`
  font-size: 14px;
  text-align: center;
  span {
    color: ${COLORS.gray};
    font-size: 12px;
  }
`;
const Btn = styled.button`
  border-top: 1px solid ${COLORS.lightestGray};
  font-size: 14px;
  color: ${COLORS.gray};
  background-color: #fff;
  padding: 15px 0 30px;
  width: 100%;
  margin-top: 26px;
`;

export default ModalApply;
