import { ReactComponent as KrwActive } from "assets/icons/Product/krw-active.svg";
import { ReactComponent as KrwInactive } from "assets/icons/Product/krw-inactive.svg";
import { ReactComponent as BtcActive } from "assets/icons/Coins/btc-active.svg";
import { ReactComponent as EthActive } from "assets/icons/Coins/eth-active.svg";
import { ReactComponent as XrpActive } from "assets/icons/Coins/xrp-active.svg";
import { ReactComponent as BtcInactive } from "assets/icons/Coins/btc-inactive.svg";
import { ReactComponent as EthInactive } from "assets/icons/Coins/eth-inactive.svg";
import { ReactComponent as XrpInactive } from "assets/icons/Coins/xrp-inactive.svg";

export const coins = [
  {
    id: 1,
    name: "원화",
    coins_id: 1,
    images: {
      active: <KrwActive />,
      inActive: <KrwInactive />,
    },
    unit: "KRW",
    profit: {
      rate: 1.04,
      month: 3,
    },
    maxAmount: 20000000,
  },
  {
    id: 2,
    name: "비트코인",
    coins_id: 2,
    images: {
      active: <BtcActive />,
      inActive: <BtcInactive />,
    },
    unit: "BTC",
    profit: {
      rate: 1.04,
      month: 3,
    },
    maxAmount: 1000,
  },
  {
    id: 3,
    name: "이더리움",
    coins_id: 3,
    images: {
      active: <EthActive />,
      inActive: <EthInactive />,
    },
    unit: "ETH",
    profit: {
      rate: 1.04,
      month: 3,
    },
    maxAmount: 1000,
  },
  {
    id: 4,
    name: "리플",
    coins_id: 4,
    images: {
      active: <XrpActive />,
      inActive: <XrpInactive />,
    },
    unit: "XRP",
    profit: {
      rate: 1.04,
      month: 3,
    },
    maxAmount: 1000,
  },
];
