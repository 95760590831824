export const alarm = [
  {
    id: 1,
    date: "2022-02-21",
    title: "테스트 공지사항입니다.",
    time: "11:10",
  },
  {
    id: 2,
    date: "2022-03-15",
    title: "테스트 공지사항입니다. 두번째 공지입니다.",
    time: "11:10",
  },
  {
    id: 3,
    date: "2022-03-21",
    title: "테스트 공지사항입니다. 세번째 테스트입니다.",
    time: "11:10",
  },
  {
    id: 4,
    date: "2022-04-20",
    title: "테스트 공지사항입니다. 반갑습니다",
    time: "11:10",
  },
  {
    id: 5,
    date: "2022-04-21",
    title: "테스트 공지사항입니다.",
    time: "11:10",
  },
  {
    id: 6,
    date: "2022-05-16",
    title: "테스트 공지사항입니다.",
    time: "11:10",
  },
  {
    id: 7,
    date: "2022-05-19",
    title:
      "안녕하세요. 반갑습니다. 공지사항입니다.안녕하세요. 반갑습니다. 공지사항입니다.안녕하세요. 반갑습니다. 공지사항입니다.",
    time: "11:10",
  },
  {
    id: 8,
    date: "2022-05-20",
    title:
      "안녕하세요. 반갑습니다. 공지사항입니다.안녕하세요. 반갑습니다. 공지사항입니다.안녕하세요. 반갑습니다. 공지사항입니다.",
    time: "11:10",
  },
];
