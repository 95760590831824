import styled from "styled-components";
import { useQuery } from "react-query";
import { useState, useRef, TouchEvent } from "react";

import { getProducts } from "api/product";
import { ProductsType } from "types/products";
import Item from "components/templates/Landing/Product/Item";

const List = () => {
  const { data, isLoading } = useQuery("products", getProducts);
  const [activeIndex, setActiveIndex] = useState(0);
  const touchStartPoint = useRef(0);
  const handleTouchStart = (e: TouchEvent) => {
    const { screenX } = e.changedTouches[0];
    touchStartPoint.current = screenX;
  };
  const handleTouchEnd = (e: TouchEvent) => {
    const { screenX } = e.changedTouches[0];
    // 감도 설정
    if (Math.abs(touchStartPoint.current - screenX) < 50) return;
    const toRight = touchStartPoint.current > screenX;
    if (toRight && activeIndex < data.length - 1) {
      setActiveIndex((prev: number) => prev + 1);
    } else if (!toRight && activeIndex > 0) {
      setActiveIndex((prev: number) => prev - 1);
    }
  };

  if (isLoading) return null;

  return (
    <ItemList>
      <ProductItem
        activeIndex={activeIndex}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {data.map((p: ProductsType, i: number) => (
          <ItemWrapper
            onClick={() => setActiveIndex(i)}
            active={activeIndex === i}
            key={p.id}
          >
            <Item data={p} active={activeIndex === i} />
          </ItemWrapper>
        ))}
      </ProductItem>
    </ItemList>
  );
};

const ItemList = styled.div`
  overflow-x: hidden;
  max-width: 900px;
  margin: 0 auto;
`;
const ItemWrapper = styled.div<{ active: boolean }>`
  cursor: ${({ active }) => !active && "pointer"};
`;
const ProductItem = styled.div<{ activeIndex: number }>`
  margin: 30px auto 130px;
  display: flex;
  align-items: center;
  height: 477px;
  width: 450px;
  transform: ${({ activeIndex }) => `translateX(${-450 * activeIndex}px)`};
  transition: 1s;
  @media only screen and (max-width: 1200px) {
    height: 477px;
    width: 390px;
    transform: ${({ activeIndex }) => `translateX(${-390 * activeIndex}px)`};
  }
  @media only screen and (max-width: 767px) {
    height: 308px;
    width: 280px;
    margin-top: 40px;
    transform: ${({ activeIndex }) => `translateX(${-280 * activeIndex}px)`};
  }
`;

export default List;
