const pink = "#FF3061";
const lightPink = "#FFF4F7";
const gray = "#808080";
const lightGray = "#b3b3b3";
const lighterGray = "#cccccc";
const lightestGray = "#e0e0e0";
const darkGray = "#666666";
const darkerGray = "#343434";

const colors = {
  pink,
  lightGray,
  darkGray,
  darkerGray,
  gray,
  lightPink,
  lighterGray,
  lightestGray,
};
export default colors;
