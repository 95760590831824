import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import { postWallets } from "api/user";
import {
  Container,
  FindBtn,
  FindInput,
  FindContainer,
} from "components/atoms/account";
import useInputs from "hooks/useInputs";

const AddWallet = () => {
  // const navigate = useNavigate();
  const [state, onChange] = useInputs({ coin_id: "0", address: "" });
  const { coin_id, address } = state;
  console.log(state);
  const handleSubmit = async () => {
    const newData = {
      coin_id: parseInt(state.coin_id),
      address: address,
    };
    const result = await postWallets(newData);
    // if (result?) {
    //   navigate(`/wallets`);
    //   alert("지갑이 추가되었습니다.");
    // } else {
    //   alert("지갑추가에 실패하였습니다. 정보를 확인해주세요.");
    // }
  };
  return (
    <>
      <Container>
        <Link to="/my/wallets">
          <Back />
        </Link>
        <TitleContainer>
          <Title>내 업비트 지갑 주소 목록</Title>
        </TitleContainer>
        <FindContainer>
          <ContentTitle>종목</ContentTitle>
          <SelectWrap>
            <ContentSelect
              placeholder="신규 비밀번호 (특수문자를 포함한 6 자리 이상)"
              value={coin_id}
              onChange={(e) => {
                onChange(e);
              }}
              name="coin_id"
            >
              <option value={0}>비트코인(BTC)</option>
              <option value={1}>이더리움(ETH)</option>
              <option value={2}>리플(XRP)</option>
              <option value={3}>원화(KRW)</option>
            </ContentSelect>
          </SelectWrap>
          <ContentTitle>주소 입력</ContentTitle>
          <ContentInput
            placeholder="주소를 입력해주세요"
            value={address}
            onChange={onChange}
            type="text"
            name="address"
          />
          <Link to="/my">
            <FindBtn disabled={address.length <= 10} onClick={handleSubmit}>
              다음
            </FindBtn>
          </Link>
        </FindContainer>
      </Container>
    </>
  );
};

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 24px;
`;
const ContentTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #808080;
  margin-bottom: 8px;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SelectWrap = styled.div`
  width: 100%;
  height: 50px;
  padding: 0 13px;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 30px;
`;
const ContentSelect = styled.select`
  width: 100%;
  height: 48px;
  background: #f5f5f5;
`;
const ContentInput = styled(FindInput)`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 17px 13px;
`;

export default AddWallet;
