import styled from "styled-components";

import COLORS from "components/atoms/colors";
import SIZE from "components/atoms/size";

export const FindBtn = styled.button`
  background-color: ${COLORS.pink};
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  padding: 12px 0;
  width: 100%;
  margin: 12px auto;
  font-weight: 700;
  &:disabled {
    background-color: #e1e1e1;
    color: ${COLORS.gray};
    cursor: default;
  }
`;
export const FindInput = styled.input`
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  padding: 11px 0;
  margin-bottom: 18px;
  background-color: #fff;
  width: 100%;
`;
export const FindContainer = styled.div`
  width: 100%;
`;
export const FindForm = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TitleContainer = styled.div`
  width: 100%;
  margin-bottom: 36px;
`;
export const Title = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #808080;
  margin-bottom: 7px;
`;
export const SubTitle = styled.p`
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
`;
export const FindInputWrap = styled.div`
  position: relative;
`;
export const PwCheck = styled.p<{ isSamePw: boolean }>`
  position: absolute;
  font-size: 12px;
  bottom: 30px;
  right: 0;
  color: ${({ isSamePw }) => (isSamePw ? "#00c773" : "#f50000")};
`;
export const Check = styled.p`
  position: absolute;
  font-size: 12px;
  bottom: 30px;
  right: ${({ right }: { right?: string }) => (right ? right : 0)};
  color: #f50000;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  max-width: ${SIZE.accountWidth};
  min-height: 90vh;
  margin: 5vh auto;
  padding: 100px 80px;
  border: 1px solid #e0e0e0;
  .back {
    position: absolute;
    top: 50px;
    left: 60px;
    cursor: pointer;
  }
`;
