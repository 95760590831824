import styled from "styled-components";

import COLORS from "components/atoms/colors";
import { ReactComponent as Image } from "assets/images/Product/FooterImg.svg";

const Guide = () => {
  return (
    <Contianer>
      <Wrap>
        <Title>아이디얼 팜이 처음이신가요?</Title>
        <Content>Ideal Farm 이용 방법</Content>
      </Wrap>
      <Image />
    </Contianer>
  );
};

const Contianer = styled.div`
  display: flex;
  align-items: center;
  background: #fff4f7;
  border-radius: 8px;
  padding: 20px;
`;
const Wrap = styled.div`
  margin-left: 7px;
  flex: 1;
`;
const Title = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.pink};
  margin-bottom: 3px;
`;
const Content = styled(Title)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export default Guide;
