import { useEffect } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

import useInputs from "hooks/useInputs";
import { getMyInfo, putMyInfo } from "api/user";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import {
  FindBtn,
  FindInput,
  FindContainer,
  FindForm,
  Container,
  Check,
  SubTitle,
  FindInputWrap,
} from "components/atoms/account";

const Info = () => {
  const { isLoading, data } = useQuery("info", getMyInfo);
  const [state, onChange] = useInputs({
    name: "",
    birthday: "",
    phone_number: "",
  });
  useEffect(() => {
    if (data) {
      onChange({ target: { name: "name", value: data.name } });
      onChange({
        target: { name: "birthday", value: data.birthday.split("T")[0] },
      });
      onChange({ target: { name: "phone_number", value: data.phone_number } });
    }
  }, [isLoading]);

  const navigate = useNavigate();
  const { phone_number, birthday, name } = state;
  const handleSubmit = async () => {
    const result = await putMyInfo(state);
    if (result) {
      alert("정보를 변경하였습니다.");
      navigate("/my");
    }
  };
  const isNameOk = 1 < name?.length && name.length < 6;
  const isBirthOk = birthday?.length !== 0;
  const numberRegex = /^[0-9]*$/;
  const isNumberOk = numberRegex.test(phone_number);
  if (isLoading) return null;

  return (
    <Container>
      <Link to="/my">
        <Back />
      </Link>
      <TitleContainer>
        <SubTitle>변경할 정보를</SubTitle>
        <SubTitle>입력해주세요</SubTitle>
      </TitleContainer>
      <FindContainer>
        <FindForm>
          <FindInputWrap>
            <FindInput
              placeholder="이름"
              value={name}
              onChange={(e) => {
                onChange(e);
              }}
              name="name"
            />
            {!isNameOk && <Check>2글자 이상 5글자 이하로 입력해주세요.</Check>}
          </FindInputWrap>
          <FindInputWrap>
            <FindInput
              value={birthday}
              onChange={(e) => {
                onChange(e);
              }}
              name="birthday"
              type="date"
              min="1900-01-01"
              max="2020-12-31"
            />
            {!isBirthOk && <Check right={"20px"}>날짜를 입력해주세요.</Check>}
          </FindInputWrap>
          <FindInputWrap>
            <FindInput
              placeholder="휴대폰 번호 (-없이 입력)"
              value={phone_number}
              onChange={(e) => {
                onChange(e);
              }}
              name="phone_number"
            />
            {!isNumberOk && <Check>숫자만 입력해주세요.</Check>}
          </FindInputWrap>
          <FindBtn
            disabled={!isNameOk || !isBirthOk || !isNumberOk}
            onClick={handleSubmit}
          >
            변경
          </FindBtn>
        </FindForm>
      </FindContainer>
    </Container>
  );
};

const TitleContainer = styled.div`
  width: 100%;
  margin-bottom: 54px;
`;

export default Info;
