import { Link } from "react-router-dom";
import { useCallback, useState } from "react";

import useInputs from "hooks/useInputs";
import { SetStateNumberType } from "types";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import {
  FindBtn,
  FindInput,
  FindContainer,
  FindForm,
  TitleContainer,
  Title,
  SubTitle,
  PwCheck,
  FindInputWrap,
  Container,
} from "components/atoms/account";

const FindPwResult = ({ setPwPage }: { setPwPage: SetStateNumberType }) => {
  const [state, onChange] = useInputs({ pw: "", pwCheck: "" });
  const { pw, pwCheck } = state;
  const [pwMessage, setPwMessage] = useState<string>("");
  // 유효성 검사
  const [isPw, setIsPw] = useState<boolean>(false);
  const onChangePw = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const passwordRegex = /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^*+=-]).{6,25}$/;
    const passwordCurrent = e.target.value;
    if (passwordCurrent === "") {
      setPwMessage("");
      setIsPw(false);
    } else if (!passwordRegex.test(passwordCurrent)) {
      setPwMessage("특수문자를 포함한 6글자 이상의 형식으로 적어주세요.");
      setIsPw(false);
    } else {
      setPwMessage("");
      setIsPw(true);
    }
  }, []);

  return (
    <Container>
      <Back onClick={() => setPwPage(1)} />
      <TitleContainer>
        <Title>비밀번호 찾기</Title>
        <SubTitle>
          비밀번호를
          <br />
          변경해주세요
        </SubTitle>
      </TitleContainer>
      <FindContainer>
        <FindForm>
          <FindInputWrap>
            <FindInput
              placeholder="신규 비밀번호 (특수문자를 포함한 6 자리 이상)"
              value={pw}
              type="password"
              onChange={(e) => {
                onChange(e);
                onChangePw(e);
              }}
              name="pw"
            />
            <PwCheck isSamePw={isPw}>{pwMessage}</PwCheck>
          </FindInputWrap>
          <FindInputWrap>
            <FindInput
              placeholder="비밀번호 확인"
              value={pwCheck}
              onChange={onChange}
              type="password"
              name="pwCheck"
            />
            <PwCheck isSamePw={pw === pwCheck}>
              {pwCheck && (pw === pwCheck ? "일치" : "불일치")}
            </PwCheck>
          </FindInputWrap>
          <Link to="/login">
            <FindBtn
              disabled={!pwCheck || !pw || pw !== pwCheck || !isPw}
              onClick={() => setPwPage(1)}
            >
              확인
            </FindBtn>
          </Link>
        </FindForm>
      </FindContainer>
    </Container>
  );
};

export default FindPwResult;
