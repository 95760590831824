import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { LoginState } from "stores/LoginStates";
import { LandingMenuType } from "types/menu";
import { ReactComponent as LandingLogo } from "assets/icons/LandingLogo.svg";
import { ReactComponent as LandingLogoWhite } from "assets/icons/LandingLogoWhite.svg";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";

const Nav = ({ menuList }: { menuList: LandingMenuType[] }) => {
  const isLogin = useRecoilValue(LoginState);
  const [scrollY, setScrollY] = useState(0);
  const [ScrollActive, setScrollActive] = useState(false);
  function handleScroll() {
    if (scrollY > 0) {
      setScrollY(window.pageYOffset);
      setScrollActive(true);
    } else {
      setScrollY(window.pageYOffset);
      setScrollActive(false);
    }
  }
  useEffect(() => {
    function scrollListener() {
      window.addEventListener("scroll", handleScroll);
    } //  window 에서 스크롤을 감시 시작
    scrollListener(); // window 에서 스크롤을 감시
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }; //  window 에서 스크롤을 감시를 종료
  });
  const moveScroll = (index: number) => {
    const { ref } = menuList[index];
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Layout
      isPink={!menuList.length && true}
      isScrollTop={menuList.length !== 0 && scrollY === 0}
    >
      <Container>
        <Link to="/">
          {menuList.length !== 0 && scrollY === 0 ? (
            <LandingLogoWhite />
          ) : (
            <LandingLogo />
          )}
        </Link>
        <MenuTab>
          {menuList.map(({ name }: LandingMenuType, i: number) => (
            <Menu
              onClick={() => moveScroll(i)}
              key={i}
              isScrollTop={scrollY === 0}
            >
              {name}
            </Menu>
          ))}

          <Menu
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/1FFaYikipuf1eccoCKX_RUnI2WMEZqvbwheDFtQmFd8E/viewform?edit_requested=true",
                "_blank"
              )
            }
            key={5}
            isScrollTop={scrollY === 0}
          >
            {`비상장 매도`}
          </Menu>
        </MenuTab>
        {isLogin ? (
          <LoginWrap>
            <Link to="/main">
              <Login>메인으로</Login>
            </Link>
          </LoginWrap>
        ) : (
          <LoginWrap>
            <Link to="/login">
              <Login>로그인</Login>
            </Link>
          </LoginWrap>
        )}
      </Container>
    </Layout>
  );
};

const Layout = styled.div<{ isPink: boolean; isScrollTop: boolean }>`
  position: fixed;
  background: ${({ isScrollTop }) =>
    isScrollTop ? "rgba(0, 0, 0, 0)" : "#fff"};
  height: 80px;
  width: 100%;
  z-index: 3;
  @media only screen and (max-width: 1200px) {
    background: ${({ isPink }) => isPink && "#FFF6F8"};
    position: ${({ isPink }) => isPink && "static"};
  }
`;
const Container = styled.div`
  height: 100%;
  max-width: ${SIZE.landingWidth};
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
  @media only screen and (max-width: 1200px) {
    padding-left: 70px;
  }
  @media only screen and (max-width: 787px) {
    padding-left: 22px;
  }
`;
const MenuTab = styled.div`
  flex: 1;
  display: flex;
  margin: 0 auto;
  margin-left: 122px;
  @media only screen and (max-width: 1200px) {
    margin-left: 82px;
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
const Menu = styled.p<{ isScrollTop: boolean }>`
  font-size: 16px;
  color: ${({ isScrollTop }) => (isScrollTop ? "#fff" : "#808080")};
  cursor: pointer;
  text-align: left;
  & + & {
    margin-left: 85px;
  }
  @media only screen and (max-width: 1200px) {
    & + & {
      margin-left: 68px;
    }
  }
`;
const LoginWrap = styled.div`
  @media only screen and (max-width: 767px) {
    flex: 1;
    text-align: end;
  }
`;
const Login = styled.button`
  background: ${COLORS.pink};
  border-radius: 4px;
  color: #fff;
  padding: 11px 28px;
  font-size: 16px;
  font-weight: 500;
  @media only screen and (max-width: 767px) {
    padding: 7px 21px;
    font-size: 14px;
  }
`;

export default Nav;
