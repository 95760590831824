export const colorData = [
  {
    name: "KRW",
    bgColor: "#FFF3F6",
    borderColor: "#FFE7ED",
    fontColor: "#FF3061",
  },
  {
    name: "BTC",
    bgColor: "#EDFBF8",
    borderColor: "#CBF6EC",
    fontColor: "#00CA99",
  },
  {
    name: "ETH",
    bgColor: "#ECF8FF",
    borderColor: "#D0EEFF",
    fontColor: "#14AAFF",
  },
];
