import styled from "styled-components";
import { useQuery } from "react-query";

import { getProducts } from "api/product";
import { ProductsType } from "types/products";
import { ReactComponent as MoreView } from "assets/icons/Main/moreView.svg";
import COLORS from "components/atoms/colors";
import Item from "components/templates/Products/Item";

const ProductsList = ({ activeCoin }: { activeCoin: string }) => {
  const { data, isLoading } = useQuery("products", getProducts);
  if (isLoading) return null;

  return (
    <Layout>
      <Container>
        <TitleWrap>
          <Title>상품 목록</Title>
          {/* <SortOption>
            <option value="예치 금액 순">예치 금액 순</option>
            <option value="예치 금액 역순">예치 금액 역순</option>
          </SortOption> */}
        </TitleWrap>
        {data.length !== 0 ? (
          data
            .filter(
              ({ name_en }: { name_en: string }) => name_en === activeCoin
            )
            .map((p: ProductsType, i: number) => <Item data={p} key={i} />)
        ) : (
          <More>판매되고 있는 상품이 없습니다.</More>
        )}
        {/* <More>
          더보기
          <MoreView />
        </More> */}
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  background-color: #f5f5f5;
`;
const Container = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 45px 15px 191px;
  min-height: calc(100vh - 95px);
`;
const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
const Title = styled.p`
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
`;
const SortOption = styled.select`
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.gray};
  background-color: #f5f5f5;
`;

const More = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-left: 7px;
  }
`;

export default ProductsList;
