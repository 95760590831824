import { getWallets } from "api/user";
import { useQuery } from "react-query";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import { ReactComponent as Delete } from "assets/icons/Account/Delete.svg";
import { Container } from "components/atoms/account";

const Wallets = () => {
  const { data, isLoading } = useQuery("wallet", () => getWallets());
  if (isLoading) return null;

  return (
    <Main>
      <Top>
        <Link to="/my">
          <Back />
        </Link>
        <Link to="add">
          <AddBtn>+ 추가</AddBtn>
        </Link>
      </Top>

      <TitleContainer>
        <Title>내 업비트 지갑 주소 목록</Title>
        <SubTitle>상품 만기시 입금 받으실 주소입니다.</SubTitle>
      </TitleContainer>
      <MenuList>
        {data.length ? (
          data.map(
            (
              {
                id,
                name_kr,
                unit,
                address,
              }: {
                id: number;
                name_kr: string;
                unit: string;
                address: string;
              },
              i: number
            ) => (
              <MenuTab key={id}>
                <ContentContainer>
                  <TitleContainer>
                    <ContentTitle>
                      {name_kr} ({unit}) 지갑 주소
                    </ContentTitle>
                    <Content>{address}</Content>
                  </TitleContainer>
                  <Delete />
                </ContentContainer>
              </MenuTab>
            )
          )
        ) : (
          <EmptyTab>등록 된 업비트 지갑 주소가 없습니다</EmptyTab>
        )}
      </MenuList>
    </Main>
  );
};

const Main = styled(Container)`
  padding: 50px 80px 100px 80px;
  .back {
    position: static;
  }
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  width: 100%;
`;
const AddBtn = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ff3061;
  cursor: pointer;
`;
const MenuList = styled.div`
  width: 100%;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 0 14px;
  margin-top: 25px;
`;
const MenuTab = styled.div`
  height: 60px;
  padding: 11px 0 14px 0;
  & + & {
    border-top: 1px solid #e0e0e0;
  }
`;
const EmptyTab = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #808080;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #808080;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ContentTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #808080;
  margin-left: 5px;
`;
const Content = styled(ContentTitle)`
  color: #000;
  margin-top: 1px;
`;
const ContentContainer = styled(TitleContainer)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  svg {
    margin-right: 5px;
    cursor: pointer;
  }
`;

export default Wallets;
