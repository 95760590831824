import useInputs from "hooks/useInputs";
import { SetStateNumberType } from "types";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import {
  FindBtn,
  FindInput,
  FindContainer,
  FindForm,
  TitleContainer,
  Title,
  SubTitle,
  Container,
} from "components/atoms/account";

const FindPwCertification = ({
  setPwPage,
}: {
  setPwPage: SetStateNumberType;
}) => {
  const [state, onChange] = useInputs({ number: "" });
  const { number } = state;

  return (
    <Container>
      <Back onClick={() => setPwPage(0)} />
      <TitleContainer>
        <Title>비밀번호 찾기</Title>
        <SubTitle>
          인증번호를
          <br />
          입력해주세요.
        </SubTitle>
      </TitleContainer>
      <FindContainer>
        <FindForm>
          <FindInput
            placeholder="이메일로 전송된 인증번호 6자리"
            value={number}
            onChange={onChange}
            name="number"
          />
          <FindBtn disabled={!number} onClick={() => setPwPage(2)}>
            확인
          </FindBtn>
        </FindForm>
      </FindContainer>
    </Container>
  );
};

export default FindPwCertification;
