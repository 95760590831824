import { Link } from "react-router-dom";

import getDDay from "utils/getDDay";
import styled from "styled-components";
import { coinData } from "datas/landing";
import { AllInvestType } from "types/invest";
import COLORS from "components/atoms/colors";
import Advice from "components/templates/Main/Invests/Advice";
import Backdrop from "components/templates/Main/Invests/Backdrop";

const Item = ({ data }: { data: AllInvestType }) => {
  const {
    id,
    code,
    amount,
    deposit_start_at,
    deposit_end_at,
    duration_month,
    name_en,
    name,
    typed_wallet,
  } = data;
  const { img } = coinData.find((i) => i.unit === name_en) || {};

  return (
    <Link to={`/invests/${id}`}>
      <ProductContainer>
        <Backdrop confirm={typed_wallet} />
        <Advice
          typed_wallet={typed_wallet}
          deposit_start_at={deposit_start_at}
          deposit_end_at={deposit_end_at}
        />
        <ProductInfo>
          <InfoTitleContainer>
            <InfoTitle>
              {img}
              {name}
            </InfoTitle>
            {deposit_end_at && (
              <InfoDate>
                <InfoContent>{duration_month}개월</InfoContent>
                <InfoContent>
                  {getDDay(deposit_end_at).day < 0
                    ? "만료"
                    : getDDay(deposit_end_at).day + "일"}
                </InfoContent>
              </InfoDate>
            )}
          </InfoTitleContainer>
          <InfoSubTitleContainer>
            <InfoSubTitle>{code}</InfoSubTitle>
            <InfoDate>
              <InfoSubTitle>예치기간</InfoSubTitle>
              <InfoSubTitle>남은 만기일</InfoSubTitle>
            </InfoDate>
          </InfoSubTitleContainer>
          <InfoQuantity>
            예치수량
            <span>
              {amount.toLocaleString()}&nbsp;
              {name_en}
            </span>
          </InfoQuantity>
        </ProductInfo>
      </ProductContainer>
    </Link>
  );
};

const InfoTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const InfoContent = styled.div`
  font-size: 14px;
  font-weight: 500px;
  line-height: 20px;
  min-width: 50px;
  text-align: center;
  &:first-child {
    margin-right: 21px;
  }
`;
const ProductContainer = styled.div`
  padding: 13px;
  border: 1px solid ${COLORS.lightestGray};
  border-radius: 8px;
  margin-bottom: 10px;
  max-width: 500px;
  margin: 0 auto 10px;
  position: relative;
`;
const ProductInfo = styled.div``;
const InfoDate = styled.div`
  display: flex;
`;
const InfoTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  display: flex;
  align-items: center;
  svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
`;
const InfoSubTitleContainer = styled(InfoTitleContainer)``;
const InfoSubTitle = styled(InfoContent)`
  font-size: 10px;
  font-weight: 500px;
  line-height: 14px;
`;
const InfoQuantity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  margin-top: 14px;
  background: #f4f4f4;
  font-size: 12px;
  color: ${COLORS.gray};
  font-weight: 500;
  line-height: 17px;
  span {
    font-size: 16px;
    line-height: 23px;
    color: #000;
  }
`;

export default Item;
