import { AxiosError } from "axios";

import { api } from "api/client";
import { AccountType, WalletType } from "types/account";

export const signup = async (signUpData: AccountType) => {
  try {
    const response = await api.post("/users/signUp", signUpData);
    const success = response.data.success;
    return success;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response) {
      const failed = err.response.data.success;
      alert(err.response?.data.message);
      return failed;
    }
  }
};

export const login = async (loginData: AccountType) => {
  try {
    const response = await api.post("/users/logIn", loginData);
    return response;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response) {
      alert(err.response?.data.message);
    }
  }
};

export const getUserToken = (): string => {
  const token = localStorage.getItem("userToken");

  return token ? token : "";
};

export const getMyInfo = async () => {
  const response = await api.get(`/users/info`);
  return response.data;
};

export const getWallets = async () => {
  const response = await api.get(`/wallets`);
  return response.data.wallets;
};

export const postWallets = async (walletData: WalletType) => {
  const response = await api.post("/wallets", walletData);
  return response;
};

export const pwChange = async (password: string) => {
  try {
    const response = await api.patch("/users/password", { password });
    return response;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response) {
      alert(err.response?.data.message);
    }
  }
};

export const deleteUser = async () => {
  const response = await api.delete("/users");
  return response;
};

export const putMyInfo = async (userData: AccountType) => {
  const response = await api.put("/users/my-info", userData);
  return response;
};

export const postNft = async (id: string, walletAddress: string) => {
  try {
    const response = await api.post(`/invests/${id}/nft`, {
      walletAddress,
    });
    return response;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response) {
      alert("NFT 연동에 실패하였습니다.");
    }
  }
};
