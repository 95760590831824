import { useState, ReactElement } from "react";
import { useQuery } from "react-query";
import styled, { css } from "styled-components";

import { periodData } from "datas/landing";
import { getTradePrice } from "api/tradePrice";
import { ReactComponent as HalfArrow } from "assets/icons/Landing/HalfArrow.svg";
import COLORS from "components/atoms/colors";
import Slider from "components/molecules/Slider";

interface CoinType {
  id: number;
  name: string;
  coins_id: number;
  images: {
    active: ReactElement;
    inActive: ReactElement;
  };
  unit: string;
  profit: {
    rate: number;
    month: number;
  };
  maxAmount: number;
}

const INIT_PERCENT = 50;
const Calculator = ({ coin }: { coin: CoinType }) => {
  const { data, isLoading } = useQuery("tradePrice", getTradePrice);
  const tradePrice = isLoading ? 0 : data[0].trade_price;
  const [activePeriodIndex, setActivePeriodIndex] = useState(0);
  const [percent, setPercent] = useState(INIT_PERCENT);
  const activePeriod = periodData[activePeriodIndex];
  const { maxAmount, profit, unit } = coin;
  let targetAmount = (maxAmount * percent) / 100;
  // 1이상이면 소수점 제거
  if (targetAmount > 1) {
    targetAmount = parseInt(targetAmount + "");
  }
  const rewardRate = profit.rate ** (activePeriod.month / profit.month);
  const rewardYield = (targetAmount * rewardRate).toFixed(2); // 소수점 얼마나 찍을지 계산.
  const rewardFixed = (+rewardYield.replace(/\.?0+$/, "")).toLocaleString(
    "ko-KR"
  ); // 소수점 뒤에 000 제거, 숫자로 변경 후 천자리마다 , 적용.
  const reward = (
    +rewardYield.replace(/\.?0+$/, "") - targetAmount
  ).toLocaleString("ko-KR");
  const tradePriceWon = (
    (+rewardYield.replace(/\.?0+$/, "") - targetAmount) *
    tradePrice
  ).toLocaleString("ko-KR");
  const maxRate = ((profit.rate ** 4 - 1) * 100).toFixed(2);

  return (
    <YieldCalc>
      <CalcSetting>
        <SubText margin={false}>{coin.name}</SubText>
        <SettingBox>
          <Quantity>
            {targetAmount.toLocaleString("ko-KR")} {unit}
            <SubText margin={true}>만큼을</SubText>
          </Quantity>
          <Slider
            percent={percent}
            onChange={(percent: number) => setPercent(percent)}
          />
        </SettingBox>
        <SettingOption>
          {periodData.map(({ name }, index) => (
            <BtnOption
              key={name}
              active={activePeriodIndex === index}
              onClick={() => setActivePeriodIndex(index)}
            >
              {name}
            </BtnOption>
          ))}
          <SubText margin={true}>동안 예치할 경우</SubText>
        </SettingOption>
      </CalcSetting>
      <CalcReword>
        <SubTitle>환급 받을 수량은 </SubTitle>
        <ResultReword>
          {rewardFixed}&nbsp;
          {unit}&nbsp;
        </ResultReword>
        <RewordDifference>
          +{reward}&nbsp;
          {unit}
        </RewordDifference>
        <YearReword>
          <span>최대</span> 연 {maxRate}%
        </YearReword>
        <CautionText>3개월 만기 상품 * 4회 예치시</CautionText>
        <HalfArrow />
      </CalcReword>
      {/* <ExchangeContainer>
        <ExchangeTitle>원화로 환산 할 경우</ExchangeTitle>
        <Exchange>+ {tradePriceWon}원</Exchange>
      </ExchangeContainer> */}
    </YieldCalc>
  );
};

const YieldCalc = styled.div`
  width: 550px;
  border-radius: 13px;
  margin: 0 auto;
  padding: 37px 35px 29px;
  border: 1px solid ${COLORS.lightestGray};
  background-color: #fff;
  @media only screen and (max-width: 787px) {
    width: 320px;
    padding: 23px 19px 22px;
  }
`;
const CalcSetting = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const SettingOption = styled.div`
  display: flex;
  margin-top: 25px;
  align-items: center;
  @media only screen and (max-width: 787px) {
    margin-top: 25px;
  }
`;
const BtnOption = styled.button<{ active: boolean }>`
  width: 60px;
  height: 35px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  background-color: #f2f2f2;
  color: ${COLORS.darkGray};
  border-radius: 20px;
  & + & {
    margin-left: 6px;
  }
  ${({ active }) =>
    active &&
    css`
      background: ${COLORS.pink};
      color: #fff;
      font-weight: 700;
    `}
  @media only screen and (max-width: 787px) {
    width: 50px;
    height: 30px;
    font-size: 12px;
    line-height: 18px;
  }
`;
const SettingBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const Quantity = styled.div`
  margin: 3px 0 15px 0;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  display: flex;
`;
const CalcReword = styled.div`
  border-radius: 13px;
  background-color: ${COLORS.pink};
  padding: 25px 26px 19px;
  margin-top: 30px;
  position: relative;
  svg {
    position: absolute;
    right: -1px;
    top: 24px;
  }
  @media only screen and (max-width: 787px) {
    width: 282px;
    padding: 16px 16px 14px;
    svg {
      top: 20px;
      height: 114px;
      right: -9px;
    }
  }
`;
const YearReword = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #fff;
  background-color: #000;
  border-radius: 20px;
  padding: 6px 10px;
  margin: 43px auto 3px auto;
  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
  }
  @media only screen and (max-width: 787px) {
    padding: 3px 7.5px;
    font-size: 12px;
    line-height: 17px;
    margin: 29px auto 3px auto;
    span {
      font-size: 10px;
      line-height: 14px;
    }
  }
`;
const ResultReword = styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  color: #fff;
  margin: 6px 0 1px 0;
  span {
    font-size: 24px;
  }
  @media only screen and (max-width: 787px) {
    margin-top: 4px;
    font-size: 21px;
    line-height: 30px;
    span {
      font-size: 18px;
    }
  }
`;
const RewordDifference = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffd6e2;
  @media only screen and (max-width: 787px) {
    font-size: 12px;
  }
`;
const CautionText = styled.p`
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  color: #ffd6e2;
`;
const SubText = styled.p<{ margin: boolean }>`
  font-size: 14px;
  color: ${COLORS.darkGray};
  margin: ${({ margin }) => margin && "0 0 0 11px;"};
  font-weight: 500;
  @media only screen and (max-width: 787px) {
    margin: ${({ margin }) => margin && "0 0 0 7px;"};
  }
`;
const SubTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  @media only screen and (max-width: 787px) {
    font-size: 12px;
  }
`;
// const ExchangeContainer = styled.div`
//   margin-top: 18px;
// `;
// const ExchangeTitle = styled.p`
//   color: ${COLORS.darkGray};
//   font-size: 14px;
//   line-height: 20px;
//   font-weight: 400;
//   margin-bottom: 4px;
// `;
// const Exchange = styled.div`
//   background: #f7f7f7;
//   border: 1px solid ${COLORS.lightestGray};
//   border-radius: 4px;
//   font-size: 17px;
//   line-height: 25px;
//   font-weight: 700;
//   padding: 7px 13px;
//   box-sizing: border-box;
//   height: 40px;
// `;

export default Calculator;
