import Moment from "react-moment";
import styled from "styled-components";

import { ProductsType } from "types/products";
import {
  Content,
  ContentTitle,
  ContentWrap,
} from "components/atoms/yetEndProducts";
import { InfoGraph } from "components/atoms/main";

const Bottom = ({ data }: { data: ProductsType }) => {
  const { fund_end_at, collected_amount, max_amount } = data;

  return (
    <Container>
      <ContentWrap>
        <ContentTitle>신청 기간</ContentTitle>
        <Content>
          ~&nbsp;
          <Moment local format="YYYY.MM.DD">
            {fund_end_at}
          </Moment>
        </Content>
      </ContentWrap>
      <ContentWrap>
        <ContentTitle>모집률</ContentTitle>
        <Content>{(collected_amount / max_amount) * 100}%</Content>
      </ContentWrap>
      <InfoGraph value={(collected_amount / max_amount) * 100} max="100" />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background: #f5f5f5;
  padding: 13px 17px;
  margin-top: 15px;
`;

export default Bottom;
