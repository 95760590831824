import useInputs from "hooks/useInputs";

import { SetStateNumberType } from "types";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import {
  FindBtn,
  FindInput,
  FindContainer,
  FindForm,
  TitleContainer,
  Title,
  SubTitle,
  Container,
} from "components/atoms/account";

const FindIdCertification = ({
  setPage,
}: {
  setPage: SetStateNumberType;
  onPrev: () => void;
  onNext: () => void;
}) => {
  const [state, onChange] = useInputs({ number: "" });
  const { number } = state;

  return (
    <Container>
      <Back onClick={() => setPage(0)} />
      <TitleContainer>
        <Title>이메일 찾기</Title>
        <SubTitle>
          인증번호를
          <br />
          입력해주세요
        </SubTitle>
      </TitleContainer>
      <FindContainer>
        <FindForm>
          <FindInput
            placeholder="문자로 전송된 인증번호 6자리"
            value={number}
            onChange={onChange}
          />
          <FindBtn disabled={!number} onClick={() => setPage(2)}>
            확인
          </FindBtn>
        </FindForm>
      </FindContainer>
    </Container>
  );
};

export default FindIdCertification;
