import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import ScrollToTop from "components/molecules/ScrollToTop";
import NavBottom from "components/organisms/NavBottom";
import Main from "components/pages/Main";
import MyInfo from "components/pages/My";
import Empty from "components/pages/Empty";
import Guide from "components/pages/Guide";
import Alarm from "components/pages/Alarm";
import Info from "components/pages/My/Info";
import Invest from "components/pages/Invest/Invest";
import ApplyNFT from "components/pages/Invest/ApplyNFT";
import Product from "components/pages/Product";
import Products from "components/pages/Products";
import EndProducts from "components/pages/EndProducts";
import YetEndProducts from "components/pages/YetEndProducts";
import Login from "components/pages/Account/Login";
import SignUp from "components/pages/Account/SignUp";
import FindId from "components/pages/Account/FindId";
import FindPw from "components/pages/Account/FindPw";
import Landing from "components/pages/Landing/Landing";
import Staking from "components/pages/Landing/Staking";
import Notice from "components/pages/My/Notice";
import NoticeDetail from "components/pages/My/NoticeDetail";
import Wallets from "components/pages/My/Wallets";
import PwChange from "components/pages/My/PwChange";
import Recommend from "components/pages/My/Recommend";
import AddWallet from "components/pages/My/AddWallet";
import NFT from "components/pages/NFT";
import PrivateRoute from "Router/PrivateRoute";
import RestrictedRoute from "Router/RestrictedRoute";

const Routers = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/staking/:id" element={<Staking />} />
        <Route element={<PrivateRoute />}>
          <Route element={<NavBottom />}>
            <Route path="/main" element={<Main />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/yet-end" element={<YetEndProducts />} />
            <Route path="/products/end" element={<EndProducts />} />
            <Route path="/nft" element={<NFT />} />
            <Route path="/my" element={<MyInfo />} />
          </Route>
          <Route path="/products/:id" element={<Product />} />
          <Route path="/invests/:id" element={<Invest />} />
          <Route path="/applynft/:id" element={<ApplyNFT />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/alarm" element={<Alarm />} />
          <Route path="/my" element={<Outlet />}>
            <Route path="info" element={<Info />} />
            <Route path="pwchange" element={<PwChange />} />
            <Route path="notice" element={<Notice />} />
            <Route path="notice/:noticeId" element={<NoticeDetail />} />
            <Route path="recommend" element={<Recommend />} />
            <Route path="wallets" element={<Wallets />} />
            <Route path="wallets/add" element={<AddWallet />} />
          </Route>
        </Route>
        <Route element={<RestrictedRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/find-id" element={<FindId />} />
          <Route path="/find-pw" element={<FindPw />} />
        </Route>
        <Route path="*" element={<Empty />} />
      </Routes>
    </Router>
  );
};

export default Routers;
