import { AxiosError } from "axios";

import { api } from "api/client";
import { InvestType } from "types/invest";
import { DepositType } from "types/deposit";

export const getInvests = async () => {
  const response = await api.get(`/invests`);
  return response.data;
};

export const getInvest = async (id: string) => {
  try {
    const response = await api.get(`/invests/${id}`);
    return response.data;
  } catch (e) {
    return null;
  }
};

export const getWallet = async () => {
  const response = await api.get(`/wallets`);
  return response.data;
};

export const getReceipt = async (id: string) => {
  const response = await api.get(`/invests/${id}/receipt`);
  return response.data;
};

export const postDeposit = async (data: DepositType) => {
  const response = await api.post(`/invests/deposit`, data);
  return response.data;
};

export const postDepositImage = async ({ id, file }: DepositType) => {
  const data = new FormData();
  data.append("id", id);
  data.append("file", file);
  const response = await api.post(`/invests/deposit/image`, data);
  return response.data;
};

export const postInvest = async (investData: InvestType) => {
  try {
    const response = await api.post(`/invests`, investData);
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response) {
      return err.response.data;
    }
  }
};

export const postRecommendCode = async (recommendCode: string) => {
  try {
    const response = await api.post(`/invests/recommendCode`, {
      recommend_code: recommendCode,
    });
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response) {
      const failed = err.response.data.success;
      return failed;
    }
  }
};

export const deleteInvest = async (id: string) => {
  try {
    const response = await api.delete(`/invests`, {
      data: {
        id: id,
      },
    });
    alert("신청이 취소되었습니다.");
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response) {
      alert(err.response.data.message);
      return err.response.data;
    }
  }
};
