import { expiryData } from "datas/invest";

const getDDay = (dday: string) => {
  const setDate = new Date(dday);
  const now = new Date();
  const koreaTimeDiff = 9 * 60 * 60 * 1000; // 한국 시간은 UTC보다 9시간 빠름(9시간의 밀리세컨드 표현)
  const koreaNow = new Date(now.getTime() + koreaTimeDiff);
  const gap = setDate.getTime() - koreaNow.getTime();
  const day = Math.floor(gap / (1000 * 60 * 60 * 24));
  const hours = Math.floor((gap % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((gap % (1000 * 60 * 60)) / (1000 * 60));
  return { day, hours, minutes };
};
export default getDDay;

export const convertFromStringToDate = (date: string) => {
  let dateComponents = date.split("T");
  let datePieces = dateComponents[0];
  let timePieces = dateComponents[1].split(":");
  return `${datePieces} ${timePieces[0]}:${timePieces[1]}`;
};

export const convertToDateNoTime = (date: string) => {
  let dateComponents = date.split("T");
  let datePieces = dateComponents[0];
  return `${datePieces}`;
};

export const dateGap = (startDate: string | Date, endDate: string | Date) => {
  const now = new Date();
  const utcNow = now.getTime() + now.getTimezoneOffset() * 60 * 1000; // 현재 시간을 utc로 변환한 밀리세컨드값
  const koreaTimeDiff = 9 * 60 * 60 * 1000; // 한국 시간은 UTC보다 9시간 빠름(9시간의 밀리세컨드 표현)
  const koreaNow = new Date(utcNow + koreaTimeDiff);
  const setStartDate = new Date(startDate ? startDate : koreaNow);
  const setEndDate = new Date(endDate ? endDate : koreaNow);
  const gap = setEndDate.getTime() - setStartDate.getTime();
  const gapDate = Math.floor(gap / (1000 * 60 * 60 * 24));
  return gapDate;
};

export const convertToDateTime = (date: string) => {
  let dateComponents = date?.split("T");
  let datePieces = dateComponents[0];
  let timeComponents = dateComponents[1].split(":");
  let timePieces = `${timeComponents[0]}:${timeComponents[1]}`;
  return { date: datePieces, time: timePieces };
};

export const checkExpiry = (timeRate: number) => {
  if (timeRate >= 100) {
    return 5;
  } else if (timeRate >= 75) {
    return 4;
  } else if (timeRate >= 50) {
    return 3;
  } else if (timeRate >= 25) {
    return 2;
  } else if (timeRate >= 0) {
    return 1;
  } else {
    return 0;
  }
};

export const getExpiryDate = (date: string) => {
  return getDDay(date).day <= 0 ? 0 : getDDay(date).day;
};

export const getExpiryPoint = (
  deposit_start_at: string,
  deposit_end_at: string
) => {
  const gap = dateGap(deposit_start_at, deposit_end_at);
  const now = new Date();
  const gapNow = dateGap(deposit_start_at, now);
  const timeRate = (gapNow / gap) * 100;
  const expiryDate = checkExpiry(timeRate);
  return expiryDate;
};

export const getIsExpiryNear = (
  deposit_start_at: string,
  deposit_end_at: string
) => {
  const point = getExpiryPoint(deposit_start_at, deposit_end_at);
  const isNear = point >= 4;
  return isNear;
};

export const getExpiryTree = (
  deposit_start_at: string,
  deposit_end_at: string
) => {
  const point = getExpiryPoint(deposit_start_at, deposit_end_at);
  const imgSrc = expiryData[point]?.src;
  return imgSrc;
};

export const getExpiryText = (
  typed_wallet: string,
  deposit_start_at: string,
  deposit_end_at: string
) => {
  const point = getExpiryPoint(deposit_start_at, deposit_end_at);
  if (typed_wallet === "미인증") {
    return "입금 증명이 필요합니다";
  } else if (typed_wallet === "입금완료") {
    return "관리자가 입금을 완료했습니다.";
  } else {
    return expiryData[point]?.text;
  }
};
