import { useState } from "react";

import Info from "components/templates/Account/FindIdInfo";
import Result from "components/templates/Account/FindIdResult";
import Certification from "components/templates/Account/FindIdCertification";

const FindId = () => {
  const [page, setPage] = useState<number>(0);

  return (
    <>
      {
        [
          <Info setPage={setPage} />,
          <Certification
            setPage={setPage}
            onPrev={() => setPage(0)}
            onNext={() => setPage(2)}
          />,
          <Result setPage={setPage} />,
        ][page]
      }
    </>
  );
};

export default FindId;
