import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Navigate, Outlet } from "react-router-dom";

import { LoginState } from "stores/LoginStates";

const RestrictedRoute = () => {
  const isLoggedin = useRecoilValue(LoginState);
  useEffect(() => {
    if (isLoggedin) {
      alert("정상적이지 못한 접근입니다.");
    }
  }, []);
  return !isLoggedin ? <Outlet /> : <Navigate to="/main" />;
};

export default RestrictedRoute;
