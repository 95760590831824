import { useRecoilValue } from "recoil";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { postNft } from "api/user";
import { data, textData } from "datas/applyNFT";
import { walletInfoState } from "stores/walletInfo";
import { ReactComponent as Close } from "assets/icons/Main/close.svg";
import { ReactComponent as Nft } from "assets/icons/Main/btnNft.svg";
import { ReactComponent as TitleImg } from "assets/icons/Main/titleImg.svg";
import SIZE from "components/atoms/size";
import ModalBtm from "components/organisms/ModalBtm";
import ModalApply from "components/templates/Invest/TopBox/ModalApply";

const ApplyNFT = () => {
  const walletAddress = useRecoilValue(walletInfoState);
  const navigate = useNavigate();
  const { id = "" } = useParams();
  const { mutate } = useMutation(() => postNft(id, walletAddress), {
    onError: () => {
      alert("NFT 연동에 실패하였습니다.");
    },
  });

  return (
    <Container>
      <Close onClick={() => navigate(-1)} />
      <TitleContainer>
        <Title>
          NFT 신청 시 해당 상품의
          <span>
            <Nft />를 획득할 수 있습니다
          </span>
        </Title>
        <TitleImg />
      </TitleContainer>
      <Guide>
        {data.map(({ id, color, img, text }) => (
          <Content key={id} color={color}>
            {img}
            {text}
          </Content>
        ))}
      </Guide>
      <Caution>
        <CautionTitle>NFT 상품 주의사항</CautionTitle>
        {textData.map(({ id, text }) => (
          <CautionText key={id}>{text}</CautionText>
        ))}
      </Caution>
      <ModalBtm
        activator={(onOpen) => (
          <BtnApply
            onClick={() => {
              mutate();
              onOpen();
            }}
          >
            NFT 신청하기
          </BtnApply>
        )}
        content={(onClose) => <ModalApply onClose={onClose} />}
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: ${SIZE.largeWidth};
  min-height: 100vh;
  margin: 0 auto;
  border: 1px solid #e0e0e0;
  padding: 30px 18px;
`;
const TitleContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    margin-bottom: -9px;
  }
`;
const Title = styled.div`
  font-weight: 700;
  span {
    display: flex;
    align-items: center;
    svg {
      margin: 3px 5px 0 0;
    }
  }
`;
const Guide = styled.div`
  border: 1px solid #ececec;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 17px;
  margin-bottom: 20px;
`;
const Content = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 15px 7px;
  font-size: 12px;
  line-height: 160%;
  span {
    font-weight: 700;
    padding: 0 2px 2px;
    background-color: ${({ color }) => color};
  }
  & + & {
    border-top: 1px solid #ebebeb;
  }
`;
const Caution = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  padding: 15px 17px;
  margin-bottom: 59px;
`;
const CautionTitle = styled.p`
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 10px;
`;
const CautionText = styled.p`
  font-size: 10px;
  line-height: 170%;
  color: #808080;
  span {
    text-decoration-line: underline;
    text-decoration-color: #a5a5a5;
  }
`;
const BtnApply = styled.button`
  background: #000000;
  padding: 12px 0;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  width: 100%;
`;

export default ApplyNFT;
