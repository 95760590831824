import { Link } from "react-router-dom";
import { useState, useCallback } from "react";

import { AccountType } from "types/account";
import { OnChangeType, SetStateNumberType } from "types/index";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import {
  FindBtn,
  FindInput,
  FindContainer,
  FindForm,
  TitleContainer,
  Title,
  SubTitle,
  PwCheck,
  FindInputWrap,
  Container,
} from "components/atoms/account";

const SignUpInfo = ({
  setPage,
  state,
  onChange,
}: {
  setPage: SetStateNumberType;
  state: AccountType;
  onChange: OnChangeType;
}) => {
  const { email, password } = state;
  const [emailMessage, setEmailMessage] = useState<string>("");
  const [pwMessage, setPwMessage] = useState<string>("");
  const [passwordCheck, setPasswordCheck] = useState("");
  // 유효성 검사
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [isPw, setIsPw] = useState<boolean>(false);
  const onChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const emailRegex =
        /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
      const emailCurrent = e.target.value;
      if (emailCurrent === "") {
        setEmailMessage("");
        setIsEmail(false);
      } else if (!emailRegex.test(emailCurrent)) {
        setEmailMessage("이메일 형식으로 적어주세요.");
        setIsEmail(false);
      } else {
        setEmailMessage("");
        setIsEmail(true);
      }
    },
    []
  );
  const onChangePw = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const passwordRegex = /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^*+=-]).{6,25}$/;
    const passwordCurrent = e.target.value;
    if (passwordCurrent === "") {
      setPwMessage("");
      setIsPw(false);
    } else if (!passwordRegex.test(passwordCurrent)) {
      setPwMessage("특수문자를 포함한 6글자 이상의 형식으로 적어주세요.");
      setIsPw(false);
    } else {
      setPwMessage("");
      setIsPw(true);
    }
  }, []);

  return (
    <Container>
      <Link to="/login">
        <Back />
      </Link>
      <TitleContainer>
        <Title>회원가입</Title>
        <SubTitle>
          아이디와 비밀번호를
          <br />
          설정해주세요.
        </SubTitle>
      </TitleContainer>
      <FindContainer>
        <FindForm>
          <FindInputWrap>
            <FindInput
              placeholder="이메일 (아이디)"
              value={email}
              onChange={(e) => {
                onChange(e);
                onChangeEmail(e);
              }}
              name="email"
            />
            <PwCheck isSamePw={isEmail}>{emailMessage}</PwCheck>
          </FindInputWrap>
          <FindInputWrap>
            <FindInput
              placeholder="비밀번호 (특수문자를 포함한 6 자리 이상)"
              value={password}
              type="password"
              onChange={(e) => {
                onChange(e);
                onChangePw(e);
              }}
              name="password"
            />
            <PwCheck isSamePw={isPw}>{pwMessage}</PwCheck>
          </FindInputWrap>
          <FindInputWrap>
            <FindInput
              placeholder="비밀번호 확인"
              value={passwordCheck}
              onChange={(e) => setPasswordCheck(e.target.value)}
              type="password"
            />
            <PwCheck isSamePw={password === passwordCheck}>
              {passwordCheck &&
                (password === passwordCheck ? "일치" : "불일치")}
            </PwCheck>
          </FindInputWrap>
          <FindBtn
            disabled={!isEmail || !isPw || password !== passwordCheck}
            onClick={() => {
              setPage(1);
            }}
          >
            확인
          </FindBtn>
        </FindForm>
      </FindContainer>
    </Container>
  );
};

export default SignUpInfo;
