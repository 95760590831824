import styled from "styled-components";

import { copyClipboard } from "utils";
import { IF_WALLET_COIN } from "datas/wallets";
import { ReactComponent as First } from "assets/icons/Product/First.svg";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";

const Deposit = ({
  owner_account,
  data,
}: {
  owner_account: string;
  data: { bank: string };
}) => {
  const { bank } = data;
  const handleCopy = () => {
    copyClipboard(bank === "" ? IF_WALLET_COIN : owner_account);
  };

  return (
    <Container>
      <TitleContainer>
        <MainTitle>
          <First />
          예치 금액 입금하기
        </MainTitle>
        <SubTitle>하단의 계좌정보로 신청하신 금액을 입금해주세요.</SubTitle>
      </TitleContainer>
      <Wallet>
        <WalletTitle>Ideal Farm의 계좌 정보</WalletTitle>
        <WalletInputWrap>
          {bank === "" ? (
            <WalletInput type="text" value={IF_WALLET_COIN} readOnly />
          ) : (
            <WalletInput type="text" value={owner_account} readOnly />
          )}
          <WalletBtn onClick={handleCopy}>복사</WalletBtn>
        </WalletInputWrap>
      </Wallet>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto 35px auto;
  max-width: ${SIZE.mediumWidth};
`;
const TitleContainer = styled.div`
  margin-bottom: 15px;
`;
const MainTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  svg {
    margin-right: 7px;
  }
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: ${COLORS.gray};
  span {
    color: #000;
    font-weight: 500;
  }
`;
const Wallet = styled.div`
  padding: 14px 10px 16px 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
`;
const WalletTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.gray};
  margin-bottom: 5px;
`;
const WalletInputWrap = styled.div`
  width: 100%;
  background-color: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px 0 10px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
`;
const WalletInput = styled.input`
  width: 230px;
`;
const WalletBtn = styled.button`
  background: ${COLORS.pink};
  border-radius: 4px;
  padding: 7px 11px;
  color: #fff;
`;

export default Deposit;
