import styled from "styled-components";
import { Link } from "react-router-dom";

import { ReactComponent as Back } from "assets/icons/Main/Back.svg";
import SIZE from "components/atoms/size";

const Top = () => {
  return (
    <Layout>
      <Container>
        <Link to="/products">
          <Back />
        </Link>
        <Title>상품 신청하기</Title>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  position: static;
  background: #fff;
  z-index: 10;
  max-width: ${SIZE.largeWidth};
  margin: 0 auto;
  border-bottom: 1px solid #e0e0e0;
  border: 1px solid #e0e0e0;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 11px 20px 11px 20px;
  position: relative;
  a {
    position: absolute;
    left: 20px;
    background-color: inherit;
  }
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
`;

export default Top;
