import { Link } from "react-router-dom";

import useInputs from "hooks/useInputs";
import { SetStateNumberType } from "types";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import {
  FindBtn,
  FindInput,
  FindContainer,
  FindForm,
  TitleContainer,
  Title,
  SubTitle,
  Container,
} from "components/atoms/account";

const FindIdInfo = ({ setPage }: { setPage: SetStateNumberType }) => {
  const [state, onChange] = useInputs({ name: "", number: "" });
  const { name, number } = state;

  return (
    <Container>
      <Link to="/login">
        <Back />
      </Link>
      <TitleContainer>
        <Title>이메일 찾기</Title>
        <SubTitle>
          본인 인증 정보를
          <br />
          입력해주세요
        </SubTitle>
      </TitleContainer>
      <FindContainer>
        <FindForm>
          <FindInput
            placeholder="이름"
            value={name}
            onChange={onChange}
            name="name"
          />
          <FindInput
            placeholder="휴대폰번호 ( - 없이 입력)"
            value={number}
            onChange={onChange}
            name="number"
          />
          <FindBtn disabled={!name || !number} onClick={() => setPage(1)}>
            확인
          </FindBtn>
        </FindForm>
      </FindContainer>
    </Container>
  );
};

export default FindIdInfo;
