import { ReactComponent as NFT } from "assets/icons/Main/NavNFT.svg";
import { ReactComponent as Home } from "assets/icons/Main/NavHome.svg";
import { ReactComponent as OnNFT } from "assets/icons/Main/NavNFTon.svg";
import { ReactComponent as MyInfo } from "assets/icons/Main/NavMyInfo.svg";
import { ReactComponent as Product } from "assets/icons/Main/NavProduct.svg";

export const menuList = [
  { name: "내 자산", icon: <Home />, link: "/main" },
  { name: "상품", icon: <Product />, link: "/products" },
  // {
  //   name: "NFT 거래소",
  //   icon: <NFT />,
  //   icon2: <OnNFT />,
  //   link: "/nft",
  // },
  { name: "내정보", icon: <MyInfo />, link: "/my" },
];
