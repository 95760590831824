import styled from "styled-components";
import { NavLink, Outlet, useLocation } from "react-router-dom";

import { menuList } from "datas/mainMenu";
import { MainMenuType } from "types/menu";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";

const NavBottom = () => {
  const location = useLocation();
  const isNFT = location.pathname === "/nft";
  return (
    <>
      <Outlet />
      <Layout isNFT={isNFT}>
        <Container>
          <MenuTab>
            {menuList.map(
              ({ name, icon, link, icon2 }: MainMenuType, i: number) => (
                <div key={i}>
                  <Link to={link} key={i}>
                    {link === "/nft" ? (
                      <Menu2>
                        {isNFT ? icon2 : icon}
                        {name}
                      </Menu2>
                    ) : (
                      <Menu>
                        {icon}
                        {name}
                      </Menu>
                    )}
                  </Link>
                </div>
              )
            )}
          </MenuTab>
        </Container>
      </Layout>
    </>
  );
};
const Layout = styled.div<{ isNFT: boolean }>`
  width: 100%;
  max-width: ${SIZE.largeWidth};
  position: fixed;
  background: ${({ isNFT }) => (isNFT ? "#1B1B1F" : "#fff")};
  color: ${({ isNFT }) => (isNFT ? "#5C5C64" : "#000")};
  z-index: 10;
  height: 83px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top: 1px solid #f2f2f2;
  border: 1px solid #e0e0e0;
`;
const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
const MenuTab = styled.div`
  display: flex;
  flex: 1;
  margin: 0 auto;
  justify-content: space-evenly;
  max-width: ${SIZE.largeWidth};
`;
const Menu = styled.p`
  font-size: 9px;
  line-height: 13px;
  fill: ${COLORS.lightestGray};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    margin-bottom: 4px;
    width: 20px;
    height: 20px;
    fill: ${COLORS.lightestGray};
  }
`;
const Menu2 = styled.p`
  font-size: 9px;
  line-height: 13px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    margin-bottom: 4px;
  }
`;

const Link = styled(NavLink)`
  &.active {
    ${Menu} {
      color: ${COLORS.pink};
      svg {
        margin-bottom: 4px;
        fill: ${COLORS.pink};
      }
    }
    ${Menu2} {
      color: #fff;
    }
  }
`;

export default NavBottom;
