import styled from "styled-components";

import { ModalType } from "types/invest";
import COLORS from "components/atoms/colors";

const ModalConfirm = ({ onClose, depositId }: ModalType) => {
  return (
    <Container>
      <ProofContainer>
        <Title>입금 증명 확인</Title>
        <SubTitle>
          입력하신 입금 <span> 거래ID </span>입니다.
        </SubTitle>
        <Space />
        <ProofTitle>거래 ID</ProofTitle>
        <ProofBox rows={4} readOnly value={depositId} />
        <SaveBtn onClick={onClose}>확인</SaveBtn>
      </ProofContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;
const ProofContainer = styled.div`
  border-radius: 16px;
  padding: 34px 23px 23px 23px;
  background-color: #fff;
  position: relative;
  svg {
    position: absolute;
    right: 17px;
    top: 20px;
    cursor: pointer;
  }
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 4px;
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray};
  span {
    color: ${COLORS.pink};
  }
`;
const AdviceTitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
`;
const ProofTitle = styled(AdviceTitle)`
  color: ${COLORS.gray};
  margin-bottom: 6px;
`;
const ProofBox = styled.textarea`
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  resize: none;
  padding: 12px 14px;
  margin-bottom: 42px;
`;
const SaveBtn = styled.button`
  background: ${COLORS.pink};
  border-radius: 4px;
  height: 45px;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 18px;
`;
const Space = styled.div`
  margin: 11px 0;
  height: 1px;
`;

export default ModalConfirm;
