import styled from "styled-components";

import Profile from "components/templates/My/Profile";
import Menu from "components/templates/My/Menu";
import SIZE from "components/atoms/size";

const MyInfo = () => {
  return (
    <Layout>
      <Top>
        <PageTitle>내정보</PageTitle>
      </Top>
      <Profile />
      <Menu />
    </Layout>
  );
};

const Layout = styled.div`
  background-color: #f7f7f7;
  margin: 0 auto;
  padding-bottom: 83px;
  min-height: 100vh;
  max-width: ${SIZE.largeWidth};
  border: 1px solid #e0e0e0;
`;
const Top = styled.div`
  padding: 40px 0 10px 16px;
  background-color: #fff;
`;
const PageTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
`;

export default MyInfo;
