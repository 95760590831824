import { useQuery } from "react-query";
import styled from "styled-components";

import { getInvests } from "api/invest";
import SIZE from "components/atoms/size";
import Top from "components/organisms/MainTop";
import Invests from "components/templates/Main/Invests";
import CenterAdvice from "components/templates/Main/CenterAdvice";
import InvestStatus from "components/templates/Main/InvestStatus";

const Main = () => {
  const { data, isLoading } = useQuery("invests", getInvests);
  if (isLoading) return null;
  const { invest_list, graph } = data;

  return (
    <Container>
      <Top />
      <InvestStatus data={graph} />
      <CenterAdvice />
      <Invests data={invest_list} />
    </Container>
  );
};

const Container = styled.div`
  max-width: ${SIZE.largeWidth};
  margin: 0 auto;
  border: 1px solid #e0e0e0;
  min-height: 100vh;
`;

export default Main;
