import styled from "styled-components";
import { Link } from "react-router-dom";

import { SetStateNumberType } from "types";
import COLORS from "components/atoms/colors";
import { ReactComponent as Back } from "assets/icons/Account/BackArrow.svg";
import {
  FindBtn,
  FindContainer,
  TitleContainer,
  Title,
  SubTitle,
  Container,
} from "components/atoms/account";

const FindIdResult = ({ setPage }: { setPage: SetStateNumberType }) => {
  return (
    <Container>
      <Back onClick={() => setPage(1)} />
      <TitleContainer>
        <Title>이메일 찾기</Title>
        <SubTitle>
          다음의 연관
          <br />
          아이디를 찾았습니다.
        </SubTitle>
      </TitleContainer>
      <FindContainer>
        <FindIdWrap>
          <FindIdDate>2021.02.23 가입</FindIdDate>
          <FindId>idealFarm.naver.com</FindId>
        </FindIdWrap>
        <Link to="/login">
          <FindBtn>로그인화면으로 돌아가기</FindBtn>
        </Link>
      </FindContainer>
    </Container>
  );
};

const FindIdWrap = styled.div`
  padding: 12px 16px;
  background: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 6px;
`;
const FindIdDate = styled.p`
  font-size: 10px;
  margin-bottom: 2px;
  color: ${COLORS.gray};
`;
const FindId = styled.p`
  font-size: 14px;
`;

export default FindIdResult;
