import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import investState from "stores/invest";
import { getInvest, deleteInvest } from "api/invest";
import { ReactComponent as Back } from "assets/images/Product/Back.svg";
import { ReactComponent as BtcBg } from "assets/images/Product/BtcBg.svg";
import { ReactComponent as EthBg } from "assets/images/Product/EthBg.svg";
import { ReactComponent as XrpBg } from "assets/images/Product/XrpBg.svg";
import SIZE from "components/atoms/size";
import Guide from "components/templates/Invest/Guide";
import Detail from "components/templates/Invest/Detail";
import TopBox from "components/templates/Invest/TopBox";
import WalletInfo from "components/templates/Invest/WalletInfo";
import Empty from "components/pages/Empty";

const bgList: { [key: string]: JSX.Element } = {
  KRW: <></>,
  BTC: <BtcBg />,
  ETH: <EthBg />,
  XRP: <XrpBg />,
};

const MyProductDetail = () => {
  const navigate = useNavigate();
  const setInvest = useSetRecoilState(investState);

  const { id = "" } = useParams();
  const { refetch, isLoading, data } = useQuery(
    ["invest", id],
    () => getInvest(id),
    {
      onSuccess: (data) =>
        setInvest(() => ({
          refetch,
          data: data.invest_detail[0],
          contractAddress: data.contractAddress,
        })),
    }
  );
  const handleDelete = () => {
    const isConfirm = window.confirm("정말로 신청을 취소하시겠습니까?");
    if (isConfirm) {
      deleteInvest(id);
      navigate("/main");
    }
  };

  if (isLoading) return null;
  if (!isLoading && !data) return <Empty />;

  return (
    <Main>
      <Top>
        {bgList[data.name_en]}
        <Link to="/main">
          <Back />
        </Link>
        <TopBox />
      </Top>
      <Container>
        <Link to="/guide">
          <Guide />
        </Link>
        <Detail />
      </Container>
      <WalletInfo />
      {data.typed_wallet === "미인증" && (
        <CancelBtn onClick={handleDelete}>신청 취소</CancelBtn>
      )}
    </Main>
  );
};

const Top = styled.div`
  background-color: #f5f5f5;
  padding: 100px 10px 27px;
  position: relative;
  z-index: 0;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .back {
    position: absolute;
    top: 50px;
    left: 10px;
  }
`;
const Main = styled.main`
  max-width: ${SIZE.largeWidth};
  min-height: 100vh;
  background-color: #f4f4f4;
  margin: 0 auto;
  padding-bottom: 100px;
  border: 1px solid #e0e0e0;
`;
const Container = styled.div`
  background-color: #fff;
  padding: 30px 23px;
`;
const CancelBtn = styled.button`
  border: 1px solid #999;
  height: 50px;
  width: 100%;
  max-width: ${SIZE.smallWidth};
  margin: 28px auto 0;
  background-color: transparent;
  color: #999;
  display: block;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
`;

export default MyProductDetail;
