import styled from "styled-components";

import { ProductsType } from "types/products";
import { ReactComponent as HalfArrow } from "assets/icons/Landing/HalfArrow.svg";
import COLORS from "components/atoms/colors";

const Calculator = ({
  data,
  targetAmount,
  isRecommend,
}: {
  data: ProductsType;
  targetAmount: number;
  isRecommend: boolean;
}) => {
  const { unit, duration_month, apy, recommend_bonus_apy, is_need_bank } = data;
  // 추가 환급 % 계산
  const rewardRate = (apy / 12) * duration_month;
  // 추천인 추가시 추가 환급 % 계산
  const rewardRateRcd =
    rewardRate + (recommend_bonus_apy / 12) * duration_month;
  // 추가 환급 금액
  const rewardYield = (+targetAmount * (+rewardRate / 100)).toFixed(0);
  // 추천인 추가시 추가 환급 금액
  const rewardYieldRcd = (+targetAmount * (+rewardRateRcd / 100)).toFixed(0);
  // 최종 환급금액
  const rewardFixed = (+rewardYield + +targetAmount).toLocaleString("ko-KR");
  // 추천인 추가시 최종 환급금액
  const rewardFixedRcd = (+rewardYieldRcd + +targetAmount).toLocaleString(
    "ko-KR"
  );
  // krw
  const isKRW = is_need_bank === 1;
  // 최소 수익률 포맷
  const rewardRateFormat = isKRW
    ? Math.floor(rewardRate)
    : Math.floor(rewardRate * 100) / 100;
  // 최소 수익률 +  보너스 수익률 포맷
  const rewardRateRcdFormat = isKRW
    ? Math.floor(rewardRateRcd)
    : Math.floor(rewardRateRcd * 100) / 100;

  return (
    <YieldCalc>
      <CalcReword>
        <ResultReword>
          {isRecommend ? rewardFixedRcd : rewardFixed}&nbsp;
          {unit}&nbsp;
        </ResultReword>
        <RewordDifference>
          +{isRecommend ? rewardYieldRcd : rewardYield}&nbsp;
          {unit}
        </RewordDifference>
        <YearReword>
          + {isRecommend ? rewardRateRcdFormat : rewardRateFormat}%
        </YearReword>
        {isRecommend && (
          <CautionWrap>
            <CautionText>
              추천인 보너스 + {(recommend_bonus_apy / 12) * duration_month}% P
              적용
            </CautionText>
          </CautionWrap>
        )}
        <HalfArrow />
      </CalcReword>
    </YieldCalc>
  );
};

const YieldCalc = styled.div`
  border-radius: 12px;
  margin: 8px auto 0;
  background-color: #fff;
`;
const CalcReword = styled.div`
  border-radius: 13px;
  background-color: ${COLORS.pink};
  padding: 16px 16px 13px;
  position: relative;
  svg {
    position: absolute;
    right: -1px;
    top: 0px;
    width: 40px;
  }
`;
const ResultReword = styled.p`
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  color: #fff;
`;
const RewordDifference = styled.p`
  font-size: 12px;
  line-height: 17px;
  font-weight: 700;
  color: #ffd6e2;
`;
const YearReword = styled.button`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  background-color: #000;
  border-radius: 20px;
  padding: 4px 12px;
  margin: 30px 0 4px 0;
  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
  }
`;
const CautionWrap = styled.div`
  width: 137px;
  height: 19px;
  background: #ffd6e2;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CautionText = styled.p`
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #ff3061;
`;

export default Calculator;
