import { useRecoilValue } from "recoil";
import styled from "styled-components";

import investState from "stores/invest";
import { convertToDateNoTime } from "utils/getDDay";
import COLORS from "components/atoms/colors";

const Detail = () => {
  const { data } = useRecoilValue(investState);
  const {
    address,
    amount,
    deposit_end_at,
    deposit_start_at,
    created_at,
    duration_month,
    name,
    name_en,
    recommend_code,
  } = data;

  const infos = [
    {
      id: 1,
      title: "투자상품명",
      content: `${name}`,
    },
    {
      id: 2,
      title: "예치 금액",
      content: `${amount.toLocaleString()} ${name_en}`,
    },
    {
      id: 3,
      title: "예치 기간",
      content: `${duration_month}개월`,
    },
    {
      id: 4,
      title: "신청일",
      content: convertToDateNoTime(created_at ? created_at : deposit_start_at),
    },
    {
      id: 5,
      title: "만기일",
      content: convertToDateNoTime(deposit_end_at),
    },
    {
      id: 6,
      title: "업비트 지갑 주소",
      content: `${address ?? "-"}`,
    },
    {
      id: 7,
      title: "추천인",
      content: `${recommend_code ?? "-"}`,
    },
  ];

  return (
    <Container>
      <Title>상세 신청 정보</Title>
      <Info>
        {infos.map(
          ({
            id,
            title,
            content,
          }: {
            id: number;
            title: string;
            content: string;
          }) => (
            <ContentWrap key={id} isHide={name_en === "KRW" && id === 6}>
              <InfoTitle>{title}</InfoTitle>
              <InfoContent>{content === "" ? "-" : content}</InfoContent>
            </ContentWrap>
          )
        )}
      </Info>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 30px;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 19px;
`;
const Info = styled.div`
  border-radius: 4px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;
const ContentWrap = styled.div<{ isHide: boolean }>`
  display: ${({ isHide }) => (isHide ? "none" : "flex")};
  margin-bottom: 9px;
`;
const InfoTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray};
`;
const InfoContent = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  flex: 1;
  text-align: right;
`;

export default Detail;
