import styled from "styled-components";

import { ProductsType } from "types/products";
import getDDay, { dateGap } from "utils/getDDay";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";
import { ContentTitle } from "components/atoms/main";

const Info = ({ data }: { data: ProductsType }) => {
  const {
    unit,
    apy,
    recommend_bonus_apy,
    code,
    duration_month,
    max_amount,
    fund_end_at,
    collected_amount,
    amount_restrict,
    min_amount,
    name_en,
    explanation,
    name,
  } = data;
  const { day, hours, minutes } = getDDay(fund_end_at);
  const dateOver = dateGap("", fund_end_at) < 0;

  return (
    <Layout>
      <Container>
        <ContentTitle>신청 상품</ContentTitle>
        <ItemContainer>
          <ItemTop>
            <NameWrap>
              <ItemName>{name}</ItemName>
              <ItemCode>{code}</ItemCode>
            </NameWrap>
            {!dateOver && (
              <EndDate>
                {day}
                <EndDateText>일</EndDateText>
                <CenterLine />
                {hours}
                <EndDateText>시간</EndDateText>
                <CenterLine />
                {minutes}
                <EndDateText>분</EndDateText>
                <span>남음</span>
              </EndDate>
            )}
          </ItemTop>
          <ItemBottom>
            <AdviceContainer>
              <AdviceWrap>
                <AdviceTitle>예상 연간 수익률</AdviceTitle>
                <AdviceContent>
                  최소 {apy}% ~ 최대 {apy + recommend_bonus_apy}%
                </AdviceContent>
              </AdviceWrap>
              <AdviceWrap>
                <AdviceTitle>총 모집 수량</AdviceTitle>
                <AdviceContent>
                  {max_amount.toLocaleString()}&nbsp;
                  {unit}
                </AdviceContent>
              </AdviceWrap>
              <AdviceWrap>
                <AdviceTitle>남은 수량</AdviceTitle>
                <AdviceContent>
                  {max_amount - collected_amount < 0
                    ? "모집 완료"
                    : `${(
                        max_amount - collected_amount
                      ).toLocaleString()} ${unit}`}
                </AdviceContent>
              </AdviceWrap>
              <AdviceWrap>
                <AdviceTitle>최소 수량</AdviceTitle>
                <AdviceContent>
                  {min_amount.toLocaleString()} {name_en}
                </AdviceContent>
              </AdviceWrap>
              <AdviceWrap>
                <AdviceTitle>단위 제한</AdviceTitle>
                <AdviceContent>
                  {amount_restrict.toLocaleString()} {name_en}
                </AdviceContent>
              </AdviceWrap>
              <AdviceWrap>
                <AdviceTitle>예치 기간</AdviceTitle>
                <AdviceContent>{duration_month} 개월</AdviceContent>
              </AdviceWrap>
              <ExplainWrap>{explanation}</ExplainWrap>
            </AdviceContainer>
          </ItemBottom>
        </ItemContainer>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  padding: 16px 0 0 0;
`;
const Container = styled.div`
  max-width: ${SIZE.largeWidth};
  margin: 0 auto;
  background: #f5f5f5;
`;
const ItemContainer = styled.div`
  margin: 8px auto 0 auto;
  padding: 18px 20px 15px;
  border: 1px solid ${COLORS.lightestGray};
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #fff;
  max-width: ${SIZE.mediumWidth};
`;
const ItemTop = styled.div`
  padding-bottom: 10px;
  margin-bottom: 11px;
  border-bottom: 1px solid ${COLORS.lightestGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const NameWrap = styled.div``;
const ItemName = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
`;
const ItemCode = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  color: ${COLORS.lightGray};
`;

const ItemBottom = styled.div`
  display: flex;
  flex-direction: column;
  a {
    width: 250px;
  }
`;
const AdviceContainer = styled.div``;
const AdviceWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;
const AdviceTitle = styled.p`
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.gray};
`;
const AdviceContent = styled.p`
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
`;
const EndDate = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  background: #f4f4f4;
  border-radius: 4px;
  span {
    font-weight: 400;
    font-size: 9px;
    line-height: 13px;
    color: #ff3061;
    margin-left: 4px;
  }
`;
const EndDateText = styled.div`
  font-size: 9px;
  line-height: 13px;
  font-weight: 400;
  margin-left: 1px;
`;
const CenterLine = styled.div`
  width: 1px;
  height: 12px;
  border-right: 1.5px solid #e0e0e0;
  margin: 0 7px 0 5px;
`;
const ExplainWrap = styled.div`
  padding: 13px;
  background: #f2f2f2;
  font-weight: 400;
  font-size: 12px;
  margin-top: 10px;
  line-height: 22px;
  white-space: pre-wrap;
`;

export default Info;
