import { ChangeEvent, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import investState from "stores/invest";
import { postDepositImage } from "api/invest";
import { imageToBase64, copyClipboard } from "utils";
import { ReactComponent as CloseBtn } from "assets/images/Product/CloseBtn.svg";
import COLORS from "components/atoms/colors";

const DepositProofKRW = ({ onClose }: { onClose: () => void }) => {
  const { data, refetch } = useRecoilValue(investState);
  const { id, address } = data;
  const [file, setFile] = useState<File | null>(null);
  const [imgBase64, setImgBase64] = useState("");
  const handleSubmit = async () => {
    if (!file) return alert("파일을 업로드해 주세요.");
    const isConfirm = window.confirm(
      "저장하시면 이체증명서를 변경할 수 없습니다. 저장하시겠습니까?"
    );
    if (!isConfirm) return;
    const { success } = await postDepositImage({ id, file });
    if (!success) return alert("저장 실패하였습니다. 다시 시도해주세요.");
    alert("저장되었습니다.");
    onClose();
    refetch();
  };
  const handleCopy = () => copyClipboard(address);
  const handleChange = async (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const file = (target.files as FileList)[0];
    const base64 = await imageToBase64(file);
    setImgBase64(base64);
    setFile(file);
  };
  const inputEl = useRef(
    null
  ) as unknown as React.MutableRefObject<HTMLInputElement>;

  return (
    <Container>
      <CloseBtn onClick={onClose} />
      <Title>입금 증명이 필요합니다</Title>
      <SubTitle>
        입금하신 <span>이체 증명서</span>를 입력해주세요.
      </SubTitle>
      <ProofContainer>
        <TitleWrap>
          <ProofTitle>이체 증명서 첨부하기</ProofTitle>
          {imgBase64 !== "" && (
            <ChangeProof onClick={() => inputEl.current.click()}>
              변경
            </ChangeProof>
          )}
        </TitleWrap>
        <CertificateContainer>
          {imgBase64 !== "" ? (
            <>
              <CertificatePicture src={imgBase64} />
              <AddFileInput
                ref={inputEl}
                type="file"
                accept="image/*"
                name="file"
                onChange={handleChange}
              />
            </>
          ) : (
            <>
              <AddFileInput
                ref={inputEl}
                type="file"
                accept="image/*"
                name="file"
                onChange={handleChange}
              />
              <AddCertificateBtn onClick={() => inputEl.current.click()}>
                이체 증명서 첨부하기 +
              </AddCertificateBtn>
            </>
          )}
        </CertificateContainer>
      </ProofContainer>
      <ProofSubTitle>아직 입금 전이라면 아래 주소로 입금해주세요</ProofSubTitle>
      <ProofContent>Ideal Farm의 계좌정보</ProofContent>
      <WalletContainer>
        <ProofWallet type="text" value={address} readOnly />
        <WalletCopyBtn onClick={handleCopy}>복사</WalletCopyBtn>
      </WalletContainer>
      <SaveBtn onClick={handleSubmit}>저장하기</SaveBtn>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
  border-radius: 16px;
  padding: 34px 23px 23px 23px;
  background-color: #fff;
  position: relative;
  svg {
    position: absolute;
    right: 17px;
    top: 20px;
    cursor: pointer;
  }
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 4px;
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray};
  span {
    color: ${COLORS.pink};
  }
`;
const ProofContainer = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const AdviceTitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
`;
const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ProofTitle = styled(AdviceTitle)`
  color: ${COLORS.gray};
  margin-bottom: 6px;
`;
const ChangeProof = styled.button`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #ff3061;
  background-color: #fff;
`;
const ProofSubTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 13px;
`;
const ProofContent = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 5px;
  color: #808080;
`;
const CertificateContainer = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px auto 28px;
`;
const CertificatePicture = styled.img`
  border-radius: 4px;
  max-height: 150px;
  width: 100%;
`;
const AddFileInput = styled.input`
  display: none;
`;
const AddCertificateBtn = styled.button`
  background: #000000;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  padding: 13px 23px;
  max-width: 180px;
  margin: 0 auto;
`;
const WalletContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  margin-bottom: 26px;
`;
const ProofWallet = styled.input`
  padding: 11px 55px 11px 10px;
  position: absolute;
  width: 100%;
  background: #f7f7f7;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
`;
const WalletCopyBtn = styled.button`
  position: absolute;
  background: #ff3061;
  border-radius: 4px;
  padding: 6px 11px;
  color: #fff;
  font-size: 12px;
  right: 6px;
`;
const SaveBtn = styled.button`
  background: ${COLORS.pink};
  border-radius: 4px;
  height: 45px;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 18px;
`;

export default DepositProofKRW;
