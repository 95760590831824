import styled from "styled-components";

import { ProductsType } from "types/products";
import {
  SubTitle,
  Title,
  TitleContainer,
  TitleWrap,
  TopContainer,
  BtnYet,
} from "components/atoms/yetEndProducts";

const Top = ({ data }: { data: ProductsType }) => {
  const { name, unit, code, active_logo } = data;

  return (
    <TopContainer>
      <TitleContainer>
        <TitleWrap>
          <Title width={"100%"}>
            {name}({unit})
          </Title>
          <BtnYet fontColor="#000">마감</BtnYet>
        </TitleWrap>
        <SubTitle>{code}</SubTitle>
      </TitleContainer>
      <AlarmBtn src={active_logo} />
    </TopContainer>
  );
};

const AlarmBtn = styled.img`
  width: 40px;
  height: 40px;
`;

export default Top;
