import ModalImg1 from "assets/icons/Main/ModalImg1.png";
import ModalImg2 from "assets/icons/Main/ModalImg2.png";
import ModalImg3 from "assets/icons/Main/ModalImg3.png";
import ModalImg4 from "assets/icons/Main/ModalImg4.png";

export const ContentData = [
  {
    id: 1,
    title: "업비트 홈",
    img: ModalImg1,
  },
  {
    id: 2,
    title: "입출금 탭으로 들어가기",
    img: ModalImg2,
  },
  {
    id: 3,
    title: "상품 선택 후 입금하기 버튼 누르기",
    img: ModalImg3,
  },
  {
    id: 4,
    title: "주소 복사",
    img: ModalImg4,
  },
];

export const IF_WALLET_KRW = "신한 140-013-940404 : (주)아이디얼팜";
export const IF_WALLET_COIN = "3FDxAxoPSFwoS7Ns84KJCTPWYPPWPfjgLM";
