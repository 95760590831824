import styled from "styled-components";

import { ModalType } from "types/invest";
import COLORS from "components/atoms/colors";

const ModalConfirmKRW = ({ onClose, verify_certificate }: ModalType) => {
  return (
    <Container>
      <ProofContainer>
        <Title>입금 증명 확인</Title>
        <SubTitle>
          입력하신 입금 <span> 이체증명서 </span>입니다.
        </SubTitle>
        <Space />
        <TitleWrap>
          <ProofTitle>이체 증명서</ProofTitle>
        </TitleWrap>
        <CertificateContainer>
          {verify_certificate ? (
            <CertificatePicture src={verify_certificate} />
          ) : (
            <AddCertificateBtn>이체 증명서 첨부하기 +</AddCertificateBtn>
          )}
        </CertificateContainer>
        <SaveBtn onClick={onClose}>확인</SaveBtn>
      </ProofContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;
const ProofContainer = styled.div`
  border-radius: 16px;
  padding: 34px 23px 23px 23px;
  background-color: #fff;
  position: relative;
  svg {
    position: absolute;
    right: 17px;
    top: 20px;
    cursor: pointer;
  }
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 4px;
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray};
  span {
    color: ${COLORS.pink};
  }
`;
const AdviceTitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
`;
const ProofTitle = styled(AdviceTitle)`
  color: ${COLORS.gray};
  margin-bottom: 6px;
`;
const SaveBtn = styled.button`
  background: ${COLORS.pink};
  border-radius: 4px;
  height: 45px;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 18px;
`;
const Space = styled.div`
  margin: 11px 0;
  height: 1px;
`;
const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;
const CertificateContainer = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px auto 28px;
`;
const CertificatePicture = styled.img`
  border-radius: 4px;
  max-height: 150px;
  width: 100%;
`;
const AddCertificateBtn = styled.button`
  background: #000000;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  padding: 13px 23px;
  max-width: 180px;
  margin: 0 auto;
`;

export default ModalConfirmKRW;
