import { useState } from "react";
import styled from "styled-components";

import { ProductsType } from "types/products";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";
import { ContentTitle } from "components/atoms/main";
import ModalHelp from "components/templates/Product/Wallet/ModalWalletHelp";
// import { getWallet } from "api/invest";
// import { useQuery } from "react-query";

const Wallet = ({
  onChange,
  data,
}: {
  onChange: () => void;
  data: ProductsType;
}) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const { is_need_bank } = data;
  // const { data, isLoading } = useQuery("wallet", () => getWallet());
  // if (isLoading) null;

  return (
    <>
      <Layout>
        <Container>
          <ContentTitleMain>
            입금 받을 계좌 정보
            <span>상품 만기 시 해당 계좌로 입금됩니다</span>
          </ContentTitleMain>
          {is_need_bank === 1 ? (
            <ItemContainer>
              <NameWrap>
                <ItemName>은행</ItemName>
                <InputContainer>
                  <ItemSelector name="bank" onChange={onChange}>
                    <option value="">은행 선택</option>
                    <option value="경남은행">경남은행</option>
                    <option value="광주은행">광주은행</option>
                    <option value="국민은행">국민은행</option>
                    <option value="기업은행">기업은행</option>
                    <option value="농협중앙회">농협중앙회</option>
                    <option value="농협회원조합">농협회원조합</option>
                    <option value="대구은행">대구은행</option>
                    <option value="도이치은행">도이치은행</option>
                    <option value="부산은행">부산은행</option>
                    <option value="산업은행">산업은행</option>
                    <option value="상호저축은행">상호저축은행</option>
                    <option value="새마을금고">새마을금고</option>
                    <option value="수협중앙회">수협중앙회</option>
                    <option value="신한금융투자">신한금융투자</option>
                    <option value="신한은행">신한은행</option>
                    <option value="신협중앙회">신협중앙회</option>
                    <option value="외환은행">외환은행</option>
                    <option value="우리은행">우리은행</option>
                    <option value="우체국">우체국</option>
                    <option value="전북은행">전북은행</option>
                    <option value="제주은행">제주은행</option>
                    <option value="카카오뱅크">카카오뱅크</option>
                    <option value="케이뱅크">케이뱅크</option>
                    <option value="하나은행">하나은행</option>
                    <option value="한국씨티은행">한국씨티은행</option>
                    <option value="HSBC은행">HSBC은행</option>
                    <option value="SC제일은행">SC제일은행</option>
                  </ItemSelector>
                </InputContainer>
              </NameWrap>
              <ItemName>계좌 번호</ItemName>
              <InputContainer>
                <ItemInput
                  name="account_number"
                  placeholder="숫자만 입력"
                  onChange={onChange}
                  type="number"
                />
              </InputContainer>
              <ItemName>예금주</ItemName>
              <InputContainer>
                <ItemInput name="account_holder" onChange={onChange} />
              </InputContainer>
            </ItemContainer>
          ) : (
            <ItemContainer>
              <NameWrap>
                <ItemName>
                  업비트 지갑 주소
                  <span onClick={() => setIsShowModal(true)}>?</span>
                </ItemName>
                <InputContainer>
                  <ItemSelector name="wallet_address" onChange={onChange}>
                    {/* <option value="">업비트 지갑 주소를 선택해주세요.</option>
                  {data?.map((data: ProductsType, i: number) => (
                    <option value={data.id} key={i}>
                      {data.address}
                    </option>
                  ))} */}
                    <option value="직접입력">직접입력</option>
                  </ItemSelector>
                </InputContainer>
              </NameWrap>
              <ItemName>지갑주소</ItemName>
              <InputContainer>
                <ItemInput
                  name="wallet_address"
                  placeholder="지갑 주소 직접 입력"
                  onChange={onChange}
                />
              </InputContainer>
            </ItemContainer>
          )}
        </Container>
      </Layout>
      {isShowModal && <ModalHelp onClose={() => setIsShowModal(false)} />}
    </>
  );
};

const Layout = styled.div`
  width: 100%;
  padding: 16px 0 0 0;
`;
const Container = styled.div`
  max-width: ${SIZE.largeWidth};
  margin: 0 auto;
  background: #f5f5f5;
`;
const ContentTitleMain = styled(ContentTitle)`
  display: flex;
  justify-content: space-between;
  span {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    color: ${COLORS.gray};
  }
`;
const ItemContainer = styled.div`
  margin: 8px auto 0 auto;
  padding: 18px 20px 18px;
  border: 1px solid ${COLORS.lightestGray};
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #fff;
  max-width: ${SIZE.mediumWidth};
`;
const NameWrap = styled.div``;
const ItemName = styled.p`
  font-size: 12px;
  font-weight: 400px;
  line-height: 17px;
  color: ${COLORS.gray};
  display: flex;
  align-items: center;
  span {
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-left: 5px;
    padding: 0px 5px;
    cursor: pointer;
  }
`;
const InputContainer = styled.div`
  padding: 13px 12px;
  position: relative;
  width: 100%;
  height: 45px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 8px auto 20px auto;
  display: flex;
  align-items: center;
`;
const ItemSelector = styled.select`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 99%;
`;
const ItemInput = styled.input`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 90%;
`;

export default Wallet;
