import { api } from "api/client";

export const getNotices = async () => {
  const response = await api.get(`/notices`);
  return response.data;
};
export const getNoticeDetail = async (id?: string) => {
  const response = await api.get(`/notices/${id}`);
  return response.data;
};
