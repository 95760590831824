import styled from "styled-components";

import { BackdropType } from "types/invest";
import { ReactComponent as DepositCheck } from "assets/images/Product/DepositCheck.svg";
import COLORS from "components/atoms/colors";
import Modal from "components/organisms/Modal";
import ModalProofKRW from "components/templates/Invest/ModalProofKRW";
import ModalProof from "components/templates/Invest/TopBox/ModalProof";

const Backdrop = ({ typed_wallet, is_need_bank }: BackdropType) => {
  return (
    <Container>
      <ContentContainer>
        {typed_wallet === "확인중" ? (
          <ContentWrapper>
            <DepositCheck />
            <ContentTitle>관리자가 입금 확인 중입니다</ContentTitle>
            <Content>(최대 3일 소요)</Content>
          </ContentWrapper>
        ) : (
          <ContentWrapper>
            <ContentTitle>입금 증명이 필요한 상품입니다</ContentTitle>
            <Modal
              activator={(onOpen) => (
                <ContentBtn onClick={onOpen}>입금 증명하기</ContentBtn>
              )}
              content={(onClose) =>
                is_need_bank ? (
                  <ModalProofKRW onClose={onClose} />
                ) : (
                  <ModalProof onClose={onClose} />
                )
              }
            />
          </ContentWrapper>
        )}
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  z-index: 2;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ContentWrapper = styled.div`
  text-align: center;
  margin-top: -30px;
`;
const ContentTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
`;
const Content = styled(ContentTitle)`
  font-size: 12px;
`;
const ContentBtn = styled.button`
  background: ${COLORS.pink};
  border-radius: 4px;
  padding: 7px 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 9px;
`;

export default Backdrop;
