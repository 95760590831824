import styled from "styled-components";

import { coutions } from "datas/coutions";
import { ReactComponent as CautionImg } from "assets/icons/Main/Caution.svg";
import { ReactComponent as RightArrow } from "assets/icons/Main/RightArrow.svg";
import SIZE from "components/atoms/size";
import COLORS from "components/atoms/colors";

const Caution = () => {
  return (
    <Container>
      <CautionContainer>
        <CautionTitle>
          <CautionImg />
          신청 전 꼭 읽어주세요
        </CautionTitle>
        <CautionContentList>
          {coutions.map((content: string, i: number) => (
            <CautionContent key={i}>{content}</CautionContent>
          ))}
        </CautionContentList>
      </CautionContainer>
      {/* <CautionApply>
        <CautionApplyContent>개인정보 3자 제공에 대한 동의</CautionApplyContent>
        <RightArrow />
      </CautionApply> */}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 26px 0 0 0;
  background: #fff;
  margin: 52px auto 27px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  max-width: ${SIZE.largeWidth};
`;
const CautionContainer = styled.div`
  background: #fff;
  padding: 0 18px 25px 18px;
`;
const CautionTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 6px;
  }
`;
const CautionContentList = styled.ul`
  padding: 0;
  margin: 14px 0 0 0;
`;
const CautionContent = styled.li`
  font-size: 12px;
  line-height: 22px;
  color: ${COLORS.gray};
  list-style: outside;
  margin-left: 18px;
`;
const CautionApply = styled.div`
  padding: 15px 20px 19px 18px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CautionApplyContent = styled.p`
  line-height: 17px;
  font-size: 12px;
  font-weight: 400;
`;

export default Caution;
