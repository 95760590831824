import styled from "styled-components";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { getYetEndProducts } from "api/product";
import { ProductsType } from "types/products";
import { ReactComponent as LeftArrow } from "assets/icons/YetEnd/LeftArrow.svg";
import SIZE from "components/atoms/size";
import Item from "components/templates/EndProducts/Item";

const EndProducts = () => {
  const { data, isLoading } = useQuery("products", getYetEndProducts);
  const navigate = useNavigate();
  if (isLoading) return null;
  const EndData = data.filter((item: ProductsType) => item.status === "마감");

  return (
    <Container>
      <Top>
        <LeftArrow onClick={() => navigate("/products/yet-end")} />
        <Title>마감 상품</Title>
      </Top>
      <ItemList>
        {EndData.map((data: ProductsType, i: number) => (
          <Item data={data} key={i} />
        ))}
      </ItemList>
    </Container>
  );
};

const Container = styled.div`
  max-width: ${SIZE.largeWidth};
  margin: 0 auto;
  border: 1px solid #e0e0e0;
  padding-bottom: 100px;
  svg {
    position: absolute;
    left: 15px;
    cursor: pointer;
  }
`;
const Top = styled.div`
  padding: 26px 15px 26px 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  position: relative;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
`;
const ItemList = styled.div`
  padding: 0 15px;
`;

export default EndProducts;
