import styled from "styled-components";
import { useResetRecoilState, useRecoilValue } from "recoil";

import { walletInfoState } from "stores/walletInfo";
import metamask from "assets/icons/MyInfo/metamask.png";
import Metamask from "components/templates/My/Metamask";

const ModalNFT = ({
  onClose,
  isProfile,
}: {
  onClose: () => void;
  isProfile?: boolean;
}) => {
  const walletInfo = useRecoilValue(walletInfoState);
  const resetWalletInfo = useResetRecoilState(walletInfoState);

  return (
    <>
      <Container>
        <BG onClick={onClose} />
        <Modal isProfile={isProfile}>
          <TitleContainer>
            <Title>NFT 지갑 연결하기</Title>
            {walletInfo ? (
              <>
                <SubTitle>MetaMask 지갑에 연결되어 있습니다.</SubTitle>
                <ContentContainer>
                  <AddressTitle>
                    <AddressImg src={metamask} />
                    MetaMask 지갑 주소
                    <AddressLogout onClick={() => resetWalletInfo()}>
                      로그아웃
                    </AddressLogout>
                  </AddressTitle>
                  <AddressContainer>{walletInfo}</AddressContainer>
                </ContentContainer>
              </>
            ) : (
              <>
                <SubTitle>다음의 지갑과 연동하실 수 있습니다.</SubTitle>
                <ContentContainer>
                  <Metamask />
                </ContentContainer>
              </>
            )}
          </TitleContainer>
          <CancelBtn onClick={onClose}>취소</CancelBtn>
        </Modal>
      </Container>
    </>
  );
};

const Container = styled.div`
  z-index: 2;
  position: fixed;
  width: 100vw;
  height: 100vh;
`;
const BG = styled.div`
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
`;
const Modal = styled.div`
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 66px 0 0;
  position: fixed;
  bottom: ${({ isProfile }: { isProfile?: boolean }) =>
    isProfile ? "80px" : "0"};
  left: 50%;
  transform: translateX(-50%);
`;

const TitleContainer = styled.div``;
const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
`;
const SubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 11px auto 48px;
  text-align: center;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 22px 300px;
`;
const CancelBtn = styled.div`
  border-top: 1px solid #e0e0e0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #808080;
  padding: 15px 0 30px;
  cursor: pointer;
`;
const AddressContainer = styled.div`
  width: 330px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #f5f5f5;
  border-radius: 4px;

  font-size: 12px;
  word-break: break-all;
  color: #808080;
`;
const AddressTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
`;
const AddressImg = styled.img`
  width: 19px;
  height: 19px;
  margin-right: 4px;
`;
const AddressLogout = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #b3b3b3;
  flex: 1;
  text-align: right;
  cursor: pointer;
`;

export default ModalNFT;
