import styled from "styled-components";

import { AdviceType } from "types/invest";
import { getIsExpiryNear, getExpiryText } from "utils/getDDay";
import { ReactComponent as Arrow } from "assets/icons/Main/ProductArrow.svg";
import { ReactComponent as Safe } from "assets/icons/Main/ProductSafe.svg";
import { ReactComponent as Calendar } from "assets/icons/Main/ProductCalendar.svg";
import COLORS from "components/atoms/colors";

const Advice = ({
  typed_wallet,
  deposit_start_at,
  deposit_end_at,
}: AdviceType) => {
  const isNear = getIsExpiryNear(deposit_start_at, deposit_end_at);
  const text = getExpiryText(typed_wallet, deposit_start_at, deposit_end_at);
  return (
    <Wrapper>
      <Content red={isNear}>
        {isNear ? <Calendar /> : <Safe />}
        {text}
      </Content>
      <Arrow />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 10px;
  margin-bottom: 11px;
  border-bottom: 1px solid ${COLORS.lightestGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Content = styled.div<{ red: boolean }>`
  color: ${({ red }) => (red ? COLORS.pink : COLORS.darkerGray)};
  font-size: 11px;
  line-height: 19px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

export default Advice;
